import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import API from '../../api';

export default class Project extends Component {
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  nameOptions = async user => {
    return API.ProjectSearch(user)
      .then(list => {
        if (!this.mounted) return [];
        return list.map(a => {
          return { value: a._id, label: a.name };
        });
      })
      .catch(() => Promise.resolve([]));
  };

  onNameSelect = user => {
    const { form } = this.props;
    const { values } = form;
    values.projectName = user.label;
    values.projectId = user.value;
    form.setValues(values);
  };

  noOptionsMessage = ({ inputValue }) => {
    if (inputValue && inputValue.length < 3) {
      return 'Enter at least 3 letters';
    }
    return 'No results found';
  };

  render() {
    const { form, name } = this.props;
    const { values } = form;
    const { projectId, projectName } = values;

    const value = {
      value: projectId,
      label: projectName
    };

    return (
      <AsyncSelect
        placeholder="type project name"
        openMenuOnClick={false}
        loadOptions={this.nameOptions}
        onChange={this.onNameSelect}
        noOptionsMessage={this.noOptionsMessage}
        value={value}
        name={name}
      />
    );
  }
}
