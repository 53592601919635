import React, { Component } from 'react';

import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

import Header from '../../containers/Header';

import { UserContext } from '../../context/user';
import ProjectForm from './form';
import ProjectList from './list';

class Project extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listUpdateTs: new Date().getTime(),
      showProjectModal: false
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleProjectAdd = () => {
    this.setState({
      showProjectModal: true
    });
  };

  rightNav = () => {
    const { user } = this.context;
    const allowedUser = [
      'kdc',
      'executive',
      'marketingManager',
      'marketingSecretary',
      'documentController'
    ];
    if (allowedUser.indexOf(user.role) === -1) return null;

    return (
      <div>
        <Button
          color="primary"
          size="sm"
          onClick={this.handleProjectAdd}
          className="mr-1"
        >
          Add
        </Button>
      </div>
    );
  };

  setLastUpdate = (key, timestamp) => {
    if (!timestamp) return;

    const stateUpdateInfo = {};
    stateUpdateInfo[`${key}`] = timestamp;

    this.setState(stateUpdateInfo);
  };

  parent = () => {
    return { setLastUpdate: this.setLastUpdate };
  };

  toggleProjectModal = () => {
    this.setState(prevState => {
      return {
        showProjectModal: !prevState.showProjectModal
      };
    });
  };

  render() {
    const { listUpdateTs, showProjectModal } = this.state;
    const { sidebar } = this.props;

    return (
      <div>
        <Header
          sidebar={sidebar}
          title="Project"
          mode="buttons"
          rightNav={this.rightNav()}
        />
        <div className="row">
          <div className="col-sm-12">
            <ProjectList
              listUpdateTs={listUpdateTs}
              parent={this.parent()}
              {...this.props}
            />
          </div>
        </div>

        <Modal
          isOpen={showProjectModal}
          toggle={this.toggleProjectModal}
          backdrop="static"
        >
          <ModalHeader>Add Project</ModalHeader>

          <ModalBody>
            <ProjectForm
              listUpdateTs={listUpdateTs}
              parent={this.parent()}
              toggleProjectModal={this.toggleProjectModal}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

Project.contextType = UserContext;

export default Project;
