import React, { Component } from 'react';
import {
  Card,
  Alert,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import Header from '../../containers/Header';
import API from '../../api';
import { UserContext } from '../../context/user';

class EditProfile extends Component {
  constructor(props) {
    super(props);

    const { match } = props;
    const { params } = match;

    this.state = {
      userId: params.userId,
      userInfo: null,
      originalUserInfo: null
    };

    this.editProfileForm = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;

    const { userId } = this.state;
    API.User(userId)
      .then(userInfo => {
        if (!this.mounted) return;
        this.setState({ userInfo, originalUserInfo: userInfo });
      })
      .catch(e => {
        console.log(e);
        if (!this.mounted) return;
        console.log(e.message);
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onSubmit = (values, { setSubmitting, setStatus, setErrors }) => {
    const { history } = this.props;
    const { originalUserInfo } = this.state;

    const changes = [];
    if (values) {
      _.forEach(values, (u, k) => {
        if (k === '_id') return u;

        if (u !== originalUserInfo[k]) changes.push(k);

        return u;
      });
    }

    if (!changes.length) {
      console.log('No changes made.');
      setSubmitting(false);
      return {};
    }

    return API.UserUpdate(values)
      .then(async () => {
        if (!this.mounted) return;
        setSubmitting(false);
        alert('User information successfully updated.');

        let logout = 0;
        const keyToLogout = ['firstName', 'lastName', 'username'];
        _.each(changes, key => {
          if (logout) return;

          if (keyToLogout.indexOf(key) > -1) {
            logout = 1;
          }
        });

        if (logout) {
          console.log('Automatic logout.');
          await API.Logout();
          history.push('/app/dashboard');
        }
      })
      .catch(e => {
        console.log('EditProfileForm', e);
        setErrors({ submit: e.message });
        setStatus({ success: false });
        setSubmitting(false);
      });
  };

  setInitialValues = () => {
    const { userInfo } = this.state;

    const {
      _id,
      username,
      email,
      firstName,
      lastName,
      mobileNumber,
      landlineNumber
    } = userInfo || {};

    return {
      _id: _id || '',
      username: username || '',
      email: email || '',
      firstName: firstName || '',
      lastName: lastName || '',
      mobileNumber: mobileNumber || '',
      landlineNumber: landlineNumber || ''
    };
  };

  setValidationSchema = Yup.object({
    username: Yup.string()
      .trim()
      .min(4, 'Minimum of 4 characters')
      .max(25, 'Maximum of 25 characters')
      .matches(/^[a-zA-Z0-9]+$/, 'Must only contain letters and numbers')
      .required('Username is required'),
    email: Yup.string()
      .trim()
      .email('Invalid email format'),
    firstName: Yup.string()
      .trim()
      .required('First name is required'),
    lastName: Yup.string()
      .trim()
      .required('Last name is required'),
    mobileNumber: Yup.string().trim(),
    landlineNumber: Yup.string().trim()
  });

  composeEditProfileFormFields = (errors, touched) => {
    return (
      <div className="col-xs-12 m-a">
        {errors.submit && (
          <Row form>
            <Col>
              <Alert color="danger">{errors.submit}</Alert>
            </Col>
          </Row>
        )}

        <Row form>
          <Input tag={Field} type="hidden" name="_id" />
          <Col sm={6}>
            <FormGroup>
              <Label for="UserName">Username *</Label>
              <Input
                tag={Field}
                type="text"
                name="username"
                placeholder=""
                autoComplete="off"
                readOnly
                invalid={errors.username && touched.username}
              />
              <FormFeedback>{errors.username}</FormFeedback>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="Email">Email</Label>
              <Input
                tag={Field}
                type="email"
                name="email"
                placeholder=""
                autoComplete="off"
                invalid={errors.email && touched.email}
              />
              <FormFeedback>{errors.email}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col sm={6}>
            <FormGroup>
              <Label for="FirstName">First Name *</Label>
              <Input
                tag={Field}
                type="text"
                name="firstName"
                placeholder=""
                autoComplete="off"
                invalid={errors.firstName && touched.firstName}
              />
              <FormFeedback>{errors.firstName}</FormFeedback>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="LastName">Last Name *</Label>
              <Input
                tag={Field}
                type="text"
                name="lastName"
                placeholder=""
                autoComplete="off"
                invalid={errors.lastName && touched.lastName}
              />
              <FormFeedback>{errors.lastName}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={6}>
            <FormGroup>
              <Label for="MobileNumber">Mobile Number</Label>
              <Input
                tag={Field}
                type="tel"
                name="mobileNumber"
                placeholder=""
                autoComplete="off"
                invalid={errors.mobileNumber && touched.mobileNumber}
              />
              <FormFeedback>{errors.mobileNumber}</FormFeedback>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="LandlineNumber">Landline Number</Label>
              <Input
                tag={Field}
                type="tel"
                name="landlineNumber"
                placeholder=""
                autoComplete="off"
                invalid={errors.landlineNumber && touched.landlineNumber}
              />
              <FormFeedback>{errors.landlineNumber}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  };

  composeEditProfileFormButtons = (errors, touched, isSubmitting) => {
    return (
      <div className="col-sm-12" style={{ padding: '0px' }}>
        <FormGroup row>
          <Col>
            <Button type="submit" color="primary" disabled={isSubmitting}>
              Update
            </Button>
          </Col>
        </FormGroup>
      </div>
    );
  };

  render() {
    const { sidebar } = this.props;
    const { userInfo } = this.state;
    if (!userInfo) return '';

    return (
      <div>
        <Header sidebar={sidebar} title="Edit Profile" mode="buttons" />
        <div className="row">
          <div className="col-sm-12">
            <Card body>
              <Formik
                initialValues={this.setInitialValues()}
                validationSchema={this.setValidationSchema}
                onSubmit={this.onSubmit}
                ref={this.editProfileForm}
              >
                {props => {
                  const { errors, touched, isSubmitting } = props;
                  return (
                    <Form autoComplete="off">
                      {this.composeEditProfileFormFields(
                        errors,
                        touched,
                        isSubmitting
                      )}

                      {this.composeEditProfileFormButtons(
                        errors,
                        touched,
                        isSubmitting
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

EditProfile.contextType = UserContext;

export default EditProfile;
