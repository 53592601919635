import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import SalesAgent from './salesAgent';
import { UserContext } from '../../context/user';

import API from '../../api';

class SalesOrderForm extends Component {
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setInitialValues = () => {
    const { companyName, companyId, so, selectedProject: sp } = this.props;

    const { user } = this.context;
    const salesAgent =
      user.role === 'sales' ? `${user.firstName} ${user.lastName}` : '';
    const salesAgentId = user.role === 'sales' ? user._id : '';
    const dateToday = moment().format('YYYY-MM-DD');

    if (sp) {
      return {
        poAmount: sp ? sp.lastQuotation : 0,
        tpcAmount: sp ? sp.tpcAmount : 0,
        soDate: dateToday,
        companyName: sp.companyName,
        companyId: sp.companyId,
        poNum: '',
        poDate: dateToday,
        deliveryDate: '',
        projectId: sp._id,
        projectName: sp ? sp.projectName : '',
        description: '',
        salesAgent: sp ? sp.salesAgentUserName : '',
        salesAgentId: sp ? sp.salesAgentUserId : '',
        terms: sp
          ? sp.quotations[sp.lastQuotationRevisionNumber].scopeOfWork
          : '',
        remarks: ''
      };
    }

    return {
      poAmount: so ? so.poAmount : 0,
      tpcAmount: so ? so.tpcAmount : 0,
      soDate:
        so && so.soDate ? moment(so.soDate).format('YYYY-MM-DD') : dateToday,
      companyName,
      companyId,
      poNum: so ? so.poNum : '',
      poDate: so && so.poDate ? moment(so.poDate).format('YYYY-MM-DD') : '',
      deliveryDate:
        so && so.deliveryDate
          ? moment(so.deliveryDate).format('YYYY-MM-DD')
          : '',
      projectId: so ? so.projectId : '',
      projectName: so ? so.projectName : '',
      description: so && so.description ? so.description : '',
      salesAgent: so ? so.salesAgent : salesAgent,
      salesAgentId: so ? so.salesAgentId : salesAgentId,
      terms: so ? so.terms : '',
      remarks: so && so.remarks ? so.remarks : ''
    };
  };

  setValidationSchema = Yup.object().shape({
    poAmount: Yup.number()
      .moreThan(0, 'Needs to be more than zero')
      .required('Required'),
    soDate: Yup.date().required('Required'),
    salesAgent: Yup.string().required('Required')
  });

  onSubmit = (values, { setSubmitting, setStatus, setErrors }) => {
    const { so, selectedProject, done, setLastUpdate, close } = this.props;
    if (so) {
      values._id = so._id;
      if (so.invoiceAmount > values.poAmount) {
        setErrors({
          submit: 'Contract price cannot be less than Invoiced amount'
        });
        setStatus({ success: false });
        setSubmitting(false);
        return Promise.reject();
      }
    }

    // fix date
    values.soDate = moment(values.soDate).isValid() ? values.soDate : '';
    values.poDate = moment(values.poDate).isValid() ? values.poDate : '';
    values.deliveryDate = moment(values.deliveryDate).isValid()
      ? values.deliveryDate
      : '';

    const promise = so
      ? API.SalesOrderUpdate(values)
      : API.SalesOrderAdd(values);
    return promise
      .then(newSo => {
        if (selectedProject) {
          API.ProjectUpdate({
            _id: selectedProject._id,
            status: 'Approved',
            soId: newSo._id,
            soNum: newSo.soNum
          })
            .then(() => {
              alert('SO successfully created.');
            })
            .catch(e => {
              console.log('SalesOrderForm', e);
            });
        }

        if (!this.mounted) return;
        setSubmitting(false);

        if (selectedProject) {
          setLastUpdate('listUpdateTs', new Date().getTime());
          close();
        } else {
          done();
        }
      })
      .catch(e => {
        console.log('SalesOrderForm', e);
        setErrors({ submit: e.message });
        setStatus({ success: false });
        setSubmitting(false);
      });
  };

  composeSalesOrderFormFields = (errors, touched, isSubmitting) => {
    const {
      salesAgent: assignedSalesAgent,
      salesAgentId: assignedSalesAgentId,
      selectedProject
    } = this.props;
    const { user } = this.context;

    const readOnly = { readOnly: selectedProject ? 'readOnly' : '' };

    return (
      <div className="col-xs-12 m-a">
        {errors.submit && (
          <Row form>
            <Col>
              <p className="text-danger">{errors.submit}</p>
            </Col>
          </Row>
        )}
        <Row form>
          <Col sm={6}>
            <FormGroup>
              <Label for="soNum">SO Number *</Label>
              <Input
                tag={Field}
                type="text"
                name="soNum"
                className="form-control-plaintext pl-2"
                readOnly
                placeholder="Auto generated"
              />
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="soDate">SO Date *</Label>
              <Input
                tag={Field}
                type="date"
                name="soDate"
                disabled={isSubmitting}
                invalid={errors.soDate && touched.soDate}
              />
              <FormFeedback>{errors.soDate}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={6}>
            <FormGroup>
              <Label for="companyName">Company Name</Label>
              <Input
                tag={Field}
                readOnly
                type="text"
                name="companyName"
                className="form-control-plaintext pl-2"
              />
              <Input tag={Field} type="hidden" name="companyId" />
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="salesAgent">Sales Agent *</Label>
              {user.role === 'sales' || selectedProject ? (
                <Input
                  tag={Field}
                  readOnly
                  type="text"
                  name="salesAgent"
                  className="form-control-plaintext pl-2"
                />
              ) : (
                <div
                  className={
                    errors.salesAgent && touched.salesAgent
                      ? 'error-border'
                      : ''
                  }
                >
                  <Field
                    name="salesAgent"
                    assignedSalesAgent={assignedSalesAgent}
                    assignedSalesAgentId={assignedSalesAgentId}
                    component={SalesAgent}
                  />
                </div>
              )}
              {errors.salesAgent && touched.salesAgent ? (
                <div className="text-danger form-feedback">
                  {errors.salesAgent}
                </div>
              ) : null}
              <Input tag={Field} type="hidden" name="salesAgentId" />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={6}>
            <FormGroup>
              <Label for="poNum">Customer PO Number</Label>
              <Input
                tag={Field}
                type="text"
                name="poNum"
                disabled={isSubmitting}
              />
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="poDate">PO Date</Label>
              <Input
                tag={Field}
                type="date"
                name="poDate"
                disabled={isSubmitting}
                invalid={errors.poDate && touched.poDate}
              />
              <FormFeedback>{errors.poDate}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={6}>
            <FormGroup>
              <Label for="projectName">Project Name</Label>
              <Input
                tag={Field}
                type="text"
                name="projectName"
                disabled={isSubmitting}
                {...readOnly}
              />
              <Input tag={Field} type="hidden" name="projectId" />
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="deliveryDate">Delivery Date</Label>
              <Input
                tag={Field}
                type="date"
                name="deliveryDate"
                disabled={isSubmitting}
                invalid={errors.deliveryDate && touched.deliveryDate}
              />
              <FormFeedback>{errors.deliveryDate}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={12}>
            <FormGroup>
              <Label for="terms">Description</Label>
              <Input
                tag={Field}
                type="text"
                name="description"
                placeholder=""
                disabled={isSubmitting}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={6}>
            <FormGroup>
              <Label for="poAmount">Contract Price *</Label>
              <Input
                tag={Field}
                type="number"
                name="poAmount"
                disabled={isSubmitting}
                invalid={errors.poAmount && touched.poAmount}
                min="0"
                step="0.01"
              />
              <FormFeedback>{errors.poAmount}</FormFeedback>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="tpcAmount">TPC</Label>
              <Input
                tag={Field}
                type="number"
                name="tpcAmount"
                disabled={isSubmitting}
                invalid={errors.tpcAmount && touched.tpcAmount}
                min="0"
                step="0.01"
              />
              <FormFeedback>{errors.tpcAmount}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={12}>
            <FormGroup>
              <Label for="terms">Terms</Label>
              <Input
                tag={Field}
                type="text"
                name="terms"
                disabled={isSubmitting}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={12}>
            <FormGroup>
              <Label for="terms">Remarks</Label>
              <Input
                tag={Field}
                type="text"
                name="remarks"
                placeholder=""
                disabled={isSubmitting}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  };

  composeSalesOrderFormButtons = (errors, touched, isSubmitting) => {
    const { close, so, addButtonLabel } = this.props;

    return (
      <div className="col-sm-12">
        <FormGroup row>
          <Col className="text-right">
            <Button
              type="submit"
              color="primary"
              className="mr-1"
              disabled={isSubmitting}
              onClick={e => {
                const eventTarget = e.target;
                setTimeout(() => {
                  eventTarget.disabled = true;

                  setTimeout(() => {
                    eventTarget.disabled = isSubmitting;
                  }, 2000);
                });

                return true;
              }}
            >
              {addButtonLabel || (so ? 'Save' : 'Add')}
            </Button>
            <Button type="button" color="danger" onClick={close}>
              Cancel
            </Button>
          </Col>
        </FormGroup>
      </div>
    );
  };

  render() {
    return (
      <Formik
        initialValues={this.setInitialValues()}
        validationSchema={this.setValidationSchema}
        onSubmit={this.onSubmit}
      >
        {props => {
          const { errors, touched, isSubmitting } = props;
          return (
            <Form>
              {this.composeSalesOrderFormFields(errors, touched, isSubmitting)}
              {this.composeSalesOrderFormButtons(errors, touched, isSubmitting)}
            </Form>
          );
        }}
      </Formik>
    );
  }
}

SalesOrderForm.contextType = UserContext;

export default SalesOrderForm;
