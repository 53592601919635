import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';

import PROJECT_PRODUCTS from '../../util/products';

export default class Products extends Component {
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  productOptions = product => {
    if (!this.mounted) return [];

    const list = [];
    PROJECT_PRODUCTS.map(p => {
      const { value } = p;
      if (value.toLowerCase().includes(product.toLowerCase())) {
        list.push({ value, label: value });
      }
      return null;
    });

    return Promise.resolve(list);
  };

  onProductSelect = product => {
    const { form } = this.props;
    const { values } = form;
    values.product = product.value;
    form.setValues(values);
  };

  render() {
    const { form, name } = this.props;
    const { values } = form;
    const { product } = values;

    const value = {
      value: product,
      label: product
    };

    return (
      <AsyncSelect
        placeholder="type product"
        openMenuOnClick
        defaultOptions={PROJECT_PRODUCTS}
        loadOptions={this.productOptions}
        onChange={this.onProductSelect}
        value={value}
        name={name}
      />
    );
  }
}
