import React, { Component } from 'react';
import { Button, Col, FormGroup, Badge } from 'reactstrap';

import { UserContext } from '../../context/user';
import { formatHour, userStringDateFormat } from '../../util/date';

class ActivityView extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  composeActivityViewFields = () => {
    const { selectedActivity } = this.props;

    return (
      <div>
        <div className="row" style={{ display: 'flex' }}>
          <div className="col-sm-12">
            <div style={{ fonSize: '11px' }}>
              {userStringDateFormat(selectedActivity.date, 'MM / DD / YYYY')},{' '}
              {formatHour(selectedActivity.timeStart)} -{' '}
              {formatHour(selectedActivity.timeEnd)}
            </div>
          </div>
          <div
            className="col-sm-12"
            style={{
              paddingTop: '21px',
              paddingBottom: '35px',
              fontSize: '16px'
            }}
          >
            {selectedActivity.log}
          </div>

          {selectedActivity.projectName ? (
            <div
              className="col-sm-12"
              style={{
                paddingBottom: '12px',
                fontSize: '15px',
                color: '#365899'
              }}
            >
              <Badge color="info">{selectedActivity.projectName}</Badge>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  };

  composeActivityViewButtons = toggleActivityViewModal => {
    return (
      <div className="col-sm-12">
        <FormGroup row>
          <Col className="text-right">
            <Button
              type="button"
              color="danger"
              onClick={toggleActivityViewModal}
            >
              Close
            </Button>
          </Col>
        </FormGroup>
      </div>
    );
  };

  render() {
    const { toggleActivityViewModal } = this.props;

    return (
      <div>
        {this.composeActivityViewFields()}
        {this.composeActivityViewButtons(toggleActivityViewModal)}
      </div>
    );
  }
}

ActivityView.contextType = UserContext;

export default ActivityView;
