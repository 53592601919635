import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import API from '../../api';

export default class Companies extends Component {
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  nameOptions = async company => {
    return API.CompanySearch(company)
      .then(list => {
        if (!this.mounted) return [];
        return list.map(a => {
          return { value: a._id, label: a.name };
        });
      })
      .catch(() => Promise.resolve([]));
  };

  onCompanySelect = async company => {
    const { form } = this.props;
    const { values } = form;
    values.companyName = company.label;
    values.companyId = company.value;

    await this.getCompanySalesAgent();

    if (!this.mounted) return;
    form.setValues(values);
  };

  noOptionsMessage = ({ inputValue }) => {
    if (inputValue && inputValue.length < 3) {
      return 'Enter at least 3 letters';
    }
    return 'No results found';
  };

  getCompanySalesAgent = async companyId => {
    const { form, includeCompanyInfo } = this.props;
    const { values } = form;

    const selectedCompanyId = companyId || values.companyId;

    if (includeCompanyInfo && selectedCompanyId) {
      // Include company assigned sales agent info to form.
      const companyInfo = await API.Company(selectedCompanyId);
      values.companySalesAgentIds = companyInfo.salesAgentId || [];
      values.companySalesAgentNames = companyInfo.salesAgent || [];

      values.salesAgent = '';
      values.salesAgentId = '';
    }
  };

  render() {
    const { form, name } = this.props;
    const { values } = form;
    const { companyId, companyName } = values;

    const value = { value: companyId, label: companyName };

    if (companyId) this.getCompanySalesAgent(companyId);

    return (
      <AsyncSelect
        placeholder="type company name"
        openMenuOnClick={false}
        loadOptions={this.nameOptions}
        onChange={this.onCompanySelect}
        noOptionsMessage={this.noOptionsMessage}
        value={value}
        name={name}
      />
    );
  }
}
