import React, { Component } from 'react';

import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

import ActivityForm from './form';
import ActivityList from './list';

class Activity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listUpdateTs: new Date().getTime(),
      selectedActivity: null,
      showActivityModal: false
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  toggleActivityModal = () => {
    this.setState(prevState => {
      return {
        showActivityModal: !prevState.showActivityModal
      };
    });
  };

  setLastUpdate = (key, timestamp) => {
    if (!timestamp) return;

    const stateUpdateInfo = {};
    stateUpdateInfo[`${key}`] = timestamp;

    this.setState(stateUpdateInfo);
  };

  setSelectedActivity = activity => {
    this.setState({ selectedActivity: activity });
    this.toggleActivityModal();
  };

  parent = () => {
    return {
      setLastUpdate: this.setLastUpdate,
      setSelectedActivity: this.setSelectedActivity
    };
  };

  render() {
    const { listUpdateTs, selectedActivity, showActivityModal } = this.state;
    const { projectId } = this.props;

    return (
      <div>
        <div className="col-xs-12 mb-2">
          <div
            className="col-xs-12 card card-body"
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '0px',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
              borderBottom: '0px'
            }}
          >
            <div className="col-sm-8" style={{ padding: '0px' }}>
              <h4>Activity Wall </h4>
            </div>

            <div
              className="col-sm-4"
              style={{ textAlign: 'right', padding: '0px' }}
            >
              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  this.setSelectedActivity(null);
                }}
                className="mr-1"
              >
                Add
              </Button>
            </div>
          </div>

          <ActivityList
            listUpdateTs={listUpdateTs}
            parent={this.parent()}
            projectId={projectId}
            toggleActivityModal={this.toggleActivityModal}
          />
        </div>

        <Modal
          isOpen={showActivityModal}
          toggle={this.toggleActivityModal}
          backdrop="static"
        >
          <ModalHeader>
            {`${selectedActivity ? 'Edit' : 'Add'} Activity`}
          </ModalHeader>
          <ModalBody>
            <ActivityForm
              selectedActivity={selectedActivity}
              parent={this.parent()}
              toggleActivityModal={this.toggleActivityModal}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default Activity;
