import React, { Component } from 'react';
import { Row, Col, Button, FormGroup, Label, Input, Table } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import _ from 'lodash';
import FormikCheckbox from '../../components/FormikCheckbox';
import currencyFormat, { currencyRound } from '../../util/currency';
import API from '../../api';

class PaymentAddInvoice extends Component {
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onSubmit = (values, { setSubmitting, setStatus, setErrors }) => {
    const { invoices, done } = this.props;
    let invs = _.filter(invoices, i => {
      return values.invoiceNumbers.indexOf(i.invoiceNum) >= 0;
    });

    if (!invs || invs.length <= 0) {
      alert('No invoice selected.');
      setSubmitting(false);
      return;
    }

    invs = _.orderBy(invs, ['balance'], ['asc']);
    invs = invs.map(i => {
      return i.invoiceNum;
    });

    const params = {
      orNum: values.orNum,
      invoiceNum: invs
    };

    API.PaymentAddInvoice(params)
      .then(res => {
        if (!this.mounted) return;
        setSubmitting(false);
        done(res);
      })
      .catch(e => {
        console.log('PaymentAddInvoice', e);
        setStatus({ success: false });
        setSubmitting(false);
        setErrors({ submit: e.message });
      });
  };

  getInvoice = invoiceNum => {
    const { invoices } = this.props;
    for (let i = 0; i < invoices.length; i += 1) {
      const inv = invoices[i];
      if (inv.invoiceNum === invoiceNum) {
        return { inv, idx: i };
      }
    }
    return {};
  };

  onPreCheck = form => {
    if (form.values.excessAmount <= 0) {
      alert('Unassigned amount already 0.');
      return false;
    }
    return true;
  };

  onChecked = (form, invoiceNum) => {
    const excess = form.values.excessAmount;
    const { inv } = this.getInvoice(invoiceNum);
    if (!inv) return;
    let newExcess = 0;
    if (excess - inv.balance < 0) {
      inv.balance -= excess;
      inv.assigned = excess;
      newExcess = 0;
    } else {
      newExcess = excess - inv.balance;
      inv.assigned = inv.balance;
      inv.balance = 0;
    }
    form.setFieldValue('excessAmount', currencyRound(newExcess));
  };

  onUnchecked = (form, invoiceNum) => {
    const excess = form.values.excessAmount;
    const { inv } = this.getInvoice(invoiceNum);
    if (!inv) return;
    const newExcess = excess + inv.assigned;
    inv.balance += inv.assigned;
    inv.assigned = 0;
    form.setFieldValue('excessAmount', currencyRound(newExcess));
  };

  render() {
    const { invoices, payment, close } = this.props;
    const { orNum, birOrNum, excessAmount } = payment;

    return (
      <Formik
        initialValues={{
          birOrNum,
          orNum,
          excessAmount,
          invoiceNumbers: []
        }}
        onSubmit={this.onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Row form>
              <Col sm={6}>
                <FormGroup>
                  <Label for="birOrNum">OR Number</Label>
                  <Input
                    readOnly
                    tag={Field}
                    type="text"
                    name="birOrNum"
                    placeholder="BIR OR #"
                    disabled={isSubmitting}
                  />
                  <Input tag={Field} type="hidden" name="orNum" />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="amount">Unassigned Amount</Label>
                  <Input
                    readOnly
                    tag={Field}
                    type="number"
                    min="0"
                    step="0.01"
                    name="excessAmount"
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table hover size="sm">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Inv#</th>
                      <th>Bl#</th>
                      <th>Balance</th>
                      <th>Assigned</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices && invoices.length > 0 ? (
                      invoices.map(inv => {
                        return (
                          <tr key={inv.invoiceNum}>
                            <th scope="row">
                              <FormikCheckbox
                                name="invoiceNumbers"
                                value={inv.invoiceNum}
                                onChecked={this.onChecked}
                                onUnchecked={this.onUnchecked}
                                onPreCheck={this.onPreCheck}
                              />
                            </th>
                            <td>{inv.birInvoiceNum}</td>
                            <td>{inv.billingInvoiceNum}</td>
                            <td>{currencyFormat(inv.balance)}</td>
                            <td>{currencyFormat(inv.assigned)}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <th scope="row" className="text-center">
                          -- no unpaid invoice --
                        </th>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <FormGroup row>
              <Col className="text-right">
                <Button
                  type="submit"
                  color="primary"
                  className="mr-1"
                  disabled={isSubmitting}
                  onClick={e => {
                    const eventTarget = e.target;
                    setTimeout(() => {
                      eventTarget.disabled = true;

                      setTimeout(() => {
                        eventTarget.disabled = isSubmitting;
                      }, 2000);
                    });

                    return true;
                  }}
                >
                  Add
                </Button>
                <Button type="button" color="danger" onClick={close}>
                  Cancel
                </Button>
              </Col>
            </FormGroup>
          </Form>
        )}
      </Formik>
    );
  }
}

export default PaymentAddInvoice;
