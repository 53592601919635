import debugLog from './debug';

const scheduler = {};

const SCHEDULER_DEBUG = false;
const DEFAULT_TIMEOUT_IN_MS = SCHEDULER_DEBUG ? 10000 : 180000; // Default is 3 mins. (180000)

/**
 * Handle auto refresh timeout.
 *
 * @param {Object}  obj          - Object that holds the function to trigger
 * @param {String}  functionName - Function to trigger
 * @param {Integer} timeout      - Timeout in milliseconds
 */
const autoRefreshList = (obj, functionName, timeoutInMs) => {
  debugLog(` ** AUTO REFRESH - ${functionName}`, SCHEDULER_DEBUG);
  const refreshtimeoutInMilliSec = timeoutInMs || DEFAULT_TIMEOUT_IN_MS;

  const timeoutId = setTimeout(() => {
    obj[functionName]();
    scheduler[`${functionName}`] = '';
    debugLog(`${functionName} executed and removed`, SCHEDULER_DEBUG);
  }, refreshtimeoutInMilliSec);

  const existingSchedule = scheduler[`${functionName}`];
  if (existingSchedule) {
    debugLog(`Cleared timeout: ${existingSchedule}`, SCHEDULER_DEBUG);
    clearTimeout(existingSchedule);
  }

  debugLog(`Added timeout: ${timeoutId}`, SCHEDULER_DEBUG);
  scheduler[`${functionName}`] = timeoutId;
};

/**
 * Clear auto refresh timeout by key.
 *
 * @param {String}  functionName - Key to use to clear target timeout
 */
export const clearAutoRefreshList = functionName => {
  const existingSchedule = scheduler[`${functionName}`];
  if (existingSchedule) {
    debugLog(`Cleared timeout: ${existingSchedule}`, SCHEDULER_DEBUG);
    clearTimeout(existingSchedule);
  }
};

export default autoRefreshList;
