import React, { Component } from 'react';
import Select from 'react-select';
import API from '../../api';
import sortArrayObjectsByKeys from '../../util/processArrayObjects';

export default class Estimator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameOptions: []
    };
  }

  async componentDidMount() {
    this.mounted = true;
    const estimatorUsers = await this.nameOptions('*ALL*');
    this.setState({ nameOptions: estimatorUsers });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  nameOptions = async user => {
    const { salesAgentId, salesAgentName } = this.props;

    return API.EstimatorSearch(user)
      .then(list => {
        if (!this.mounted) return [];

        const estimatorUsers = [];
        list.map(a => {
          if (salesAgentId === a._id) return {};

          estimatorUsers.push({ value: a._id, label: a.name });
          return {};
        });

        const finalEstimatorList = [];
        if (salesAgentId && salesAgentName) {
          finalEstimatorList.push({
            value: salesAgentId,
            label: salesAgentName
          });

          return sortArrayObjectsByKeys(
            [...finalEstimatorList, ...estimatorUsers],
            'label',
            0
          );
        }

        return estimatorUsers;
      })
      .catch(() => Promise.resolve([]));
  };

  onNameSelect = user => {
    const { form } = this.props;
    const { values } = form;
    values.marketingUserName = user.label;
    values.marketingUserId = user.value;
    form.setValues(values);
  };

  noOptionsMessage = ({ inputValue }) => {
    if (inputValue && inputValue.length < 3) {
      return 'Enter at least 3 letters';
    }
    return 'No results found';
  };

  render() {
    const { nameOptions } = this.state;
    const { form, name } = this.props;
    const { values } = form;
    const { marketingUserId, marketingUserName } = values;

    const value = {
      value: marketingUserId,
      label: marketingUserName
    };

    return (
      <Select
        placeholder="type name"
        options={nameOptions}
        onChange={this.onNameSelect}
        value={value}
        name={name}
      />
    );
  }
}
