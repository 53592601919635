import React, { Component } from 'react';
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { UserContext } from '../../context/user';

import API from '../../api';
import Estimator from '../users/estimator';

class QuotationForm extends Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.quotationForm = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setValidationSchema = Yup.object().shape({
    date: Yup.date().required('Required'),
    scopeOfWork: Yup.string().required('Required'),
    price: Yup.number().required('Required'),
    marketingUserId: Yup.string().required('Required'),
    marketingUserName: Yup.string().required('Required')
  });

  composeQuotationFormFields = (errors, touched, isSubmitting) => {
    const { selectedProject } = this.props;

    return (
      <div className="col-xs-12 m-a">
        <Input tag={Field} type="hidden" name="_id" />
        <Row form>
          <Col xs={12}>
            <Row form>
              <Col sm={6}>
                <FormGroup>
                  <Label for="date">Date</Label>
                  <Input
                    tag={Field}
                    type="date"
                    name="date"
                    disabled={isSubmitting}
                    invalid={errors.date && touched.date}
                  />
                  <FormFeedback>{errors.date}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="scopeOfWork">Scope Of Work</Label>
                  <Input
                    tag={Field}
                    type="text"
                    name="scopeOfWork"
                    disabled={isSubmitting}
                    invalid={errors.scopeOfWork && touched.scopeOfWork}
                  />
                  <FormFeedback>{errors.scopeOfWork}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={6}>
                <FormGroup>
                  <Label for="price">Price</Label>
                  <Input
                    tag={Field}
                    type="number"
                    min="0"
                    step="0.01"
                    name="price"
                    disabled={isSubmitting}
                    invalid={errors.price && touched.price}
                  />
                  <FormFeedback>{errors.price}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="marketingUserName">Estimator</Label>
                  <Field
                    name="marketingUserName"
                    component={Estimator}
                    disabled={isSubmitting}
                    invalid={errors.marketing && touched.marketing}
                    salesAgentId={selectedProject.salesAgentUserId || ''}
                    salesAgentName={selectedProject.salesAgentUserName || ''}
                  />
                  {errors.marketingUserName && touched.marketingUserName ? (
                    <div className="text-danger">
                      {errors.marketingUserName}
                    </div>
                  ) : null}
                  <Input tag={Field} type="hidden" name="marketingUserId" />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={12}>
                <FormGroup>
                  <Label for="remarks">Remarks</Label>
                  <Input
                    tag={Field}
                    type="text"
                    name="remarks"
                    disabled={isSubmitting}
                    invalid={errors.remarks && touched.remarks}
                  />
                  <FormFeedback>{errors.remarks}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  composeQuotationFormButtons = (
    errors,
    touched,
    isSubmitting,
    toggleQuotationModal
  ) => {
    const { selectedQuotation } = this.props;
    return (
      <div className="col-sm-12">
        <FormGroup row>
          <Col className="text-right">
            <Button type="submit" color="primary" className="mr-1">
              {selectedQuotation && selectedQuotation._id ? 'Update' : 'Create'}
            </Button>
            <Button type="button" color="danger" onClick={toggleQuotationModal}>
              Cancel
            </Button>
          </Col>
        </FormGroup>
      </div>
    );
  };

  setInitialValues = () => {
    const { selectedQuotation, selectedProject } = this.props;
    const {
      _id,
      date,
      scopeOfWork,
      price,
      marketingUserId,
      marketingUserName,
      remarks
    } = selectedQuotation || {};

    return {
      _id,
      projectId: selectedProject._id,
      projectName: selectedProject.projectName,
      date: moment(date).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD'),
      scopeOfWork: scopeOfWork || '',
      price: price || '',
      marketingUserId: marketingUserId || '',
      marketingUserName: marketingUserName || '',
      remarks: remarks || ''
    };
  };

  onSubmit = (values, { setSubmitting, setStatus, setErrors }) => {
    const {
      setLastUpdate,
      selectedQuotation,
      toggleQuotationModal
    } = this.props;

    const promise =
      selectedQuotation && selectedQuotation._id
        ? API.QuotationUpdate(values)
        : API.QuotationAdd(values);

    return promise
      .then(() => {
        if (!this.mounted) return;
        setSubmitting(false);
        setLastUpdate('listUpdateTs', new Date().getTime());
        toggleQuotationModal();
      })
      .catch(e => {
        console.log('QuotationForm', e);
        setErrors({ submit: e.message });
        setStatus({ success: false });
        setSubmitting(false);
      });
  };

  render() {
    const { toggleQuotationModal } = this.props;

    return (
      <Formik
        initialValues={this.setInitialValues()}
        validationSchema={this.setValidationSchema}
        onSubmit={this.onSubmit}
        ref={this.quotationForm}
      >
        {props => {
          const { errors, touched, isSubmitting } = props;
          return (
            <Form>
              {this.composeQuotationFormFields(errors, touched, isSubmitting)}
              {this.composeQuotationFormButtons(
                errors,
                touched,
                isSubmitting,
                toggleQuotationModal
              )}
            </Form>
          );
        }}
      </Formik>
    );
  }
}

QuotationForm.contextType = UserContext;

export default QuotationForm;
