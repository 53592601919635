import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import API from '../../api';

export default class SalesOrders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companySOList: []
    };
  }

  componentDidMount() {
    this.mounted = true;

    const { form } = this.props;
    const { values } = form;
    const { companyId } = values;

    API.SalesOrdersListByCompanyId(companyId)
      .then(list => {
        if (!this.mounted) return;

        const soList = [];
        list.forEach(so => {
          soList.push({ value: so._id, label: so.soNum });
        });

        this.setState({ companySOList: soList });
      })
      .catch(() => Promise.resolve([]));
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  nameOptions = async so => {
    if (!this.mounted) return [];

    const { companySOList } = this.state;

    const finalSoList = [];
    companySOList.map(a => {
      if (a.label.indexOf(so) > -1) {
        finalSoList.push(a);
      }

      return a;
    });

    return finalSoList;
  };

  onSalesOrderSelect = async so => {
    const { form } = this.props;
    const { values } = form;
    values.soNum = so.label;
    values.soId = so.value;

    form.setValues(values);
  };

  noOptionsMessage = ({ inputValue }) => {
    if (inputValue && inputValue.length < 3) {
      return 'Enter at least 3 letters';
    }
    return 'No results found';
  };

  render() {
    const { form, name } = this.props;
    const { values } = form;
    const { soId, soNum } = values;

    const value = { value: soId, label: soNum };

    return (
      <AsyncSelect
        placeholder="type so name"
        openMenuOnClick={false}
        loadOptions={this.nameOptions}
        onChange={this.onSalesOrderSelect}
        noOptionsMessage={this.noOptionsMessage}
        value={value}
        name={name}
      />
    );
  }
}
