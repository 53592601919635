import React, { Component } from 'react';
import {
  Card,
  Alert,
  UncontrolledAlert,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Header from '../../containers/Header';
import API from '../../api';

class EmployeeChangePassword extends Component {
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onSubmit = (values, { setSubmitting, setStatus, resetForm, setErrors }) => {
    API.UserChangePassword(values)
      .then(() => {
        if (!this.mounted) return;
        resetForm({
          currentPassword: '',
          newPassword: '',
          newPasswordConfirm: ''
        });
        setStatus({ success: true });
        setSubmitting(false);
      })
      .catch(e => {
        console.log('Employee change password', e);
        setStatus({ success: false });
        setSubmitting(false);
        setErrors({ submit: e.message });
      });
  };

  render() {
    const { sidebar } = this.props;
    return (
      <div>
        <Header sidebar={sidebar} title="Change Password" />
        <div className="row">
          <div className="col-sm-12">
            <Card body>
              <Formik
                initialValues={{
                  currentPassword: '',
                  newPassword: '',
                  newPasswordConfirm: ''
                }}
                validationSchema={Yup.object({
                  currentPassword: Yup.string()
                    .min(4, 'Minimum of 4 characters')
                    .required('Current Password is required'),
                  newPassword: Yup.string()
                    .min(6, 'Minimum of 6 characters')
                    .required('New Password is required'),
                  newPasswordConfirm: Yup.string()
                    .min(6, 'Minimum of 6 characters')
                    .oneOf(
                      [Yup.ref('newPassword'), null],
                      'New Passwords must match'
                    )
                    .required('Password confirm is required')
                })}
                onSubmit={this.onSubmit}
              >
                {({ status, errors, touched, isSubmitting }) => {
                  return (
                    <Form autoComplete="off">
                      {status && status.success && (
                        <Row form>
                          <Col>
                            <UncontrolledAlert color="success">
                              Your password has been changed!
                            </UncontrolledAlert>
                          </Col>
                        </Row>
                      )}

                      {errors.submit && (
                        <Row form>
                          <Col>
                            <Alert color="danger">{errors.submit}</Alert>
                          </Col>
                        </Row>
                      )}

                      <FormGroup row>
                        <Label for="currentPassword" sm={2}>
                          Current Password *
                        </Label>
                        <Col sm={10}>
                          <Input
                            tag={Field}
                            type="password"
                            name="currentPassword"
                            placeholder=""
                            autoComplete="off"
                            invalid={
                              errors.currentPassword && touched.currentPassword
                            }
                          />
                          <FormFeedback>{errors.currentPassword}</FormFeedback>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="newPassword" sm={2}>
                          New Password *
                        </Label>
                        <Col sm={10}>
                          <Input
                            tag={Field}
                            type="password"
                            name="newPassword"
                            placeholder=""
                            autoComplete="off"
                            invalid={errors.newPassword && touched.newPassword}
                          />
                          <FormFeedback>{errors.newPassword}</FormFeedback>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="newPasswordConfirm" sm={2}>
                          Confirm Password *
                        </Label>
                        <Col sm={10}>
                          <Input
                            tag={Field}
                            type="password"
                            name="newPasswordConfirm"
                            placeholder=""
                            autoComplete="off"
                            invalid={
                              errors.newPasswordConfirm &&
                              touched.newPasswordConfirm
                            }
                          />
                          <FormFeedback>
                            {errors.newPasswordConfirm}
                          </FormFeedback>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col>
                          <Button
                            type="submit"
                            color="primary"
                            disabled={isSubmitting}
                          >
                            Change
                          </Button>
                        </Col>
                      </FormGroup>
                    </Form>
                  );
                }}
              </Formik>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default EmployeeChangePassword;
