import React, { Component } from 'react';
import {
  Card,
  Alert,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Header from '../../containers/Header';
import API from '../../api';
import { ACTUAL_ROLES } from '../../util/roles';

class EmployeeAdd extends Component {
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onSubmit = (values, { setSubmitting, setStatus, setErrors }) => {
    const { history } = this.props;
    API.RegisterUser(values)
      .then(() => {
        if (!this.mounted) return;
        history.push('/app/employee');
      })
      .catch(e => {
        console.log('Employee add', e);
        setStatus({ success: false });
        setSubmitting(false);
        setErrors({ submit: e.message });
      });
  };

  rightNav() {
    const { history } = this.props;
    return (
      <div>
        <Button color="danger" size="sm" onClick={history.goBack}>
          Back
        </Button>
      </div>
    );
  }

  render() {
    const { sidebar } = this.props;
    return (
      <div>
        <Header
          sidebar={sidebar}
          title="Add Employee"
          mode="buttons"
          rightNav={this.rightNav()}
        />
        <div className="row">
          <div className="col-sm-12">
            <Card body>
              <Formik
                initialValues={{
                  username: '',
                  password: '',
                  passwordConfirm: '',
                  firstName: '',
                  lastName: '',
                  mobileNumber: '',
                  landlineNumber: '',
                  email: '',
                  role: 'admin',
                  salesAgent: false
                }}
                validationSchema={Yup.object({
                  username: Yup.string()
                    .trim()
                    .min(4, 'Minimum of 4 characters')
                    .max(25, 'Maximum of 25 characters')
                    .matches(
                      /^[a-zA-Z0-9]+$/,
                      'Must only contain letters and numbers'
                    )
                    .required('Username is required'),
                  password: Yup.string()
                    .min(6, 'Minimum of 6 characters')
                    .required('Password is required'),
                  passwordConfirm: Yup.string()
                    .min(6, 'Minimum of 6 characters')
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
                    .required('Password confirm is required'),
                  firstName: Yup.string()
                    .trim()
                    .required('First name is required'),
                  lastName: Yup.string()
                    .trim()
                    .required('Last name is required'),
                  mobileNumber: Yup.string().trim(),
                  landlineNumber: Yup.string().trim(),
                  email: Yup.string()
                    .trim()
                    .email('Invalid email format')
                })}
                onSubmit={this.onSubmit}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form autoComplete="off">
                    {errors.submit && (
                      <Row form>
                        <Col>
                          <Alert color="danger">{errors.submit}</Alert>
                        </Col>
                      </Row>
                    )}

                    <Row form>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="UserName">Username *</Label>
                          <Input
                            tag={Field}
                            type="text"
                            name="username"
                            placeholder=""
                            autoComplete="off"
                            invalid={errors.username && touched.username}
                          />
                          <FormFeedback>{errors.username}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="role">Role *</Label>
                          <Field
                            component="select"
                            name="role"
                            className="form-control"
                          >
                            {ACTUAL_ROLES.map(r => (
                              <option key={r.value} value={r.value}>
                                {r.label}
                              </option>
                            ))}
                          </Field>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="Password">Password *</Label>
                          <Input
                            tag={Field}
                            type="password"
                            name="password"
                            placeholder=""
                            autoComplete="off"
                            invalid={errors.password && touched.password}
                          />
                          <FormFeedback>{errors.password}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="passwordConfirm">
                            Confirm Password *
                          </Label>
                          <Input
                            tag={Field}
                            type="password"
                            name="passwordConfirm"
                            placeholder=""
                            autoComplete="off"
                            invalid={
                              errors.passwordConfirm && touched.passwordConfirm
                            }
                          />
                          <FormFeedback>{errors.passwordConfirm}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="FirstName">First Name</Label>
                          <Input
                            tag={Field}
                            type="text"
                            name="firstName"
                            placeholder=""
                            autoComplete="off"
                            invalid={errors.firstName && touched.firstName}
                          />
                          <FormFeedback>{errors.firstName}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="LastName">Last Name</Label>
                          <Input
                            tag={Field}
                            type="text"
                            name="lastName"
                            placeholder=""
                            autoComplete="off"
                            invalid={errors.lastName && touched.lastName}
                          />
                          <FormFeedback>{errors.lastName}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="MobileNumber">Mobile Number</Label>
                          <Input
                            tag={Field}
                            type="tel"
                            name="mobileNumber"
                            placeholder=""
                            autoComplete="off"
                            invalid={
                              errors.mobileNumber && touched.mobileNumber
                            }
                          />
                          <FormFeedback>{errors.mobileNumber}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="LandlineNumber">Landline Number</Label>
                          <Input
                            tag={Field}
                            type="tel"
                            name="landlineNumber"
                            placeholder=""
                            autoComplete="off"
                            invalid={
                              errors.landlineNumber && touched.landlineNumber
                            }
                          />
                          <FormFeedback>{errors.landlineNumber}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="Email">Email</Label>
                          <Input
                            tag={Field}
                            type="email"
                            name="email"
                            placeholder=""
                            autoComplete="off"
                            invalid={errors.email && touched.email}
                          />
                          <FormFeedback>{errors.email}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="salesAgent">Other roles:</Label>
                          <FormGroup check>
                            <Label check style={{ fontSize: '17px' }}>
                              <Input
                                tag={Field}
                                type="checkbox"
                                name="salesAgent"
                              />{' '}
                              Sales Agent
                            </Label>
                          </FormGroup>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="estimator" />
                          <FormGroup check>
                            <Label check style={{ fontSize: '17px' }}>
                              <Input
                                tag={Field}
                                type="checkbox"
                                name="estimator"
                              />{' '}
                              Estimator
                            </Label>
                          </FormGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup row>
                      <Col>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          Add
                        </Button>
                      </Col>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default EmployeeAdd;
