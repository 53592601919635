import React, { Component } from 'react';
import { CardBody, Row, Col, Table, Button } from 'reactstrap';
import { UserContext } from '../../context/user';
import {
  ROLE_KDC,
  ROLE_EXECUTIVE,
  ROLE_EXECUTIVE_SECRETARY,
  ROLE_MARKETING_MANAGER,
  ROLE_DOCUMENT_CONTROLLER
} from '../../util/roles';

class Info extends Component {
  renderContact() {
    const { company } = this.props;

    let persons = [];
    if (company && company.contactPerson) {
      persons = company.contactPerson.map(person => (
        <tr key={person._id}>
          <th scope="row">{person.name ? person.name : '-'}</th>
          <td>{person.mobile ? person.mobile : '-'}</td>
          <td>{person.email ? person.email : '-'}</td>
          <td>{person.designation ? person.designation : '-'}</td>
        </tr>
      ));
    }

    if (persons.length <= 0) {
      persons.push(
        <tr key="none">
          <th scope="row" className="text-center" colSpan={4}>
            -- none --
          </th>
        </tr>
      );
    }

    return (
      <Table size="sm">
        <thead>
          <tr>
            <th className="border-top-0 pt-0">Name</th>
            <th className="border-top-0 pt-0">Mobile</th>
            <th className="border-top-0 pt-0">Email</th>
            <th className="border-top-0 pt-0">Designation</th>
          </tr>
        </thead>
        <tbody>{persons}</tbody>
      </Table>
    );
  }

  render() {
    const { company, history } = this.props;

    const { user } = this.context;
    const allowedUserCompanyEdit = [
      ROLE_KDC,
      ROLE_EXECUTIVE,
      ROLE_EXECUTIVE_SECRETARY,
      ROLE_MARKETING_MANAGER,
      ROLE_DOCUMENT_CONTROLLER
    ];

    let editButton = '';
    if (user && user.role && allowedUserCompanyEdit.indexOf(user.role) > -1) {
      editButton = (
        <Row>
          <Col className="text-right">
            <Button
              color="secondary"
              size="sm"
              className="mr-1"
              onClick={() => history.push(`/app/company/edit/${company._id}`)}
            >
              Edit
            </Button>
          </Col>
        </Row>
      );
    }

    return (
      <Row className="mb-3">
        <CardBody>
          <Row>
            <Col sm={6}>
              <dl className="row mb-0 px-3 pb-5">
                <dt className="col-6 col-sm-2 text-nowrap">Landline</dt>
                <dd className="col-6 col-sm-4">
                  {company.landline ? company.landline : '-'}
                </dd>
                <dt className="col-6 col-sm-2 text-nowrap">Fax</dt>
                <dd className="col-6 col-sm-4">
                  {company.fax ? company.fax : '-'}
                </dd>
                <dt className="col-6 col-sm-2 text-nowrap">Address 1</dt>
                <dd className="col-6 col-sm-4">
                  {company.address1 ? company.address1 : '-'}
                </dd>
                <dt className="col-6 col-sm-2 text-nowrap">Address 2</dt>
                <dd className="col-6 col-sm-4">
                  {company.address2 ? company.address2 : '-'}
                </dd>
                <dt className="col-6 col-sm-2">City</dt>
                <dd className="col-6 col-sm-4">
                  {company.city ? company.city : '-'}
                </dd>
                <dt className="col-6 col-sm-2">Region</dt>
                <dd className="col-6 col-sm-4">
                  {company.region ? company.region : '-'}
                </dd>
                <dt className="col-6 col-sm-2">Postal</dt>
                <dd className="col-6 col-sm-4">
                  {company.postal ? company.postal : '-'}
                </dd>
              </dl>
              <dl className="row mb-0 px-3 pb-2">
                <dt className="col-6 col-sm-2">Sales Agent</dt>
                <dd className="col-6 col-sm-4">
                  {company.salesAgent ? company.salesAgent.join(', ') : '-'}
                </dd>
              </dl>
            </Col>
            <Col sm={6}>{this.renderContact()}</Col>
          </Row>
          {editButton}
        </CardBody>
      </Row>
    );
  }
}

Info.contextType = UserContext;

export default Info;
