import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Loading = () => {
  return (
    <Container className="h-100">
      <Row className="h-100 justify-content-center align-items-center">
        <Col className="text-center">
          <div
            className="spinner-border"
            style={{ width: '3rem', height: '3rem' }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default Loading;
