import React, { PureComponent } from 'react';
import {
  Card,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown
} from 'reactstrap';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Label,
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line
} from 'recharts';
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';
import API from '../../api';
import Loading from '../../components/Loading';
import { UserContext } from '../../context/user';

import Header from '../../containers/Header';
import Activity from '../activity';

import { titleCase } from '../../util/processString';

const COLORS = [
  '#7fc97f',
  '#beaed4',
  '#fdc086',
  '#386cb0',
  '#f0027f',
  '#bf5b17',
  '#666666',
  '#ffff99',
  '#00daf3',
  '#0d44cb',
  '#08cfd8',
  '#0c7ce3',
  '#076389',
  '#04d234',
  '#0746b0',
  '#0e6c77',
  '#010531',
  '#058e9e',
  '#08dfda',
  '#0ba9d9',
  '#077758',
  '#047f18',
  '#0e8a9e',
  '#0e207f',
  '#07ad80',
  '#0569b6',
  '#0874ae',
  '#0c1c74'
];
// const MONTHLY_QUOTA = 15000000;
const YEARLY_QUOTA = 150000000;
class DashboardExecutive extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      receivables: {},
      payables: {},
      // last12Months: [],
      last12MonthsEveryone: [],
      last12MonthsEveryoneAgents: [],
      monthlyByYear: [],
      yearly: [],
      isLoading: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.getReports();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async getReports() {
    const {
      salesAllMonthly: monthlyByYearResult,
      salesAllYearly: yearlyResult,
      salesLast12MonthsEveryone: last12MonthsEveryoneResult,
      receivables: res,
      payables: pays
    } = await API.ReportsDashboard('executive');

    const receivables = {};
    res.forEach(rep => {
      const { value, period } = rep;
      receivables[period] = [
        { name: 'Unbilled', value: value.unbilledAmount },
        { name: 'Unpaid', value: value.invoiceBalance },
        { name: 'Paid', value: value.paidAmount }
      ];
    });

    const payables = {};
    pays.forEach(rep => {
      const { value, period } = rep;
      payables[period] = [
        { name: 'Unbilled', value: value.unbilledAmount },
        { name: 'Unpaid', value: value.invoiceBalance },
        { name: 'Paid', value: value.paidAmount }
      ];
    });

    // const last12Months = [];
    // let cur = moment().subtract(11, 'month');
    // let end = moment().format('YY-MM');
    // while (end >= cur.format('YY-MM')) {
    //   const curMonth = cur.format('YY-MM');
    //   const r = _.find(last12MonthsResult, v => v.period === curMonth);
    //   last12Months.push({
    //     name: cur.format('MMM YY'),
    //     sales: r ? r.value || 0 : 0
    //   });
    //   cur = cur.add(1, 'month');
    // }

    const yearly = [];
    let cur = moment().subtract(3, 'year');
    let end = moment().format('YYYY');
    while (end >= cur.format('YYYY')) {
      const curYear = cur.format('YYYY');
      const r = _.find(yearlyResult, v => v.period === curYear);
      yearly.push({
        name: cur.format('YYYY'),
        sales: r ? r.value || 0 : 0
      });
      cur = cur.add(1, 'year');
    }

    const monthlyByYear = [];
    const prevMonth = { '2017': 0, '2018': 0, '2019': 0, '2020': 0 };
    cur = moment('17-01', 'YY-MM');
    end = moment('17-12', 'YY-MM').format('YY-MM');
    while (end >= cur.format('YY-MM')) {
      const d = { name: cur.format('MMM') };

      let curMonth = `17-${cur.format('MM')}`;
      let r = _.find(monthlyByYearResult, v => v.period === curMonth);
      d['2017'] = prevMonth['2017'] + (r ? r.value || 0 : 0);
      prevMonth['2017'] = d['2017'];

      curMonth = `18-${cur.format('MM')}`;
      r = _.find(monthlyByYearResult, v => v.period === curMonth);
      d['2018'] = prevMonth['2018'] + (r ? r.value || 0 : 0);
      prevMonth['2018'] = d['2018'];

      curMonth = `19-${cur.format('MM')}`;
      r = _.find(monthlyByYearResult, v => v.period === curMonth);
      d['2019'] = prevMonth['2019'] + (r ? r.value || 0 : 0);
      prevMonth['2019'] = d['2019'];

      curMonth = `20-${cur.format('MM')}`;
      r = _.find(monthlyByYearResult, v => v.period === curMonth);
      if (r) {
        d['2020'] = prevMonth['2020'] + (r.value || 0);
        prevMonth['2020'] = d['2020'];
      }

      monthlyByYear.push(d);
      cur = cur.add(1, 'month');
    }

    const last12MonthsEveryone = [];
    const agents = {};
    cur = moment().subtract(11, 'month');
    end = moment().format('YY-MM');
    while (end >= cur.format('YY-MM')) {
      const curMonth = cur.format('YY-MM');
      const r = _.filter(
        last12MonthsEveryoneResult,
        v => v.period === curMonth
      );
      const l = { name: cur.format('MMM YY') };
      _.each(r, s => {
        if (!agents[s.label]) agents[s.label] = s.value;
        else agents[s.label] += s.value;
        l[s.label] = agents[s.label];
      });
      last12MonthsEveryone.push(l);

      cur = cur.add(1, 'month');
    }

    let mapAgents = _.map(agents, (total, name) => {
      return { total, name };
    });
    mapAgents = _.orderBy(mapAgents, ['total'], ['desc']);
    const last12MonthsEveryoneAgents = mapAgents;

    _.each(last12MonthsEveryoneAgents, a => {
      if (!last12MonthsEveryone[0][a.name]) {
        last12MonthsEveryone[0][a.name] = 0;
      }
    });

    if (this.mounted)
      this.setState({
        receivables,
        payables,
        last12MonthsEveryone,
        last12MonthsEveryoneAgents,
        monthlyByYear,
        yearly,
        loading: false
      });
  }

  renderCustomLabel = item => {
    const percent = Math.round(item.percent * 100);

    return (
      <text
        fill={item.fill}
        x={item.x}
        y={item.y}
        stroke="none"
        alignmentBaseline="middle"
        className="recharts-text recharts-pie-label-text"
        textAnchor="end"
      >
        <tspan x={item.x} textAnchor={item.textAnchor} dy="0em">
          {percent}%
        </tspan>
      </text>
    );
  };

  renderLegendText = (value, entry) => {
    const amount = numeral(entry.payload.value, 'PHP').format(' 0.[00]a');
    return `${value} - P${amount}`;
  };

  renderChart = (name, data) => {
    return (
      <ResponsiveContainer
        minWidth={200}
        minHeight={200}
        style={{ margin: 'auto' }}
      >
        <PieChart>
          <Pie
            data={data}
            innerRadius={30}
            outerRadius={50}
            paddingAngle={5}
            dataKey="value"
            nameKey="name"
            label={item => this.renderCustomLabel(item)}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${entry.name}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
            <Label value={name} position="center" />
          </Pie>
          <Legend
            layout="vertical"
            verticalAlign="middle"
            align="left"
            formatter={this.renderLegendText}
          />
        </PieChart>
      </ResponsiveContainer>
    );
  };

  formatYAxis = v => {
    const amount = numeral(v).format('0.[00]a');
    return `\u20B1${amount}`;
  };

  formatTooltip = value => {
    const amount = numeral(value).format('0.[00]a');
    return [`\u20B1${amount}`];
  };

  rebuildReport = async name => {
    const { isLoading } = this.state;
    if (isLoading) return;

    this.setState({ isLoading: true });

    if (name === 'all') {
      await API.ReportsRebuild('receivables')
        .then(() => alert(`Receivables rebuild started`))
        .catch(e => console.log(e));

      await API.ReportsRebuild('sales')
        .then(() => alert(`Sales rebuild started`))
        .catch(e => console.log(e));

      await API.ReportsRebuild('payables')
        .then(() => alert(`Payables rebuild started`))
        .catch(e => console.log(e));

      await API.ReportsRebuild('company')
        .then(() => alert(`Company rebuild started`))
        .catch(e => console.log(e));

      this.getReports();
      this.setState({ isLoading: false });
      return;
    }

    await API.ReportsRebuild(name)
      .then(() => {
        alert(`${titleCase(name)} rebuild started`);
        this.getReports();
        this.setState({ isLoading: false });
      })
      .catch(e => console.log(e));
  };

  rightNav = () => {
    const { isLoading } = this.state;
    const { user } = this.context;
    let kdc = null;
    if (user && user.role && user.role === 'kdc') {
      kdc = (
        <UncontrolledButtonDropdown className="mr-2" disabled={!!isLoading}>
          <DropdownToggle caret size="sm">
            KDC
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem header>Rebuild Report</DropdownItem>
            <DropdownItem onClick={() => this.rebuildReport('all')}>
              All
            </DropdownItem>
            <DropdownItem onClick={() => this.rebuildReport('receivables')}>
              Receivables
            </DropdownItem>
            <DropdownItem onClick={() => this.rebuildReport('sales')}>
              Sales
            </DropdownItem>
            <DropdownItem onClick={() => this.rebuildReport('payables')}>
              Payables
            </DropdownItem>
            <DropdownItem onClick={() => this.rebuildReport('company')}>
              Company
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      );
    }

    return <div>{kdc}</div>;
  };

  render() {
    const {
      loading,
      receivables,
      payables,
      monthlyByYear,
      last12MonthsEveryone,
      last12MonthsEveryoneAgents,
      yearly
    } = this.state;
    const { sidebar } = this.props;

    if (loading) {
      return (
        <div className="h-100">
          <Header sidebar={sidebar} title="Dashboard" />
          <Loading />
        </div>
      );
    }

    console.log(payables);

    return (
      <div>
        <Header
          sidebar={sidebar}
          title="Dashboard"
          mode="buttons"
          rightNav={this.rightNav()}
        />

        <div className="row mb-2">
          <div className="col-sm-6">
            <Card body className="mb-2">
              <CardTitle>
                <h4>Yearly Sales</h4>
              </CardTitle>
              <ResponsiveContainer
                width="100%"
                minWidth={300}
                minHeight={200}
                style={{ margin: 'auto' }}
              >
                <BarChart
                  data={yearly}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis tickFormatter={this.formatYAxis} />
                  <Tooltip formatter={this.formatTooltip} />
                  <Bar dataKey="sales" fill="#28a745">
                    {yearly.map(entry => {
                      let color = '';
                      if (entry.sales >= YEARLY_QUOTA) {
                        color = '#28a745';
                      } else if (entry.sales >= YEARLY_QUOTA * 0.5) {
                        color = '#ffc107';
                      } else {
                        color = '#dc3545';
                      }
                      return <Cell key={entry.name} fill={color} />;
                    })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Card>
            <Card body className="mb-2">
              <CardTitle>
                <h4>Monthly Sales by Year</h4>
              </CardTitle>
              <ResponsiveContainer
                width="100%"
                minWidth={300}
                minHeight={200}
                style={{ margin: 'auto' }}
              >
                <LineChart
                  data={monthlyByYear}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis tickFormatter={this.formatYAxis} />
                  <Tooltip formatter={this.formatTooltip} />
                  <Line dataKey="2017" stroke="#dc3545" connectNulls />
                  <Line dataKey="2018" stroke="#ffc107" connectNulls />
                  <Line dataKey="2019" stroke="#28a745" connectNulls />
                  <Line dataKey="2020" stroke="#2471A3" connectNulls />
                  <Legend />
                </LineChart>
              </ResponsiveContainer>
            </Card>
            <Card body className="mb-2">
              <CardTitle>
                <h4>Sales Agent&apos;s Performance in the Last 12 Months</h4>
              </CardTitle>
              <ResponsiveContainer
                width="100%"
                minWidth={300}
                minHeight={275}
                style={{ margin: 'auto' }}
              >
                <LineChart
                  data={last12MonthsEveryone}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis tickFormatter={this.formatYAxis} />
                  <Tooltip formatter={this.formatTooltip} />
                  {_.map(last12MonthsEveryoneAgents, (v, k) => {
                    return (
                      <Line
                        key={v.name}
                        type="monotone"
                        dataKey={v.name}
                        stroke={COLORS[k]}
                        connectNulls
                      />
                    );
                  })}
                  <Legend />
                </LineChart>
              </ResponsiveContainer>
            </Card>
            <Card body className="mb-2">
              <CardTitle>
                <h4>Receivables</h4>
              </CardTitle>
              <div className="row">
                <div className="col-sm-12" style={{ overflow: 'auto' }}>
                  {receivables['2017'] &&
                    this.renderChart('Total', receivables.total)}
                </div>
                <div className="col-sm-12" style={{ overflow: 'auto' }}>
                  {receivables['2020'] &&
                    this.renderChart('2020', receivables['2020'])}
                </div>
                <div className="col-sm-12" style={{ overflow: 'auto' }}>
                  {receivables['2019'] &&
                    this.renderChart('2019', receivables['2019'])}
                </div>
                <div className="col-sm-12" style={{ overflow: 'auto' }}>
                  {receivables['2018'] &&
                    this.renderChart('2018', receivables['2018'])}
                </div>
                <div className="col-sm-12" style={{ overflow: 'auto' }}>
                  {receivables['2017'] &&
                    this.renderChart('2017', receivables['2017'])}
                </div>
              </div>
            </Card>
            <Card body className="mb-2">
              <CardTitle>
                <h4>Payables</h4>
              </CardTitle>
              <div className="row">
                <div className="col-sm-12" style={{ overflow: 'auto' }}>
                  {payables['2019'] &&
                    this.renderChart('Total', payables.total)}
                </div>

                <div className="col-sm-12" style={{ overflow: 'auto' }}>
                  {payables['2020'] &&
                    this.renderChart('2020', payables['2020'])}
                </div>

                <div className="col-sm-12" style={{ overflow: 'auto' }}>
                  {payables['2019'] &&
                    this.renderChart('2019', payables['2019'])}
                </div>
              </div>
            </Card>
          </div>
          <div className="col-sm-6">
            <Activity />
          </div>
        </div>
      </div>
    );
  }
}

DashboardExecutive.contextType = UserContext;

export default DashboardExecutive;
