import getHistory from './history';

export const defaultOpts = {
  get: { method: 'get', credentials: 'include' },
  post: {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  },
  put: {
    method: 'put',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  },
  delete: {
    method: 'delete',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }
};

export const HTTP_OK = 200;
export const HTTP_CREATED = 201;
export const HTTP_NO_CONTENT = 204;
export const HTTP_MULTI_STATUS = 207;
export const HTTP_UNAUTHORIZED = 401;
export const HTTP_FORBIDDEN = 403;

export class kuha {
  static isSuccess(status) {
    if (status >= HTTP_OK && status < HTTP_NO_CONTENT) {
      return true;
    }
    return false;
  }

  static async mo(url, opts) {
    const response = await fetch(url, opts).catch(err => Promise.reject(err));
    if (this.isSuccess(response.status)) {
      const ret = await response.json().catch(err => Promise.reject(err));
      return ret;
    }
    if (response.status === HTTP_NO_CONTENT) {
      return Promise.resolve();
    }
    // show error in UI
    return Promise.reject(response);
  }

  static async get(url, opts = {}) {
    const newopts = {
      ...defaultOpts.get,
      ...opts
    };

    return this.mo(url, newopts);
  }

  static async post(url, body, opts = {}) {
    const newopts = {
      ...defaultOpts.post,
      ...opts,
      body: JSON.stringify(body)
    };

    return this.mo(url, newopts);
  }

  static async put(url, body, opts = {}) {
    const newopts = {
      ...defaultOpts.put,
      ...opts,
      body: JSON.stringify(body)
    };

    return this.mo(url, newopts);
  }

  static async delete(url, opts = {}) {
    const newopts = {
      ...defaultOpts.delete,
      ...opts
    };

    return this.mo(url, newopts);
  }
}

/**
 * Protected version of kuha where if we get forbidden,
 * user will be redirected to signout page automatically.
 */
export class kuhaP extends kuha {
  static async mo(url, opts) {
    const response = await fetch(url, opts).catch(err => {
      console.log('forbidden2', err);
      return Promise.reject(err);
    });
    if (response.status === HTTP_UNAUTHORIZED) {
      getHistory().push('/signout');
      return Promise.reject(response);
    }
    if (response.status === HTTP_FORBIDDEN) {
      getHistory().push('/app/dashboard');
      return Promise.reject(response);
    }
    if (this.isSuccess(response.status)) {
      const ret = await response.json().catch(err => Promise.reject(err));
      return ret;
    }
    if (response.status === HTTP_NO_CONTENT) {
      return Promise.resolve();
    }
    if (response.status === HTTP_MULTI_STATUS) {
      return Promise.resolve(response);
    }
    // show error in UI
    const error = await response
      .json()
      .then(res => Promise.reject(res))
      .catch(err => Promise.reject(err));
    return error;
  }
}
