import React, { Component } from 'react';
import { Container, Navbar, NavbarToggler, Collapse, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggleRightNav = this.toggleRightNav.bind(this);
    this.state = {
      collapsedRightNav: true
    };
  }

  toggleSidebar = () => {
    const { sidebar } = this.props;
    sidebar.toggleSidebar();
  };

  toggleRightNav() {
    const { collapsedRightNav } = this.state;
    this.setState({
      collapsedRightNav: !collapsedRightNav
    });
  }

  renderRightNavBar() {
    const { rightNav } = this.props;
    const { collapsedRightNav } = this.state;
    if (!rightNav) return null;

    const nav = [];

    nav.push(
      <NavbarToggler
        key="toggler"
        onClick={this.toggleRightNav}
        className="mr-0"
      />
    );
    nav.push(
      <Collapse key="collapse" isOpen={!collapsedRightNav} navbar>
        {rightNav}
      </Collapse>
    );
    return nav;
  }

  render() {
    const { sidebar, title, mode, rightNav } = this.props;
    return (
      <Navbar light className="navbar-expand-lg border-bottom px-2 mb-2 mx-n2">
        <Container fluid className="pl-0 pr-2">
          <div className="navbar-wrapper">
            <Button
              outline
              color="secondary"
              size="sm"
              onClick={this.toggleSidebar}
            >
              <FontAwesomeIcon icon={sidebar.hidden ? 'list' : 'bars'} />
            </Button>
            <h3 className="pl-3 pt-2 text-dark">{title}</h3>
          </div>
          {mode === 'buttons' ? rightNav : this.renderRightNavBar()}
        </Container>
      </Navbar>
    );
  }
}

export default Header;
