import React, { Component } from 'react';
import {
  Card,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap';
import { Formik, Form, Field, FieldArray } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import _ from 'lodash';
import { UserContext } from '../../context/user';
import SalesAgent from './salesAgent';
import Loading from '../../components/Loading';
import Header from '../../containers/Header';
import API from '../../api';

const INIT_PERSON = {
  _id: Math.floor(Math.random() * 10000 + 1),
  name: '',
  mobile: '',
  email: '',
  designation: ''
};

const INIT_COMPANY = {
  _id: '',
  name: '',
  address1: '',
  address2: '',
  city: '',
  region: '',
  postal: '',
  landline: '',
  fax: '',
  salesAgent: [],
  salesAgentId: [],
  contactPerson: [INIT_PERSON]
};

class CompanyForm extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    const { params } = match;
    this.state = {
      mode: params.id ? 'edit' : 'add',
      id: params.id,
      company: INIT_COMPANY,
      possibleDuplicate: [],
      loading: true
    };
    this.delayTimer = null;
  }

  componentDidMount() {
    this.mounted = true;
    this.getCompany();
  }

  componentWillUnmount() {
    if (this.delayTimer) this.delayTimer.cancel();
    this.mounted = false;
  }

  onSubmit = (values, { setSubmitting, setStatus, setErrors }) => {
    const { possibleDuplicate, mode, id } = this.state;
    const { history } = this.props;

    if (possibleDuplicate.length > 0) {
      let duplicates = '';
      possibleDuplicate.forEach(dup => {
        duplicates += duplicates === '' ? dup.name : `\n${dup.name}`;
      });
      if (
        !window.confirm(
          `There are possible duplicate company name(s):\n${duplicates}\n\nAre you sure you want to continue?`
        )
      ) {
        setSubmitting(false);
      }
    }

    const data = {};
    // send only keys inside INIT_COMPANY
    _.forEach(INIT_COMPANY, (val, key) => {
      if (key === 'contactPerson') return;
      data[key] = values[key];
    });

    values.contactPerson.forEach(person => {
      if (
        person.name.trim() !== '' ||
        person.mobile.trim() !== '' ||
        person.email.trim() !== '' ||
        person.designation.trim() !== ''
      ) {
        if (!data.contactPerson) {
          data.contactPerson = [];
        }
        const p = { ...person };
        delete p._id;
        data.contactPerson.push(p);
      }
    });

    if (mode === 'add') {
      delete data._id;
    }

    const promise =
      mode === 'edit' ? API.CompanyUpdate(data) : API.CompanyAdd(data);
    promise
      .then(company => {
        if (!this.mounted) return;
        setSubmitting(false);
        const _id = company ? company._id : id;
        history.push(`/app/company/${_id}`);
      })
      .catch(e => {
        console.log(e);
        setErrors({ submit: e.message });
        setStatus({ success: false });
        setSubmitting(false);
      });
  };

  getCompany() {
    const { id } = this.state;
    if (!id) {
      this.setState({ loading: false });
      return;
    }

    API.Company(id)
      .then(company => {
        if (!this.mounted) return;

        if (!company.contactPerson || company.contactPerson.length <= 0) {
          company.contactPerson = [INIT_PERSON];
        }
        if (!company.salesAgent || company.salesAgent.length <= 0) {
          company.salesAgent = [];
        }
        if (!company.salesAgentId || company.salesAgentId.length <= 0) {
          company.salesAgentId = [];
        }
        this.setState({
          company: { ...INIT_COMPANY, ...company },
          loading: false
        });
      })
      .catch(e => {
        console.log(e);
        if (!this.mounted) return;
        this.setState({ loading: false });
      });
  }

  checkForDuplicate(value) {
    const { id } = this.state;
    if (!value) return Promise.resolve([]);
    const company = value.trim();
    if (company.length <= 2) {
      this.setState({ possibleDuplicate: [] });
      return Promise.resolve([]);
    }
    return API.CompanySearch(company)
      .then(possibleDuplicate => {
        if (!this.mounted) return [];

        const dups = _.filter(possibleDuplicate, dup => {
          return dup._id !== id;
        });

        this.setState({ possibleDuplicate: dups });
        return dups;
      })
      .catch(() => Promise.resolve([]));
  }

  delaySearch(value) {
    let timeout = null;
    const p = new Promise(resolve => {
      timeout = setTimeout(() => {
        this.checkForDuplicate(value)
          .then(duplicates => {
            if (!duplicates || !value) return resolve(true);
            for (let i = 0; i < duplicates.length; i += 1) {
              if (
                duplicates[i].name.toLowerCase() === value.trim().toLowerCase()
              ) {
                return resolve(false); // invalid
              }
            }
            // return true to be valid
            return resolve(true);
          })
          .catch(e => console.log(e));
      }, 300);
    });

    return {
      promise: p,
      cancel: () => clearTimeout(timeout)
    };
  }

  rightNav() {
    const { history } = this.props;
    return (
      <div>
        <Button color="danger" size="sm" onClick={history.goBack}>
          Back
        </Button>
      </div>
    );
  }

  render() {
    const { possibleDuplicate, mode, loading, company } = this.state;
    const { sidebar } = this.props;
    const { user } = this.context;
    const { role } = user;

    if (loading) {
      return (
        <div className="mt-5">
          <Loading className="h-100" />
        </div>
      );
    }

    let duplicates = false;
    if (possibleDuplicate.length > 0) {
      duplicates = '';
      possibleDuplicate.forEach(dup => {
        duplicates += duplicates === '' ? dup.name : `, ${dup.name}`;
      });
    }

    const validationSchema =
      mode === 'edit'
        ? Yup.object().shape({
            name: Yup.string()
              .min(3, 'Too Short!')
              .max(100, 'Too Long!')
              .test(
                /* name */ 'unique-name',
                /* failure message */ 'Company name already exists',
                value => {
                  // delayTimer is protection for fast typing.
                  // Only check for duplicate after 1 second pause.
                  if (this.delayTimer) {
                    this.delayTimer.cancel();
                  }
                  this.delayTimer = this.delaySearch(value);
                  return this.delayTimer.promise
                    .then(res => {
                      this.delayTimer = null;
                      return res;
                    })
                    .catch(e => console.log(e));
                }
              )
              .required('Required'),
            postal: Yup.string()
              .min(4, 'Too Short!')
              .max(4, 'Too Long!')
          })
        : Yup.object().shape({
            name: Yup.string()
              .min(3, 'Too Short!')
              .max(100, 'Too Long!')
              .test(
                /* name */ 'unique-name',
                /* failure message */ 'Company name already exists',
                value => {
                  // delayTimer is protection for fast typing.
                  // Only check for duplicate after 1 second pause.
                  if (this.delayTimer) {
                    this.delayTimer.cancel();
                  }
                  this.delayTimer = this.delaySearch(value);
                  return this.delayTimer.promise
                    .then(res => {
                      this.delayTimer = null;
                      return res;
                    })
                    .catch(e => console.log(e));
                }
              )
              .required('Required'),
            postal: Yup.string()
              .min(4, 'Too Short!')
              .max(4, 'Too Long!')
          });

    return (
      <div>
        <Header
          sidebar={sidebar}
          title={mode === 'edit' ? 'Edit Company' : 'Add Company'}
          mode="buttons"
          rightNav={this.rightNav()}
        />
        <div className="row">
          <div className="col-sm-12">
            <Card body>
              <Formik
                initialValues={company}
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
              >
                {({ values, errors, touched, isSubmitting }) => (
                  <Form autoComplete="new-password">
                    {errors.submit && (
                      <Row form>
                        <Col>
                          <p className="text-danger">{errors.submit}</p>
                        </Col>
                      </Row>
                    )}
                    <Row form>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="name">Company Name</Label>
                          <Input
                            tag={Field}
                            type="text"
                            name="name"
                            autoComplete="off"
                            invalid={errors.name && touched.name}
                          />
                          <Input tag={Field} type="hidden" name="_id" />
                          <FormFeedback>{errors.name}</FormFeedback>
                          {duplicates && (
                            <span className="form-text">
                              Possible duplicate:
                              <br />
                              {possibleDuplicate &&
                                possibleDuplicate.length > 0 &&
                                possibleDuplicate.map(dup => (
                                  <div key={dup._id}>
                                    <strong className="text-danger text-bold">
                                      {dup.name}
                                    </strong>
                                  </div>
                                ))}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                      {role !== 'sales' && (
                        <Col sm={6}>
                          <FormGroup>
                            <Label for="salesAgent">Sales Agent</Label>
                            <Field
                              name="salesAgent"
                              multi
                              component={SalesAgent}
                            />
                            <FormFeedback>{errors.salesAgent}</FormFeedback>
                            <Input
                              tag={Field}
                              type="hidden"
                              name="salesAgentId"
                            />
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <Row form>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="address1">Address 1</Label>
                          <Input tag={Field} type="text" name="address1" />
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="address2">Address 2</Label>
                          <Input
                            tag={Field}
                            type="text"
                            name="address2"
                            placeholder=""
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="city">City</Label>
                          <Input
                            tag={Field}
                            type="text"
                            name="city"
                            id="city"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="region">Region</Label>
                          <Input
                            tag={Field}
                            type="text"
                            name="region"
                            id="region"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <Label for="postal">Postal Code</Label>
                          <Input
                            tag={Field}
                            type="text"
                            name="postal"
                            invalid={errors.postal && touched.postal}
                          />
                          <FormFeedback>{errors.postal}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="landline">Landline Number</Label>
                          <Input tag={Field} type="text" name="landline" />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="fax">Fax Number</Label>
                          <Input tag={Field} type="text" name="fax" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h3 className="h4 mb-3">Contact Persons</h3>
                      </Col>
                    </Row>
                    <FieldArray
                      name="contactPerson"
                      render={arrayHelpers => (
                        <div>
                          {values.contactPerson &&
                          values.contactPerson.length > 0 ? (
                            values.contactPerson.map((person, index) => (
                              <Row key={person._id} form>
                                <Col sm={3}>
                                  <Input
                                    tag={Field}
                                    type="text"
                                    name={`contactPerson[${index}].name`}
                                    placeholder="Name"
                                  />
                                </Col>
                                <Col sm={3}>
                                  <Input
                                    tag={Field}
                                    type="text"
                                    name={`contactPerson[${index}].mobile`}
                                    placeholder="Mobile Number"
                                  />
                                </Col>
                                <Col sm={3}>
                                  <Input
                                    tag={Field}
                                    type="email"
                                    name={`contactPerson[${index}].email`}
                                    placeholder="Email"
                                  />
                                </Col>
                                <Col sm={2}>
                                  <Input
                                    tag={Field}
                                    type="designation"
                                    name={`contactPerson[${index}].designation`}
                                    placeholder="Designation"
                                  />
                                </Col>
                                <Col sm={1} className="d-flex flex-column">
                                  <FormGroup className="mt-auto">
                                    {values.contactPerson.length ===
                                    index + 1 ? (
                                      <Button
                                        type="button"
                                        color="success"
                                        onClick={() =>
                                          arrayHelpers.push({
                                            _id: Math.floor(
                                              Math.random() * 10000 + 1
                                            ),
                                            name: '',
                                            mobile: '',
                                            email: '',
                                            designation: ''
                                          })
                                        }
                                      >
                                        <FontAwesomeIcon
                                          className="icon"
                                          icon="plus"
                                        />
                                      </Button>
                                    ) : (
                                      <Button
                                        type="button"
                                        color="danger"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          className="icon"
                                          icon="minus"
                                        />
                                      </Button>
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                            ))
                          ) : (
                            <div>No</div>
                          )}
                        </div>
                      )}
                    />

                    <FormGroup row>
                      <Col>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          Submit
                        </Button>
                      </Col>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

CompanyForm.contextType = UserContext;

export default CompanyForm;
