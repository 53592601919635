const camelCaseToSpaces = text => {
  return text
    .replace(/([A-Z])/g, ' $1') // uppercase the first character
    .replace(/^./, str => {
      return str;
    });
};

export const camelCaseToSpacesLowerCase = text => {
  return camelCaseToSpaces(text).toLowerCase();
};

export const titleCase = title => {
  if (!title) return '';

  return title
    .trim()
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

export default camelCaseToSpaces;
