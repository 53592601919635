import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import API from '../../api';

export default class SalesAgent extends Component {
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  nameOptions = agent => {
    return API.SalesAgentSearch(agent)
      .then(list => {
        if (!this.mounted) return [];
        return list.map(a => {
          return { value: a._id, label: a.name };
        });
      })
      .catch(() => Promise.resolve([]));
  };

  onNameSelect = agent => {
    const { form } = this.props;
    const { values } = form;
    values.salesAgent = agent.map(a => {
      return a.label;
    });
    values.salesAgentId = agent.map(a => {
      return a.value;
    });
    form.setValues(values);
  };

  noOptionsMessage = ({ inputValue }) => {
    if (inputValue && inputValue.length < 3) {
      return 'Enter at least 3 letters';
    }
    return 'No results found';
  };

  render() {
    const { form, name } = this.props;
    const { values } = form;
    const value = [];
    if (values.salesAgent.length > 0) {
      values.salesAgent.forEach((label, k) => {
        const val = values.salesAgentId[k];
        value.push({ label, value: val });
      });
    }

    return (
      <AsyncSelect
        isMulti
        placeholder="type agent name"
        openMenuOnClick={false}
        loadOptions={this.nameOptions}
        onChange={this.onNameSelect}
        noOptionsMessage={this.noOptionsMessage}
        value={value}
        name={name}
      />
    );
  }
}
