import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';

export default class SalesAgent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      salesAgentOptions: this.defaultOptions()
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  defaultOptions = () => {
    const { assignedSalesAgent, assignedSalesAgentId } = this.props;
    const assignedList = [];
    assignedSalesAgent.forEach((value, key) => {
      if (!(assignedSalesAgentId && assignedSalesAgent)) return;

      assignedList.push({
        value: assignedSalesAgentId[key],
        label: assignedSalesAgent[key]
      });
    });

    return assignedList;
  };

  nameOptions = async agent => {
    if (!this.mounted) return [];

    const { salesAgentOptions } = this.state;

    const list = [];
    salesAgentOptions.map(sa => {
      const { label, value } = sa;
      if (label.toLowerCase().includes(agent.toLowerCase())) {
        list.push({ value, label });
      }

      return null;
    });

    return Promise.resolve(list);
  };

  onNameSelect = agent => {
    const { form } = this.props;
    const { values } = form;
    values.salesAgent = agent.label;
    values.salesAgentId = agent.value;
    form.setValues(values);
  };

  noOptionsMessage = () => {
    return 'No results found';
  };

  render() {
    const { salesAgentOptions } = this.state;
    const { form, name } = this.props;
    const { values } = form;
    const value = { label: values.salesAgent, value: values.salesAgent };

    return (
      <AsyncSelect
        placeholder="type agent name"
        openMenuOnClick
        defaultOptions={salesAgentOptions}
        loadOptions={this.nameOptions}
        onChange={this.onNameSelect}
        noOptionsMessage={this.noOptionsMessage}
        value={value}
        name={name}
      />
    );
  }
}
