import React, { Component } from 'react';
import { Button, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import SalesOrders from '../salesOrder/salesOrders';

import { UserContext } from '../../context/user';

import API from '../../api';

import {
  ROLE_KDC,
  ROLE_EXECUTIVE,
  ROLE_SALES,
  ROLE_DOCUMENT_CONTROLLER
} from '../../util/roles';

class LinkSoForm extends Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.LinkSoForm = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setValidationSchema = Yup.object().shape({
    soId: Yup.string().required('Required'),
    soNum: Yup.string().required('Required')
  });

  composeLinkSoFormFields = (errors, touched, isSubmitting) => {
    const { user } = this.context;

    const excludedValues = [];
    if (
      [ROLE_KDC, ROLE_EXECUTIVE, ROLE_SALES, ROLE_DOCUMENT_CONTROLLER].indexOf(
        user.role
      ) === -1
    ) {
      excludedValues.push('Approved');
    }

    return (
      <div className="col-xs-12 m-a">
        <Row form>
          <Col sm={12}>
            <FormGroup>
              <Label for="soNum">SO Number</Label>
              <Field
                name="soNum"
                component={SalesOrders}
                disabled={isSubmitting}
                invalid={errors.soNum && touched.soNum}
                includeCompanyInfo
              />
              {errors.soNum && touched.soNum ? (
                <div className="text-danger">{errors.soNum}</div>
              ) : null}
              <Input tag={Field} type="hidden" name="soId" />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  };

  composeLinkSoFormButtons = (
    errors,
    touched,
    isSubmitting,
    toggleLinkSoModal
  ) => {
    return (
      <div className="col-sm-12">
        <FormGroup row>
          <Col className="text-right">
            <Button type="submit" color="primary" className="mr-1">
              Link
            </Button>
            <Button type="button" color="danger" onClick={toggleLinkSoModal}>
              Cancel
            </Button>
          </Col>
        </FormGroup>
      </div>
    );
  };

  setInitialValues = () => {
    const { selectedProject } = this.props;
    const { companyId } = selectedProject;

    return { soId: '', soNum: '', companyId };
  };

  onSubmit = (values, { setSubmitting, setStatus, setErrors }) => {
    const { setLastUpdate, selectedProject, toggleLinkSoModal } = this.props;

    if (selectedProject) values._id = selectedProject._id;

    return API.ProjectLinkSo(values)
      .then(() => {
        if (!this.mounted) return;
        setSubmitting(false);
        setLastUpdate('listUpdateTs', new Date().getTime());
        toggleLinkSoModal();
      })
      .catch(e => {
        console.log('LinkSoForm', e);
        setErrors({ submit: e.message });
        setStatus({ success: false });
        setSubmitting(false);
        alert(e.message);
      });
  };

  render() {
    const { toggleLinkSoModal } = this.props;

    return (
      <Formik
        initialValues={this.setInitialValues()}
        validationSchema={this.setValidationSchema}
        onSubmit={this.onSubmit}
        ref={this.LinkSoForm}
      >
        {props => {
          const { errors, touched, isSubmitting } = props;
          return (
            <Form>
              {this.composeLinkSoFormFields(errors, touched, isSubmitting)}
              {this.composeLinkSoFormButtons(
                errors,
                touched,
                isSubmitting,
                toggleLinkSoModal
              )}
            </Form>
          );
        }}
      </Formik>
    );
  }
}

LinkSoForm.contextType = UserContext;

export default LinkSoForm;
