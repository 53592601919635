import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Purchasing from '../users/purchasing';
import { UserContext } from '../../context/user';

import API from '../../api';

class PurchaseOrderForm extends Component {
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setInitialValues = () => {
    const { companyName, companyId, po, selectedProject: sp } = this.props;

    const purchasingOfficerName = '';
    const purchasingOfficerId = '';

    const dateToday = moment().format('YYYY-MM-DD');

    if (sp) {
      return {
        poNum: sp ? sp.poNum : '',
        poAmount: sp ? sp.lastQuotation : 0,
        soDate: dateToday,
        companyName: sp.companyName,
        companyId: sp.companyId,
        poDate: dateToday,
        deliveryDate: '',
        mrfNo: sp ? sp.mrfNo : '',
        projectId: sp._id,
        projectName: sp ? sp.projectName : '',
        description: '',
        purchasingOfficerName: sp ? sp.purchasingOfficerName : '',
        purchasingOfficerId: sp ? sp.purchasingOfficer : '',
        terms: sp
          ? sp.quotations[sp.lastQuotationRevisionNumber].scopeOfWork
          : '',
        remarks: ''
      };
    }

    return {
      poNum: po ? po.poNum : '',
      poAmount: po ? po.poAmount : 0,
      companyName,
      companyId,
      poDate:
        po && po.poDate ? moment(po.poDate).format('YYYY-MM-DD') : dateToday,
      deliveryDate:
        po && po.deliveryDate
          ? moment(po.deliveryDate).format('YYYY-MM-DD')
          : '',
      mrfNo: po ? po.mrfNo : '',
      projectId: po ? po.projectId : '',
      projectName: po ? po.projectName : '',
      description: po && po.description ? po.description : '',
      purchasingOfficerName: po
        ? po.purchasingOfficerName
        : purchasingOfficerName,
      purchasingOfficerId: po ? po.purchasingOfficerId : purchasingOfficerId,
      terms: po ? po.terms : '',
      remarks: po && po.remarks ? po.remarks : ''
    };
  };

  setValidationSchema = Yup.object().shape({
    poNum: Yup.string().required('Required'),
    poAmount: Yup.number()
      .moreThan(0, 'Needs to be more than zero')
      .required('Required'),
    poDate: Yup.date().required('Required'),
    purchasingOfficerName: Yup.string().required('Required')
  });

  onSubmit = (values, { setSubmitting, setStatus, setErrors }) => {
    const { po, selectedProject, done, setLastUpdate, close } = this.props;
    if (po) {
      values._id = po._id;
      if (po.invoiceAmount > values.poAmount) {
        setErrors({
          submit: 'Contract price cannot be less than Invoiced amount'
        });
        setStatus({ success: false });
        setSubmitting(false);
        return Promise.reject();
      }
    }

    // fix date
    values.poDate = moment(values.poDate).isValid() ? values.poDate : '';
    values.deliveryDate = moment(values.deliveryDate).isValid()
      ? values.deliveryDate
      : '';

    const promise = po
      ? API.PurchaseOrderUpdate(values)
      : API.PurchaseOrderAdd(values);
    return promise
      .then(newPo => {
        if (selectedProject) {
          API.ProjectUpdate({
            _id: selectedProject._id,
            status: 'Approved',
            poId: newPo._id,
            poNum: newPo.poNum
          })
            .then(() => {
              alert('PO successfully created.');
            })
            .catch(e => {
              console.log('PurchaseOrderForm', e);
            });
        }

        if (!this.mounted) return;
        setSubmitting(false);

        if (selectedProject) {
          setLastUpdate('listUpdateTs', new Date().getTime());
          close();
        } else {
          done();
        }
      })
      .catch(e => {
        console.log('PurchaseOrderForm', e);
        setErrors({ submit: e.message });
        setStatus({ success: false });
        setSubmitting(false);
      });
  };

  composePurchaseOrderFormFields = (errors, touched, isSubmitting) => {
    return (
      <div className="col-xs-12 m-a">
        {errors.submit && (
          <Row form>
            <Col>
              <p className="text-danger">{errors.submit}</p>
            </Col>
          </Row>
        )}
        <Row form>
          <Col sm={6}>
            <FormGroup>
              <Label for="poNum">PO Number *</Label>
              <Input
                tag={Field}
                type="text"
                name="poNum"
                disabled={isSubmitting}
                invalid={errors.poNum && touched.poNum}
              />
              <FormFeedback>{errors.poNum}</FormFeedback>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="poDate">PO Date *</Label>
              <Input
                tag={Field}
                type="date"
                name="poDate"
                disabled={isSubmitting}
                invalid={errors.poDate && touched.poDate}
              />
              <FormFeedback>{errors.poDate}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={6}>
            <FormGroup>
              <Label for="companyName">Company Name</Label>
              <Input
                tag={Field}
                readOnly
                type="text"
                name="companyName"
                className="form-control-plaintext pl-2"
              />
              <Input tag={Field} type="hidden" name="companyId" />
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="purchasingOfficerName">Purchasing Officer *</Label>
              <div
                className={
                  errors.purchasingOfficerName && touched.purchasingOfficerName
                    ? 'error-border'
                    : ''
                }
              >
                <Field name="purchasingOfficerName" component={Purchasing} />
              </div>
              {errors.purchasingOfficerName && touched.purchasingOfficerName ? (
                <div className="text-danger form-feedback">
                  {errors.purchasingOfficerName}
                </div>
              ) : null}
              <Input tag={Field} type="hidden" name="purchasingOfficerId" />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={6}>
            <FormGroup>
              <Label for="poAmount">Contract Price *</Label>
              <Input
                tag={Field}
                type="number"
                name="poAmount"
                disabled={isSubmitting}
                invalid={errors.poAmount && touched.poAmount}
                min="0"
                step="0.01"
              />
              <FormFeedback>{errors.poAmount}</FormFeedback>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="deliveryDate">Delivery Date</Label>
              <Input
                tag={Field}
                type="date"
                name="deliveryDate"
                disabled={isSubmitting}
                invalid={errors.deliveryDate && touched.deliveryDate}
              />
              <FormFeedback>{errors.deliveryDate}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={6}>
            <FormGroup>
              <Label for="mrfNo">MRF Number</Label>
              <Input
                tag={Field}
                type="text"
                pattern="[0-9\-]+"
                name="mrfNo"
                title="Enter number and dash(-) only"
                disabled={isSubmitting}
                invalid={errors.mrfNo && touched.mrfNo}
              />
              <FormFeedback>{errors.mrfNo}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={12}>
            <FormGroup>
              <Label for="terms">Description</Label>
              <Input
                tag={Field}
                type="text"
                name="description"
                placeholder=""
                disabled={isSubmitting}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={12}>
            <FormGroup>
              <Label for="terms">Terms</Label>
              <Input
                tag={Field}
                type="text"
                name="terms"
                disabled={isSubmitting}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={12}>
            <FormGroup>
              <Label for="terms">Remarks</Label>
              <Input
                tag={Field}
                type="text"
                name="remarks"
                placeholder=""
                disabled={isSubmitting}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  };

  composePurchaseOrderFormButtons = (errors, touched, isSubmitting) => {
    const { close, po, addButtonLabel } = this.props;

    return (
      <div className="col-sm-12">
        <FormGroup row>
          <Col className="text-right">
            <Button
              type="submit"
              color="primary"
              className="mr-1"
              disabled={isSubmitting}
              onClick={e => {
                const eventTarget = e.target;
                setTimeout(() => {
                  eventTarget.disabled = true;

                  setTimeout(() => {
                    eventTarget.disabled = isSubmitting;
                  }, 2000);
                });

                return true;
              }}
            >
              {addButtonLabel || (po ? 'Save' : 'Add')}
            </Button>
            <Button type="button" color="danger" onClick={close}>
              Cancel
            </Button>
          </Col>
        </FormGroup>
      </div>
    );
  };

  render() {
    return (
      <Formik
        initialValues={this.setInitialValues()}
        validationSchema={this.setValidationSchema}
        onSubmit={this.onSubmit}
      >
        {props => {
          const { errors, touched, isSubmitting } = props;
          return (
            <Form noValidate>
              {this.composePurchaseOrderFormFields(
                errors,
                touched,
                isSubmitting
              )}

              {this.composePurchaseOrderFormButtons(
                errors,
                touched,
                isSubmitting
              )}
            </Form>
          );
        }}
      </Formik>
    );
  }
}

PurchaseOrderForm.contextType = UserContext;

export default PurchaseOrderForm;
