import React, { Component } from 'react';
import { Card, Table, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/lib/Async';
import { debounce } from 'lodash';
import { UserContext } from '../../context/user';
import Pagination from '../../components/Pagination';
import RowsPerPage from '../../components/RowsPerPage';
import API from '../../api';
import { DEFAULT_PER_PAGE } from '../../constants/others';

class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      page: 1,
      pages: 1,
      perPage: DEFAULT_PER_PAGE,
      loading: true
    };
    this.debounceProject = debounce(this.getProjects, 500);
  }

  componentDidMount() {
    this.mounted = true;
    const { page, perPage } = this.state;
    let storedPerPage = localStorage.getItem('project-perPage');
    if (storedPerPage) {
      storedPerPage = parseInt(storedPerPage, 10);
      this.setState({ perPage: storedPerPage });
      this.getList(page, storedPerPage);
    } else {
      this.getList(page, perPage);
    }
  }

  componentDidUpdate(prevProps) {
    const { listUpdateTs } = this.props;
    const { page, perPage } = this.state;
    if (prevProps.listUpdateTs !== listUpdateTs) {
      this.getList(page, perPage);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setCurrentPage = curPage => {
    const { page, perPage } = this.state;
    if (curPage === page) {
      return;
    }
    this.setState({ page: curPage, loading: true }, () =>
      this.getList(curPage, perPage)
    );
  };

  onNameSelect = project => {
    const { history } = this.props;
    history.push(`/app/project/${project.value}`);
  };

  setRowsPerPage = newPerPage => {
    const { page, perPage } = this.state;
    if (newPerPage === perPage) {
      return;
    }

    localStorage.setItem('project-perPage', newPerPage);
    this.setState({ page: 1, perPage: newPerPage, loading: true }, () =>
      this.getList(page, newPerPage)
    );
  };

  getList(page, perPage) {
    API.Projects(page, perPage)
      .then(res => {
        if (!this.mounted) return;
        this.setState({ ...res, loading: false });
      })
      .catch(err => {
        console.log(err);
        if (!this.mounted) return;
        this.setState({ loading: false });
      });
  }

  getProjects = (project, cb) => {
    API.ProjectSearch(project)
      .then(list => {
        if (!this.mounted) return;
        const options = list.map(proj => {
          return { value: proj._id, label: proj.name };
        });
        cb(options);
      })
      .catch(() => cb([]));
  };

  loadOptions = (project, cb) => {
    this.debounceProject(project, cb);
  };

  rebuildCache = name => {
    API.SearchRebuild(name)
      .then(() => alert('Rebuild started'))
      .catch(e => console.log(e));
  };

  renderLoading = () => {
    return (
      <tr key="spinner">
        <td colSpan={7} className="text-center">
          <span className="spinner-border" role="status" aria-hidden="true" />
        </td>
      </tr>
    );
  };

  noOptionsMessage = ({ inputValue }) => {
    if (inputValue && inputValue.length < 3) {
      return 'Enter at least 3 letters';
    }
    return 'No results found';
  };

  renderList() {
    const { list } = this.state;
    return list.map(row => {
      const projectUrl = `/app/project/${row._id}`;

      return (
        <tr key={row.reference}>
          <td>
            <Link to={projectUrl}>{row.reference}</Link>
          </td>
          <td className="text-center">{row.projectName}</td>
          <td className="text-center">{row.companyName}</td>
          <td className="text-center">{row.product}</td>
          <td className="text-center">{row.salesAgentUserName}</td>
          <td className="text-center">{row.status}</td>
        </tr>
      );
    });
  }

  render() {
    const { loading, page, pages, perPage } = this.state;

    return (
      <div className="row">
        <div className="col-sm-12">
          <Card body>
            <Row>
              <Col sm={6}>
                <AsyncSelect
                  placeholder="Search project"
                  openMenuOnClick={false}
                  loadOptions={this.loadOptions}
                  onChange={this.onNameSelect}
                  noOptionsMessage={this.noOptionsMessage}
                  className="mb-2"
                />
              </Col>
              <Col sm={6}>
                <Pagination
                  page={page}
                  pages={pages}
                  setCurrentPage={this.setCurrentPage}
                />
              </Col>
            </Row>
            <Table striped hover responsive>
              <thead>
                <tr className="text-center">
                  <th>Reference</th>
                  <th>Name</th>
                  <th>Company</th>
                  <th>Product</th>
                  <th>Sales Agent</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {loading ? this.renderLoading() : this.renderList()}
              </tbody>
            </Table>
            <Row>
              <Col sm={6}>
                <RowsPerPage
                  perPage={perPage}
                  setRowsPerPage={this.setRowsPerPage}
                />
              </Col>
              <Col sm={6}>
                <Pagination
                  page={page}
                  pages={pages}
                  setCurrentPage={this.setCurrentPage}
                />
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    );
  }
}

ProjectList.contextType = UserContext;

export default ProjectList;
