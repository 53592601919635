import React, { Component } from 'react';
import { Form, FormGroup, Table, Badge, Button, Input } from 'reactstrap';
import moment from 'moment';
import currencyFormat from '../../util/currency';
import dateFormat from '../../util/date';
import API from '../../api';

class BankTrnxList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading: false,
      reschedId: null,
      newDate: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { page = 1, perPage = 50, query } = this.props;
    this.getList(page, perPage, query);
  }

  componentDidUpdate(props) {
    const { refresh } = this.props;
    const { query, page = 1, perPage = 50 } = this.props;
    const { bankId, startDate, endDate } = query;

    if (props.refresh !== refresh) {
      this.getList(page, perPage, query);
    } else if (props.page !== page) {
      this.getList(props.page, perPage, query);
    } else if (
      bankId &&
      startDate &&
      endDate &&
      (props.query.bankId !== bankId ||
        props.query.startDate !== startDate ||
        props.query.endDate !== endDate)
    ) {
      this.getList(props.page, perPage, props.query);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getList(page, perPage, query) {
    const { setPages, loading } = this.props;

    if (loading) return;

    // console.log(query);
    this.setState({ loading: true });
    API.BankTransactions(page, perPage, query)
      .then(res => {
        if (!this.mounted) return;
        this.setState({ ...res, loading: false });
        if (setPages) setPages(res.pages);
      })
      .catch(err => {
        console.log(err);
        if (!this.mounted) return;
        this.setState({ loading: false });
      });
  }

  handleBounce = _id => {
    this.setState({ loading: true });
    API.BankTrnxBounce({ _id })
      .then(() => {
        if (!this.mounted) return;
        this.setState({ loading: false });
        const { handleSuccess } = this.props;
        if (handleSuccess) handleSuccess('Cheque set to bounce');
      })
      .catch(err => {
        console.log(err);
        if (!this.mounted) return;
        this.setState({ loading: false });
        const { handleError } = this.props;
        if (handleError) handleError(err.message);
      });
  };

  handleClearing = _id => {
    this.setState({ loading: true });
    API.BankTrnxClearing({ _id })
      .then(() => {
        if (!this.mounted) return;
        this.setState({ loading: false });
        const { handleSuccess } = this.props;
        if (handleSuccess) handleSuccess('Cheque set to clearing');
      })
      .catch(err => {
        console.log(err);
        if (!this.mounted) return;
        this.setState({ loading: false });
        const { handleError } = this.props;
        if (handleError) handleError(err.message);
      });
  };

  handleCleared = _id => {
    this.setState({ loading: true });
    API.BankTrnxCleared({ _id })
      .then(() => {
        if (!this.mounted) return;
        this.setState({ loading: false });
        const { handleSuccess } = this.props;
        if (handleSuccess) handleSuccess('Transaction set to cleared');
      })
      .catch(err => {
        console.log(err);
        if (!this.mounted) return;
        this.setState({ loading: false });
        const { handleError } = this.props;
        if (handleError) handleError(err.message);
      });
  };

  handleResched = (_id, date) => {
    this.setState({ loading: true });
    API.BankTrnxResched({ _id, date })
      .then(() => {
        if (!this.mounted) return;
        this.setState({ loading: false, reschedId: null, newDate: null });
        const { handleSuccess } = this.props;
        if (handleSuccess) handleSuccess(`Transaction date set to ${date}`);
      })
      .catch(err => {
        console.log(err);
        if (!this.mounted) return;
        this.setState({ loading: false });
        const { handleError } = this.props;
        if (handleError) handleError(err.message);
      });
  };

  getMethodBadge = method => {
    return method === 'cash' ? (
      <Badge color="info">Cash</Badge>
    ) : (
      <Badge color="secondary">Cheque</Badge>
    );
  };

  getStatusBadge = status => {
    if (status === 'pending') {
      return <Badge color="danger">Pending</Badge>;
    }
    if (status === 'clearing') {
      return <Badge color="warning">Clearing</Badge>;
    }
    if (status === 'cleared') {
      return <Badge color="success">Cleared</Badge>;
    }
    if (status === 'bounced') {
      return <Badge color="danger">Bounced</Badge>;
    }
    return null;
  };

  renderLoading = () => {
    return (
      <tr key="spinner">
        <td colSpan={6} className="text-center">
          <span className="spinner-border" role="status" aria-hidden="true" />
        </td>
      </tr>
    );
  };

  getTrnxBtn = trnx => {
    const { reschedId } = this.state;
    const today = moment().format('YYYY-MM-DD');
    const trnxDate = moment(trnx.date).format('YYYY-MM-DD');

    if (trnx.status === 'cleared' || trnx.status === 'bounced') {
      return null;
    }

    if (trnxDate > today) {
      return (
        <React.Fragment>
          {reschedId !== trnx._id ? (
            <Button
              color="secondary"
              size="xs"
              block
              onClick={() =>
                this.setState({
                  reschedId: trnx._id,
                  newDate: moment(trnx.date).format('YYYY-MM-DD')
                })
              }
            >
              Resched
            </Button>
          ) : null}
        </React.Fragment>
      );
    }

    if (trnx.paymentMethod === 'cheque' && trnx.status === 'pending') {
      return (
        <React.Fragment>
          <Button
            color="warning"
            size="xs"
            block
            onClick={() => this.handleClearing(trnx._id)}
          >
            Clearing
          </Button>
          {reschedId !== trnx._id ? (
            <Button
              color="secondary"
              size="xs"
              block
              onClick={() =>
                this.setState({
                  reschedId: trnx._id,
                  newDate: moment(trnx.date).format('YYYY-MM-DD')
                })
              }
            >
              Resched
            </Button>
          ) : null}
        </React.Fragment>
      );
    }

    if (trnx.paymentMethod === 'cheque' && trnx.status === 'clearing') {
      return (
        <React.Fragment>
          <Button
            color="success"
            size="xs"
            block
            onClick={() => this.handleCleared(trnx._id)}
          >
            Cleared
          </Button>
          <Button
            color="danger"
            size="xs"
            block
            onClick={() => this.handleBounce(trnx._id)}
          >
            Bounced
          </Button>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Button
          color="success"
          size="xs"
          block
          onClick={() => this.handleCleared(trnx._id)}
        >
          Cleared
        </Button>
        {reschedId !== trnx._id ? (
          <Button
            color="secondary"
            size="xs"
            block
            onClick={() =>
              this.setState({
                reschedId: trnx._id,
                newDate: moment(trnx.date).format('YYYY-MM-DD')
              })
            }
          >
            Resched
          </Button>
        ) : null}
      </React.Fragment>
    );
  };

  getTrnxDate = trnx => {
    const { reschedId, newDate } = this.state;
    if (trnx.status === 'pending' && reschedId === trnx._id) {
      return (
        <Form inline>
          <FormGroup>
            <Input
              className="mt-2"
              type="date"
              name="date"
              id="newDate"
              placeholder="New date"
              bsSize="sm"
              value={newDate}
              onChange={e => {
                this.setState({ newDate: e.currentTarget.value });
              }}
            />
          </FormGroup>
          <FormGroup className="pt-2">
            <Button
              color="success"
              size="xs"
              onClick={() => this.handleResched(trnx._id, newDate)}
            >
              Set
            </Button>
            <Button
              className="ml-2"
              color="danger"
              size="xs"
              onClick={() => this.setState({ reschedId: null, newDate: null })}
            >
              Cancel
            </Button>
          </FormGroup>
        </Form>
      );
    }
    return (
      <React.Fragment>
        <span className="text-muted">{dateFormat(trnx.date)}</span>
      </React.Fragment>
    );
  };

  renderList = () => {
    const { list } = this.state;
    if (list.length <= 0) {
      return (
        <tr key={0}>
          <th scope="row" className="text-center" colSpan="5">
            <span className="text-muted">-- No transactions --</span>
          </th>
        </tr>
      );
    }

    return list.map(row => {
      const amt = currencyFormat(row.amount);
      const bal = currencyFormat(row.availableBalance);
      const amtfmt = (
        <span>
          <span
            className={row.type === 'deposit' ? 'text-success' : 'text-danger'}
          >
            {row.type === 'deposit' ? <span>{amt}</span> : <span>({amt})</span>}
          </span>
          {row.availableBalance ? (
            <span>
              <br />
              <span className="text-muted">{bal}</span>
            </span>
          ) : null}
        </span>
      );

      const today = moment().startOf('day');
      const trnxDate = moment(row.date).startOf('day');
      const dateDifference = trnxDate.diff(today, 'days');

      return (
        <tr
          key={row._id}
          style={dateDifference <= 10 ? { backgroundColor: 'pink' } : {}}
        >
          <th scope="row" className="text-center" style={{ width: '15%' }}>
            {this.getMethodBadge(row.paymentMethod)}
            <br />
            {this.getStatusBadge(row.status)}
          </th>
          <td className="text-center">{dateDifference}</td>
          <td>
            {row.remarks ? (
              <span>
                <strong>{row.remarks}</strong>
                <br />
                <span className="text-muted">{row.bankName}</span>
                <br />
                {this.getTrnxDate(row)}
              </span>
            ) : (
              <span>
                <strong>{row.bankName}</strong>
                <br />
                {this.getTrnxDate(row)}
              </span>
            )}
          </td>
          <td className="text-right">{amtfmt}</td>
          <td className="text-right" style={{ width: '15%' }}>
            {this.getTrnxBtn(row)}
          </td>
        </tr>
      );
    });
  };

  render() {
    const { loading } = this.state;
    return (
      <Table striped hover responsive size="sm">
        <thead>
          <tr>
            <th className="text-center">Status</th>
            <th className="text-center">Days Remaining</th>
            <th className="text-center">Remarks</th>
            <th className="text-center">Amount</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>{loading ? this.renderLoading() : this.renderList()}</tbody>
      </Table>
    );
  }
}

export default BankTrnxList;
