import React, { Component } from 'react';
import {
  Card,
  Table,
  Button,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  NavLink,
  UncontrolledTooltip
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faLock } from '@fortawesome/free-solid-svg-icons';
import Header from '../../containers/Header';
import Pagination from '../../components/Pagination';
import RowsPerPage from '../../components/RowsPerPage';
import API from '../../api';
import { DEFAULT_PER_PAGE } from '../../constants/others';
import UserView from './view';
import { UserContext } from '../../context/user';

import { ROLE_KDC, ROLE_EXECUTIVE, getRoleProperNames } from '../../util/roles';

class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      page: 1,
      pages: 1,
      perPage: DEFAULT_PER_PAGE,
      loading: true,
      showUserViewModal: false,
      selectedUser: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { page, perPage } = this.state;
    let savedPerPage = localStorage.getItem('employee-perPage');
    if (savedPerPage) {
      savedPerPage = parseInt(savedPerPage, 10);
      this.setState({ perPage: savedPerPage });
      this.getList(page, savedPerPage);
    } else {
      this.getList(page, perPage);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getList(page, perPage) {
    API.Users(page, perPage)
      .then(res => {
        if (!this.mounted) return;
        this.setState({ ...res, loading: false });
      })
      .catch(err => {
        console.log(err);
        if (!this.mounted) return;
        this.setState({ loading: false });
      });
  }

  toggleUserViewModal = () => {
    this.setState(prevState => {
      return {
        showUserViewModal: !prevState.showUserViewModal
      };
    });
  };

  setCurrentPage = curPage => {
    const { page, perPage } = this.state;
    if (curPage === page) {
      return;
    }
    this.setState({ page: curPage, loading: true }, () =>
      this.getList(curPage, perPage)
    );
  };

  setRowsPerPage = rowsPerPage => {
    const { page, perPage } = this.state;
    if (rowsPerPage === perPage) {
      return;
    }

    localStorage.setItem('employee-perPage', rowsPerPage);
    this.setState({ page: 1, perPage: rowsPerPage, loading: true }, () =>
      this.getList(page, rowsPerPage)
    );
  };

  rightNav = () => {
    return (
      <div>
        <Link to="/app/employee/add">
          <Button color="primary" size="sm">
            Add
          </Button>
        </Link>
      </div>
    );
  };

  renderLoading = () => {
    return (
      <tr key="spinner">
        <td colSpan={6} className="text-center">
          <span className="spinner-border" role="status" aria-hidden="true" />
        </td>
      </tr>
    );
  };

  setSelectedUser = user => {
    this.setState({ selectedUser: user });
    this.toggleUserViewModal();
  };

  renderList() {
    const { user } = this.context;
    const { list } = this.state;

    return list.map(row => {
      const name = `${row.lastName}, ${row.firstName}`;
      return (
        <tr key={row._id}>
          <th scope="row">
            <NavLink
              href="#"
              onClick={() => {
                this.setSelectedUser(row);
              }}
            >
              {name}
            </NavLink>
          </th>
          <td>{row.username}</td>
          <td className="text-center">{getRoleProperNames[row.role]}</td>
          <td className="text-center">
            {row.active === 'true' ? 'Yes' : 'No'}
          </td>
          <td className="text-center">
            <Button
              outline
              color="secondary"
              size="sm"
              onClick={this.toggleSidebar}
              id={`update-${row._id}`}
            >
              <Link to={`/app/employee/admin-update/${row._id}`}>
                <FontAwesomeIcon icon={faEdit} />
              </Link>
            </Button>
            <UncontrolledTooltip target={`update-${row._id}`}>
              Edit employee
            </UncontrolledTooltip>

            {[ROLE_KDC, ROLE_EXECUTIVE].indexOf(user.role) > -1 ? (
              <>
                <Button
                  outline
                  color="secondary"
                  size="sm"
                  style={{ marginLeft: '10px' }}
                  id={`setPass-${row._id}`}
                >
                  <Link to={`/app/employee/set-password/${row._id}`}>
                    <FontAwesomeIcon icon={faLock} />
                  </Link>
                </Button>
                <UncontrolledTooltip target={`setPass-${row._id}`}>
                  Set password
                </UncontrolledTooltip>
              </>
            ) : (
              ''
            )}
          </td>
        </tr>
      );
    });
  }

  render() {
    const {
      loading,
      page,
      pages,
      perPage,
      showUserViewModal,
      selectedUser
    } = this.state;
    const { sidebar } = this.props;

    return (
      <div>
        <Header
          sidebar={sidebar}
          title="Employee"
          mode="buttons"
          rightNav={this.rightNav()}
        />
        <div className="row">
          <div className="col-sm-12">
            <Card body>
              <Row>
                <Col sm={12}>
                  <Pagination
                    page={page}
                    pages={pages}
                    setCurrentPage={this.setCurrentPage}
                  />
                </Col>
              </Row>
              <Table striped hover responsive size="sm">
                <thead>
                  <tr className="text-center">
                    <th>Name</th>
                    <th>Username</th>
                    <th>Role</th>
                    <th>Active</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? this.renderLoading() : this.renderList()}
                </tbody>
              </Table>
              <Row>
                <Col sm={6}>
                  <RowsPerPage
                    perPage={perPage}
                    setRowsPerPage={this.setRowsPerPage}
                  />
                </Col>
                <Col sm={6}>
                  <Pagination
                    page={page}
                    pages={pages}
                    setCurrentPage={this.setCurrentPage}
                  />
                </Col>
              </Row>
            </Card>
          </div>
        </div>

        <Modal
          isOpen={showUserViewModal}
          toggle={this.toggleUserViewModal}
          backdrop="static"
        >
          <ModalHeader>View User</ModalHeader>
          <ModalBody>
            <UserView
              selectedUser={selectedUser}
              toggleUserViewModal={this.toggleUserViewModal}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

Employee.contextType = UserContext;

export default Employee;
