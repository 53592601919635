import React, { Component } from 'react';
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

import moment from 'moment';
import { UserContext } from '../../context/user';

import Project from '../project/projects';

import TIME_OPTIONS, { autoSelectTimeEnd } from '../../util/time';
import ALL_ROLES, {
  ACTUAL_ROLES,
  VIRTUAL_ROLES,
  handleRolesSelection
} from '../../util/roles';

import API from '../../api';

class ActivityForm extends Component {
  constructor(props) {
    super(props);

    const { selectedActivity } = this.props;

    let selectedActivityTimeStart = '';
    let selectedActivityTimeEnd = '';
    let startTime = [];
    let endTime = [];
    let roles = [];
    if (selectedActivity) {
      if (selectedActivity.timeStart) {
        selectedActivityTimeStart = selectedActivity.timeStart;
        startTime = parseInt(selectedActivityTimeStart, 10) * 2;
        if (selectedActivityTimeStart.indexOf('30') > -1) startTime += 1;
        startTime = [TIME_OPTIONS[startTime]];
      }

      if (selectedActivity.timeEnd) {
        selectedActivityTimeEnd = selectedActivity.timeEnd;
        endTime = parseInt(selectedActivityTimeEnd, 10) * 2;
        if (selectedActivityTimeEnd.indexOf('30') > -1) endTime += 1;
        endTime = [TIME_OPTIONS[endTime]];
      }

      const audience = selectedActivity.whoShouldSeeThis;
      if (audience && audience.length) {
        if (audience.indexOf('all') > -1) {
          roles = [VIRTUAL_ROLES[0]];
        } else if (audience.indexOf('onlyme') > -1) {
          roles = [VIRTUAL_ROLES[1]];
        } else {
          roles = ACTUAL_ROLES.filter(
            item => audience.indexOf(item.value) > -1
          );
        }
      } else {
        roles = [VIRTUAL_ROLES[0]];
      }
    }

    if (!roles.length) roles = [VIRTUAL_ROLES[0]];

    this.state = {
      timeStart: startTime,
      timeEnd: endTime,
      roles
    };

    this.props = props;
    this.activityForm = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setValidationSchema = Yup.object().shape({
    log: Yup.string().required('Required'),
    date: Yup.date().required('Required')
  });

  onChangeSelectTimeStart = time => {
    this.setState({ timeStart: time });
    this.setState({ timeEnd: autoSelectTimeEnd(time) });
  };

  onChangeSelectTimeEnd = time => {
    this.setState({ timeEnd: time });
  };

  selectValueTimeStart = timeSelectOptions => {
    const { timeStart } = this.state;
    if (timeStart.length === 0) {
      let index = new Date().getHours() * 2;
      if (timeStart.indexOf('30') > -1) index += 1;
      const value = timeSelectOptions[index];
      setTimeout(() => {
        this.setState({ timeStart: value });
      });
      return value;
    }
    return timeStart;
  };

  selectValueTimeEnd = timeSelectOptions => {
    const { timeEnd } = this.state;
    if (timeEnd.length === 0) {
      let index = new Date().getHours() * 2 + 2;
      if (timeEnd.indexOf('30') > -1) index += 1;
      if (index === 48) index = 0;
      const value = timeSelectOptions[index];
      setTimeout(() => {
        this.setState({ timeEnd: value });
      }, 100);
      return value;
    }
    return timeEnd;
  };

  composeActivityFormFields = (errors, touched, isSubmitting) => {
    const { roles } = this.state;
    const timeSelectOptions = TIME_OPTIONS;
    const rolesSelectOptions = ALL_ROLES;
    const roleSelectValue = roles.length === 0 ? [] : roles;

    // Use label instead of value in searching for match for react-select
    const customFilter = (option, searchText) => {
      return option.data.label.toLowerCase().includes(searchText.toLowerCase());
    };

    return (
      <div className="col-xs-12 m-a">
        <Row form>
          <Col xs={12}>
            <FormGroup>
              <Label for="log">Activity</Label>
              <Field
                component="textarea"
                className={`form-control ${
                  errors.log && touched.log ? 'is-invalid' : ''
                }`}
                name="log"
                disabled={isSubmitting}
              />
              <FormFeedback>{errors.log}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={4}>
            <FormGroup>
              <Label for="date">Date</Label>
              <Input
                tag={Field}
                type="date"
                name="date"
                disabled={isSubmitting}
                invalid={errors.date && touched.date}
                style={{ paddingRight: '10px' }}
              />
              <FormFeedback>{errors.date}</FormFeedback>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <Label for="timeStart">Time Start</Label>
              <Select
                tag={Field}
                name="timeStart"
                options={timeSelectOptions}
                onChange={this.onChangeSelectTimeStart}
                value={this.selectValueTimeStart(timeSelectOptions)}
                filterOption={customFilter}
                disabled={isSubmitting}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <Label for="timeEnd">Time End</Label>
              <Select
                tag={Field}
                name="timeEnd"
                options={timeSelectOptions}
                onChange={this.onChangeSelectTimeEnd}
                value={this.selectValueTimeEnd(timeSelectOptions)}
                filterOption={customFilter}
                disabled={isSubmitting}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col xs={12}>
            <FormGroup>
              <Label for="projectName">Project</Label>
              <Field
                name="projectName"
                component={Project}
                disabled={isSubmitting}
                invalid={errors.projectName && touched.projectName}
              />
              {errors.projectName && touched.projectName ? (
                <div className="text-danger">{errors.projectName}</div>
              ) : null}
              <Input tag={Field} type="hidden" name="projectId" />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col xs={12}>
            <FormGroup>
              <Label for="roleFilter">Who should see this?</Label>
              <Select
                options={rolesSelectOptions}
                isMulti
                onChange={this.onRoleSelect}
                value={roleSelectValue}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  };

  composeActivityFormButtons = (
    errors,
    touched,
    isSubmitting,
    toggleActivityModal
  ) => {
    const { selectedActivity } = this.props;

    return (
      <div className="col-sm-12">
        <FormGroup row>
          <Col className="text-right">
            <Button
              type="submit"
              color="primary"
              className="mr-1"
              disabled={isSubmitting}
            >
              {selectedActivity ? 'Update' : 'Create'}
            </Button>
            <Button type="button" color="danger" onClick={toggleActivityModal}>
              Cancel
            </Button>
          </Col>
        </FormGroup>
      </div>
    );
  };

  setInitialValues = () => {
    const { selectedActivity } = this.props;

    let { log, date, projectId, projectName } = selectedActivity || {};

    log = log || '';
    date = date || moment().format('YYYY-MM-DD');
    projectId = projectId || '';
    projectName = projectName || '';

    return { log, date, projectId, projectName };
  };

  onSubmit = (values, { setSubmitting, setStatus, setErrors }) => {
    const { parent, selectedActivity, toggleActivityModal } = this.props;
    const { timeStart, timeEnd, roles } = this.state;
    const [, onlyme] = VIRTUAL_ROLES;
    let selectedRoles = [];

    if (selectedActivity) values._id = selectedActivity._id;

    // Fix date
    values.date = moment(values.date).isValid() ? values.date : '';

    values.timeStart = timeStart.value;
    values.timeEnd = timeEnd.value;

    roles.forEach(r => {
      selectedRoles.push(r.value);
    });

    if (!selectedRoles.length) selectedRoles = onlyme.value;

    values.whoShouldSeeThis = selectedRoles;

    // Validate time.
    if (values.timeStart >= values.timeEnd && values.timeEnd !== '00:00') {
      const message = 'Time start should be greater tham time end.';
      alert(message);
      setErrors({ submit: message });
      setStatus({ success: false });
      setSubmitting(false);
      return {};
    }

    if (!values.projectId) {
      delete values.projectId;
      delete values.projectName;
    }

    const promise = selectedActivity
      ? API.ActivityUpdate(values)
      : API.ActivityAdd(values);

    return promise
      .then(() => {
        values.projectId = '';
        values.projectName = '';

        if (!this.mounted) return;
        setSubmitting(false);
        parent.setLastUpdate('listUpdateTs', new Date().getTime());
        toggleActivityModal();
      })
      .catch(e => {
        console.log('ActivityForm', e);
        setErrors({ submit: e.message });
        setStatus({ success: false });
        setSubmitting(false);
      });
  };

  onRoleSelect = role => {
    const selectedRoles = handleRolesSelection(role);
    this.setState({ roles: selectedRoles });
  };

  render() {
    const { toggleActivityModal } = this.props;

    return (
      <Formik
        initialValues={this.setInitialValues()}
        validationSchema={this.setValidationSchema}
        onSubmit={this.onSubmit}
        ref={this.activityForm}
      >
        {props => {
          const { errors, touched, isSubmitting } = props;
          return (
            <Form>
              {this.composeActivityFormFields(errors, touched, isSubmitting)}

              {this.composeActivityFormButtons(
                errors,
                touched,
                isSubmitting,
                toggleActivityModal
              )}
            </Form>
          );
        }}
      </Formik>
    );
  }
}

ActivityForm.contextType = UserContext;

export default ActivityForm;
