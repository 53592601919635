import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import API from '../../api';

class PaymentAdd extends Component {
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setInitialValues = () => {
    const { companyName, companyId } = this.props;

    return {
      birOrNum: '',
      tax: 0,
      subamount: 0,
      date: moment().format('YYYY-MM-DD'),
      remarks: '',
      companyName,
      companyId
    };
  };

  setValidationSchema = Yup.object().shape({
    tax: Yup.number()
      .moreThan(-1, 'Should be greater than or equal to 0.')
      .required('Required'),
    subamount: Yup.number()
      .moreThan(0, 'Should be greater than 0.')
      .required('Required'),
    date: Yup.date().required('Required'),
    companyName: Yup.string().required('Required'),
    companyId: Yup.string().required('Required')
  });

  onSubmit = (values, { setSubmitting, setStatus, setErrors }) => {
    const { done, type } = this.props;

    const paymentType = type || 'payables';

    values.type = paymentType;

    API.PaymentAdd(values)
      .then(pay => {
        if (!this.mounted) return;
        setSubmitting(false);
        done(pay);
      })
      .catch(e => {
        console.log('PaymentAdd', e);
        setStatus({ success: false });
        setSubmitting(false);
        setErrors({ submit: e.message });
      });
  };

  render() {
    const { close } = this.props;

    return (
      <Formik
        initialValues={this.setInitialValues()}
        validationSchema={this.setValidationSchema}
        onSubmit={this.onSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form noValidate>
            <Row form>
              <Col sm={6}>
                <FormGroup>
                  <Label for="birOrNum">OR Number</Label>
                  <Input
                    tag={Field}
                    type="text"
                    name="birOrNum"
                    placeholder="BIR OR #"
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="poDate">Date *</Label>
                  <Input
                    tag={Field}
                    type="date"
                    name="date"
                    disabled={isSubmitting}
                    invalid={errors.date && touched.date}
                  />
                  <FormFeedback>{errors.date}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={6}>
                <FormGroup>
                  <Label for="tax">Tax in Peso *</Label>
                  <Input
                    tag={Field}
                    type="number"
                    name="tax"
                    disabled={isSubmitting}
                    min="0"
                    step="0.01"
                    invalid={errors.tax && touched.tax}
                  />
                  <FormFeedback>{errors.tax}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="subamount">Amount in Peso *</Label>
                  <Input
                    tag={Field}
                    type="number"
                    name="subamount"
                    disabled={isSubmitting}
                    min="0"
                    step="0.01"
                    invalid={errors.subamount && touched.subamount}
                  />
                  <FormFeedback>{errors.subamount}</FormFeedback>
                  <FormText color="muted">Note: Excluding Tax</FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={12}>
                <FormGroup>
                  <Label for="companyName">Company Name</Label>
                  <Input
                    tag={Field}
                    readOnly
                    type="text"
                    name="companyName"
                    className="form-control-plaintext p-2"
                  />
                  <Input tag={Field} type="hidden" name="companyId" />
                  {errors.companyName && touched.companyName ? (
                    <div className="text-danger">{errors.companyName}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col sm={4} />
            </Row>
            <Row form>
              <Col sm={12}>
                <FormGroup>
                  <Label for="terms">Remarks</Label>
                  <Input
                    tag={Field}
                    type="text"
                    name="remarks"
                    placeholder=""
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup row>
              <Col className="text-right">
                <Button
                  type="submit"
                  color="primary"
                  className="mr-1"
                  disabled={isSubmitting}
                  onClick={e => {
                    const eventTarget = e.target;
                    setTimeout(() => {
                      eventTarget.disabled = true;

                      setTimeout(() => {
                        eventTarget.disabled = isSubmitting;
                      }, 2000);
                    });

                    return true;
                  }}
                >
                  Add
                </Button>
                <Button type="button" color="danger" onClick={close}>
                  Cancel
                </Button>
              </Col>
            </FormGroup>
          </Form>
        )}
      </Formik>
    );
  }
}

export default PaymentAdd;
