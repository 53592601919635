import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Collapse,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import currencyFormat from '../../util/currency';
import { UserContext } from '../../context/user';
import API from '../../api';

import LinkSoForm from './formLinkSo';
import SalesOrderForm from '../salesOrder/form';

import {
  ROLE_KDC,
  ROLE_EXECUTIVE,
  ROLE_SALES,
  ROLE_DOCUMENT_CONTROLLER,
  ROLE_MARKETING,
  ROLE_MARKETING_SECRETARY
} from '../../util/roles';

class Info extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    const { collapse = true } = props;
    this.state = {
      collapse,
      showLinkSoModal: false,
      showCreateSoModal: false
    };
  }

  allowToCreateSo = () => {
    const { user } = this.context;
    const { project } = this.props;

    if (!(project.salesAgentUserId && project.salesAgentUserName)) return false;

    if (project.status !== 'Quoted') return false;

    if (project.quotations.length <= 0) return false;

    const allowedRole = [
      ROLE_KDC,
      ROLE_EXECUTIVE,
      ROLE_SALES,
      ROLE_DOCUMENT_CONTROLLER
    ];
    if (allowedRole.indexOf(user.role) === -1) return false;

    return true;
  };

  createSo = async () => {
    const { project, setLastUpdate } = this.props;
    const {
      _id,
      reference,
      projectName,
      companyId,
      companyName,
      lastQuotation,
      salesAgentUserId,
      salesAgentUserName,
      scopeOfWork
    } = project;

    const msg = `Are you sure you want create sales order for project '${projectName ||
      reference}'?`;

    if (!window.confirm(msg)) return;

    const soInfo = {
      companyId: companyId || '',
      companyName: companyName || '',
      deliveryDate: '',
      description: '',
      poAmount: lastQuotation,
      poDate: moment().format('YYYY-MM-DD'),
      poNum: '',
      projectId: _id,
      projectName,
      remarks: '',
      salesAgent: salesAgentUserName || '',
      salesAgentId: salesAgentUserId || '',
      soDate: moment().format('YYYY-MM-DD'),
      terms: scopeOfWork
    };

    await API.SalesOrderAdd(soInfo)
      .then(so => {
        API.ProjectUpdate({
          _id,
          status: 'Approved',
          soId: so._id,
          soNum: so.soNum
        })
          .then(() => {
            setLastUpdate('listUpdateTs', new Date().getTime());
            alert('SO successfully created.');
          })
          .catch(e => {
            console.log('ProjectForm', e);
          });
      })
      .catch(e => {
        console.log('ProjectForm', e);
      });
  };

  allowToLinkSo = () => {
    const { user } = this.context;
    const { project } = this.props;

    if (!(project.salesAgentUserId && project.salesAgentUserName)) return false;

    if (project.status !== 'Quoted') return false;

    if (project.soId) return false;

    if (project.quotations.length <= 0) return false;

    const allowedRole = [
      ROLE_KDC,
      ROLE_EXECUTIVE,
      ROLE_SALES,
      ROLE_DOCUMENT_CONTROLLER
    ];
    if (allowedRole.indexOf(user.role) === -1) return false;

    return true;
  };

  showQuotedButton = () => {
    const { user } = this.context;
    const { project } = this.props;

    if (['For Quotation', 'For Revision'].indexOf(project.status) === -1)
      return false;

    if (!project.lastQuotation) return false;

    const allowedRole = [
      ROLE_KDC,
      ROLE_EXECUTIVE,
      ROLE_SALES,
      ROLE_MARKETING,
      ROLE_MARKETING_SECRETARY,
      ROLE_DOCUMENT_CONTROLLER
    ];
    if (allowedRole.indexOf(user.role) === -1) return false;

    return true;
  };

  toggleLinkSoModal = () => {
    this.setState(prevState => {
      return {
        showLinkSoModal: !prevState.showLinkSoModal
      };
    });
  };

  toggleCreateSoModal = () => {
    this.setState(prevState => {
      return {
        showCreateSoModal: !prevState.showCreateSoModal
      };
    });
  };

  updateProjectStatus = status => {
    const { project, setLastUpdate } = this.props;

    if (
      !window.confirm(`Are you sure you want to change status to ${status}?`)
    ) {
      return;
    }

    if (status === project.status) {
      console.log(`Status is already ${status}`);
      return;
    }

    const data = {
      _id: project._id,
      status
    };

    API.ProjectUpdateStatus(data)
      .then(() => {
        setLastUpdate('listUpdateTs', new Date().getTime());
      })
      .catch(e => {
        console.log('UpdateProjectStatus', e);
        alert(e.message);
      });
  };

  toggle() {
    const { collapse } = this.state;
    this.setState({ collapse: !collapse });
  }

  render() {
    const { collapse, showLinkSoModal, showCreateSoModal } = this.state;
    const { toggleProjectModal, project, setLastUpdate } = this.props;

    return (
      <Row className="mb-3">
        <Col sm={12}>
          <Card>
            <CardHeader
              className="text-center align-items-center d-flex justify-content-center cursor-pointer"
              onClick={this.toggle}
            >
              <strong className="mr-1">Project Info</strong>
              <FontAwesomeIcon
                className="float-right"
                icon={collapse ? 'caret-up' : 'caret-down'}
              />
            </CardHeader>
            <Collapse isOpen={collapse}>
              <CardBody>
                <Row>
                  <Col sm={12}>
                    <dl className="row mb-0 px-3 pb-2">
                      <dt className="col-6 col-sm-2 text-nowrap">Reference</dt>
                      <dd className="col-6 col-sm-4">
                        {project.reference ? project.reference : '-'}
                      </dd>
                      <dt className="col-6 col-sm-2 text-nowrap">Name</dt>
                      <dd className="col-6 col-sm-4">
                        {project.projectName ? project.projectName : '-'}
                      </dd>
                      <dt className="col-6 col-sm-2 text-nowrap">Company</dt>
                      <dd className="col-6 col-sm-4">
                        {project.companyName ? project.companyName : '-'}
                      </dd>
                      <dt className="col-6 col-sm-2 text-nowrap">Product</dt>
                      <dd className="col-6 col-sm-4">
                        {project.product ? project.product : '-'}
                      </dd>
                      <dt className="col-6 col-sm-2">Sales Agent</dt>
                      <dd className="col-6 col-sm-4">
                        {project.salesAgentUserName
                          ? project.salesAgentUserName
                          : '-'}
                      </dd>
                      <dt className="col-6 col-sm-2">Status</dt>
                      <dd className="col-6 col-sm-4">
                        {project.status ? project.status : '-'}
                      </dd>
                      <dt className="col-6 col-sm-2">First Quotation</dt>
                      <dd className="col-6 col-sm-4">
                        {project.firstQuotation
                          ? currencyFormat(project.firstQuotation)
                          : '-'}
                      </dd>
                      <dt className="col-6 col-sm-2">Last Quotation</dt>
                      <dd className="col-6 col-sm-4">
                        {project.lastQuotation
                          ? currencyFormat(project.lastQuotation)
                          : '-'}
                      </dd>
                      <dt className="col-6 col-sm-2">SO #</dt>
                      <dd className="col-6 col-sm-4">
                        {project.soNum ? project.soNum : '-'}
                      </dd>
                      <dt className="col-6 col-sm-2">Revisions</dt>
                      <dd className="col-6 col-sm-4">
                        {project.lastQuotationRevisionNumber
                          ? project.lastQuotationRevisionNumber
                          : 0}
                      </dd>
                    </dl>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-right">
                    <Button
                      color="info"
                      size="sm"
                      className="mr-1"
                      onClick={() => toggleProjectModal(null)}
                    >
                      Edit
                    </Button>

                    {this.showQuotedButton() ? (
                      <Button
                        color="secondary"
                        size="sm"
                        className="mr-1"
                        onClick={() => this.updateProjectStatus('Quoted')}
                      >
                        Quoted
                      </Button>
                    ) : (
                      ''
                    )}

                    {this.allowToCreateSo() ? (
                      <Button
                        color="success"
                        size="sm"
                        className="mr-1"
                        onClick={() => this.toggleCreateSoModal()}
                      >
                        Create SO
                      </Button>
                    ) : (
                      ''
                    )}

                    {this.allowToLinkSo() ? (
                      <Button
                        color="warning"
                        size="sm"
                        className="mr-1"
                        onClick={() => {
                          this.toggleLinkSoModal();
                        }}
                      >
                        Link SO
                      </Button>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Collapse>
          </Card>
        </Col>

        <Modal
          isOpen={showLinkSoModal}
          toggle={this.toggleLinkSoModal}
          backdrop="static"
        >
          <ModalHeader>Link SO</ModalHeader>

          <ModalBody>
            <LinkSoForm
              setLastUpdate={setLastUpdate}
              selectedProject={project}
              toggleLinkSoModal={this.toggleLinkSoModal}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={showCreateSoModal}
          toggle={this.toggleCreateSoModal}
          backdrop="static"
        >
          <ModalHeader>Create SO</ModalHeader>

          <ModalBody>
            <SalesOrderForm
              setLastUpdate={setLastUpdate}
              selectedProject={project}
              close={this.toggleCreateSoModal}
              addButtonLabel="Create SO"
              done={setLastUpdate}
            />
          </ModalBody>
        </Modal>
      </Row>
    );
  }
}

Info.contextType = UserContext;

export default Info;
