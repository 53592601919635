import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import API from '../../api';
import { currencyRound } from '../../util/currency';

class InvoiceAdd extends Component {
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onSubmit = (values, { setSubmitting, setStatus, setErrors }) => {
    const { done } = this.props;

    values.type = 'receivables';

    API.InvoiceAdd(values)
      .then(inv => {
        if (!this.mounted) return;
        setSubmitting(false);
        done(inv);
      })
      .catch(e => {
        console.log('InvoiceAdd', e);
        setStatus({ success: false });
        setSubmitting(false);
        setErrors({ submit: e.message });
      });
  };

  render() {
    const { close, so } = this.props;
    const { _id, soNum, companyName, companyId } = so;

    return (
      <Formik
        initialValues={{
          birInvoiceNum: '',
          billingInvoiceNum: '',
          amount: currencyRound(so.unbilledAmount),
          date: moment().format('YYYY-MM-DD'),
          remarks: '',
          soNum,
          soId: _id,
          companyName,
          companyId
        }}
        validationSchema={Yup.object().shape({
          amount: Yup.number()
            .moreThan(0, 'Needs to be more than zero')
            .max(
              currencyRound(so.unbilledAmount),
              `Needs to be less than unbilled amount ${currencyRound(
                so.unbilledAmount
              )}`
            )
            .required('Required'),
          date: Yup.date().required('Required'),
          companyName: Yup.string().required('Required'),
          companyId: Yup.string().required('Required')
        })}
        onSubmit={this.onSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form noValidate>
            <Row form>
              <Col sm={6}>
                <FormGroup>
                  <Label for="amount">Amount in Peso *</Label>
                  <Input
                    tag={Field}
                    type="number"
                    name="amount"
                    disabled={isSubmitting}
                    invalid={errors.amount && touched.amount}
                    min="0"
                    step="0.01"
                  />
                  <FormFeedback>{errors.amount}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="poDate">Date *</Label>
                  <Input
                    tag={Field}
                    type="date"
                    name="date"
                    disabled={isSubmitting}
                    invalid={errors.date && touched.date}
                  />
                  <FormFeedback>{errors.date}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={6}>
                <FormGroup>
                  <Label for="soNum">Invoice Number</Label>
                  <Input
                    tag={Field}
                    type="text"
                    name="birInvoiceNum"
                    placeholder="BIR Invoice #"
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="soDate">Billing Number</Label>
                  <Input
                    tag={Field}
                    type="text"
                    name="billingInvoiceNum"
                    placeholder="Billing Invoice #"
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={8}>
                <FormGroup>
                  <Label for="companyName">Company Name</Label>
                  <Input
                    tag={Field}
                    readOnly
                    type="text"
                    name="companyName"
                    placeholder="Static Power Philippines"
                    className="form-control-plaintext pl-2"
                  />
                  <Input tag={Field} type="hidden" name="companyId" />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="soNum">SO Number</Label>
                  <Input
                    tag={Field}
                    readOnly
                    type="text"
                    name="soNum"
                    disabled={isSubmitting}
                  />
                  <Input tag={Field} type="hidden" name="soId" />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={12}>
                <FormGroup>
                  <Label for="terms">Remarks</Label>
                  <Input
                    tag={Field}
                    type="text"
                    name="remarks"
                    placeholder=""
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup row>
              <Col className="text-right">
                <Button
                  type="submit"
                  color="primary"
                  className="mr-1"
                  disabled={isSubmitting}
                  onClick={e => {
                    const eventTarget = e.target;
                    setTimeout(() => {
                      eventTarget.disabled = true;

                      setTimeout(() => {
                        eventTarget.disabled = isSubmitting;
                      }, 2000);
                    });

                    return true;
                  }}
                >
                  Add
                </Button>
                <Button type="button" color="danger" onClick={close}>
                  Cancel
                </Button>
              </Col>
            </FormGroup>
          </Form>
        )}
      </Formik>
    );
  }
}

export default InvoiceAdd;
