import React, { Component } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Table,
  Row,
  Col,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Header from '../../containers/Header';
import currencyFormat from '../../util/currency';
import dateFormat from '../../util/date';
import API from '../../api';
import SoView from '../salesOrder/view';

const DEFAULT_WEEKS = 1;

class WeeklySales extends Component {
  constructor(props) {
    super(props);

    const weeks = [];
    const curWeek = moment();
    const start = curWeek.format('YY-ww');
    const end = moment().format('YY-ww');
    while (end >= curWeek.format('YY-ww')) {
      weeks.push(curWeek.format('YY-ww'));
      curWeek.add(1, 'week');
    }

    this.state = {
      list: [],
      weeks,
      start,
      end,
      step: 0,
      loading: true,
      selectedSo: null,
      showSoViewModal: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { start, end } = this.state;
    this.getList(start, end);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getList(start, end) {
    API.WeeklySales(start, end)
      .then(res => {
        if (!this.mounted) return;
        // console.log('res', res);
        this.processList(res);
      })
      .catch(err => {
        console.log(err);
        if (!this.mounted) return;
        this.setState({ loading: false });
      });
  }

  renderLoading = () => {
    return (
      <tr key="spinner">
        <td colSpan={9} className="text-center">
          <span className="spinner-border" role="status" aria-hidden="true" />
        </td>
      </tr>
    );
  };

  toggleSoViewModal = () => {
    this.setState(prevState => {
      return {
        showSoViewModal: !prevState.showSoViewModal
      };
    });
  };

  setSelectedSoView = So => {
    this.setState({ selectedSo: So });
    this.toggleSoViewModal();
  };

  getSO = async selectedSoId => {
    const so = await API.SalesOrdersDetails(selectedSoId);
    this.setSelectedSoView(so);
  };

  renderList = () => {
    const { list, weeks } = this.state;
    // console.log('list', list);

    return list.map(row => {
      const soNums = [];
      if (row.soNums) {
        let i = 0;
        row.soNums.forEach((v, k) => {
          i += 1;
          soNums.push(
            <div key={row.soIds[k] + i}>
              <NavLink
                style={{ padding: '0px' }}
                href="#"
                onClick={() => {
                  this.getSO(row.soIds[k]);
                }}
              >
                {v}
              </NavLink>
            </div>
          );
        });
      }

      const soDates = [];
      if (row.soDates) {
        let i = 0;
        row.soDates.forEach((v, k) => {
          i += 1;
          soDates.push(<div key={row.soIds[k] + i}>{dateFormat(v)}</div>);
        });
      }

      const companyNames = [];
      if (row.companyNames) {
        let i = 0;
        row.companyNames.forEach((v, k) => {
          i += 1;
          companyNames.push(
            <div key={row.soIds[k] + i}>
              <Link to={`/app/company/${row.companyIds[k]}`}>{v}</Link>
            </div>
          );
        });
      }

      return (
        <tr key={row.name}>
          <th scope="row">{row.name}</th>
          <th scope="row">{soNums}</th>
          <th scope="row">{soDates}</th>
          <th scope="row">{companyNames}</th>
          {weeks.map(w => {
            return (
              <td key={w} className="text-right">
                {currencyFormat(row[w])}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  updateWeekNumber = step => {
    let currentWeek = moment();
    if (step) {
      const { weeks } = this.state;

      const week = weeks[0];
      if (!week) return {};

      const weekInfo = week.split('-');
      if (weekInfo.length !== 2) return {};

      const weekNumber = parseInt(weekInfo[1], 10);
      if (Number.isNaN(weekNumber)) return {};

      const todayWeekNumber = moment().week();
      currentWeek = moment().week(todayWeekNumber + step, 'weeks');
    }

    const updatedWeeks = [];
    const start = currentWeek.format('YY-ww');
    const end = DEFAULT_WEEKS > 1 ? moment().format('YY-ww') : start;
    while (end >= currentWeek.format('YY-ww')) {
      updatedWeeks.push(currentWeek.format('YY-ww'));
      currentWeek.add(1, 'week');
    }

    this.setState({ weeks: updatedWeeks, start, end });
    return { start, end };
  };

  goToPreviousWeek = () => {
    const { step } = this.state;
    const newStep = step - 1;
    const { start, end } = this.updateWeekNumber(newStep);
    this.getList(start, end);
    this.setState({ step: newStep });
  };

  goToCurrentWeek = () => {
    const { start, end } = this.updateWeekNumber(0);
    this.getList(start, end);
    this.setState({ step: 0 });
  };

  goToNextWeek = () => {
    const { step } = this.state;
    const newStep = step + 1;
    const { start, end } = this.updateWeekNumber(newStep);
    this.getList(start, end);
    this.setState({ step: newStep });
  };

  processList(list) {
    let newlist = {};
    const total = { name: ' ' };

    list.forEach(l => {
      if (!newlist[l.userId])
        newlist[l.userId] = {
          name: l.label,
          soNums: l.soNums,
          soIds: l.soIds,
          soDates: l.soDates,
          companyNames: l.companyNames,
          companyIds: l.companyIds
        };
      newlist[l.userId][l.period] = l.value;

      if (!total[l.period]) total[l.period] = 0;
      total[l.period] += l.value;
    });

    newlist.total = total;

    newlist = _.sortBy(newlist, l => l.total * -1);

    this.setState({ list: [...newlist], loading: false });
  }

  render() {
    const { loading, weeks, showSoViewModal, selectedSo } = this.state;
    const { sidebar } = this.props;

    const buttonStyle = {
      backgroundColor: '#007bff',
      borderColor: '#007bff'
    };

    return (
      <div>
        <Header sidebar={sidebar} title="Weekly Sales" />
        <div className="row">
          <div className="col-sm-12">
            <Card body>
              <div style={{ paddingBottom: '21px' }}>
                <ButtonGroup>
                  <Button
                    className="mr-1"
                    style={buttonStyle}
                    onClick={this.goToPreviousWeek}
                  >
                    Prev
                  </Button>
                  <Button
                    className="mr-1"
                    style={buttonStyle}
                    onClick={this.goToCurrentWeek}
                  >
                    Current
                  </Button>
                  <Button style={buttonStyle} onClick={this.goToNextWeek}>
                    Next
                  </Button>
                </ButtonGroup>
              </div>

              <Row>
                <Col sm={8} />
                <Col sm={4} />
              </Row>
              <Table striped hover responsive size="sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>SO #</th>
                    <th>Date</th>
                    <th>Company</th>
                    {weeks.map(w => {
                      const ws = w.split('-');
                      return (
                        <th key={w} className="text-center">
                          Wk {ws[1]}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {loading ? this.renderLoading() : this.renderList()}
                </tbody>
              </Table>
            </Card>
          </div>
        </div>

        <Modal
          isOpen={showSoViewModal}
          toggle={this.toggleSoViewModal}
          backdrop="static"
        >
          <ModalHeader>View SO</ModalHeader>
          <ModalBody>
            <SoView
              selectedSo={selectedSo}
              toggleSoViewModal={this.toggleSoViewModal}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default WeeklySales;
