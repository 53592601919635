import React, { Component } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Table
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Header from '../../containers/Header';
import Loading from '../../components/Loading';
import API from '../../api';
import currencyFormat from '../../util/currency';
import BankTrxnForm from './form';
import BankTrnxList from './list';

class Bank extends Component {
  constructor(props) {
    super(props);

    this.state = {
      banks: [],
      loading: false,
      errorMessage: null,
      showModal: false,
      formType: 'deposit',
      refreshTrnxs: true
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.getBanks();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getBanks() {
    API.Banks()
      .then(banks => {
        if (!this.mounted) return;
        this.setState({ banks, loading: false });
      })
      .catch(e => {
        console.log(e);
        if (!this.mounted) return;
        this.setState({ errorMessage: e.message, loading: false });
      });
  }

  handleSuccess = msg => {
    this.setState({ showModal: false });
    this.getBanks();

    toast.success(msg);

    this.setState(prevState => {
      return {
        refreshTrnxs: !prevState.refreshTrnxs
      };
    });
  };

  handleError = msg => {
    toast.error(msg);
  };

  toggleModal = () => {
    this.setState(prevState => {
      return {
        showModal: !prevState.showModal
      };
    });
  };

  handleDeposit = () => {
    this.setState({
      showModal: true,
      formType: 'deposit'
    });
  };

  handleWithdrawal = () => {
    this.setState({
      showModal: true,
      formType: 'withdrawal'
    });
  };

  rightNav = () => {
    return (
      <div>
        <Button
          color="primary"
          size="sm"
          onClick={this.handleDeposit}
          className="mr-1"
        >
          Deposit
        </Button>
        <Button
          color="danger"
          size="sm"
          onClick={this.handleWithdrawal}
          className="mr-1"
        >
          Withdrawal
        </Button>
        <Link to="/app/bank/transactions/">
          <Button color="primary" size="sm">
            View All Transactions
          </Button>
        </Link>
      </div>
    );
  };

  render() {
    const {
      banks,
      loading,
      errorMessage,
      showModal,
      formType,
      refreshTrnxs
    } = this.state;
    const { sidebar } = this.props;

    if (loading) {
      return (
        <div className="mt-5">
          <Loading className="h-100" />
        </div>
      );
    }

    if (errorMessage) {
      return <div className="mt-5 text-danger">Error: {errorMessage}</div>;
    }

    return (
      <React.Fragment>
        <Header
          sidebar={sidebar}
          title="Bank"
          mode="buttons"
          rightNav={this.rightNav()}
        />
        <Row className="mb-2">
          <Col md="6" className="pr-1">
            <Card>
              <CardHeader className="text-center">
                <strong>Uncleared Transactions</strong>
              </CardHeader>
              <CardBody className="px-2 py-0">
                <BankTrnxList
                  query={{ listType: 'uncleared' }}
                  page={1}
                  refresh={refreshTrnxs}
                  handleSuccess={this.handleSuccess}
                  handleError={this.handleError}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md="6" className="pl-1">
            <Card>
              <CardHeader className="text-center">
                <strong>Upcoming Transactions</strong>
              </CardHeader>
              <CardBody className="px-2 py-0">
                <BankTrnxList
                  query={{ listType: 'upcoming' }}
                  page={1}
                  refresh={refreshTrnxs}
                  handleSuccess={this.handleSuccess}
                  handleError={this.handleError}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Card>
              <CardHeader className="text-center">
                <h4>Bank Accounts</h4>
              </CardHeader>
              <div>
                <CardBody className="px-1 py-1">
                  <Table striped hover responsive size="sm">
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">Name</th>
                        <th className="text-center">Account Num</th>
                        <th className="text-center">Current Balance</th>
                        <th className="text-center">Available Balance</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {banks.map((b, i) => (
                        <tr key={b._id}>
                          <th scope="row" className="text-center">
                            {i + 1}
                          </th>
                          <td>{b.name}</td>
                          <td>{b.accountNumber}</td>
                          <td className="text-right">
                            {currencyFormat(b.currentBalance, 'text-primary')}
                          </td>
                          <td className="text-right">
                            {currencyFormat(b.availableBalance, 'text-success')}
                          </td>
                          <td className="text-center">
                            <Link to={`/app/bank/transactions/${b._id}`}>
                              <Button color="primary" size="sm">
                                View
                              </Button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="float-right">
                    <Link to="/app/bank/transactions/">
                      <Button color="primary" size="sm">
                        View All Transactions
                      </Button>
                    </Link>
                  </div>
                </CardBody>
              </div>
            </Card>
          </Col>
        </Row>

        <Modal isOpen={showModal} toggle={this.toggleModal} backdrop="static">
          <ModalHeader>
            {formType === 'deposit' ? 'Deposit' : 'Withdrawal'}
          </ModalHeader>
          <ModalBody>
            <BankTrxnForm
              close={this.toggleModal}
              type={formType}
              handleSuccess={this.handleSuccess}
              handleError={this.handleError}
              done={this.handleSuccess}
            />
          </ModalBody>
        </Modal>

        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default Bank;
