import React, { Component } from 'react';
import { Card, Table, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Header from '../../containers/Header';
import Pagination from '../../components/Pagination';
import RowsPerPage from '../../components/RowsPerPage';
import currencyFormat from '../../util/currency';
import dateFormat from '../../util/date';
import API from '../../api';
import { DEFAULT_PER_PAGE } from '../../constants/others';

class UnpaidInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      page: 1,
      pages: 1,
      perPage: DEFAULT_PER_PAGE,
      loading: true
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { page, perPage } = this.state;
    let savedPerPage = localStorage.getItem('unpaidInvoices-perPage');
    if (savedPerPage) {
      savedPerPage = parseInt(savedPerPage, 10);
      this.setState({ perPage: savedPerPage });
      this.getList(page, savedPerPage);
    } else {
      this.getList(page, perPage);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getList(page, perPage) {
    API.UnpaidInvoices(page, perPage)
      .then(res => {
        if (!this.mounted) return;
        this.setState({ ...res, loading: false });
      })
      .catch(err => {
        console.log(err);
        if (!this.mounted) return;
        this.setState({ loading: false });
      });
  }

  setCurrentPage = curPage => {
    const { page, perPage } = this.state;
    if (curPage === page) {
      return;
    }
    this.setState({ page: curPage, loading: true }, () =>
      this.getList(curPage, perPage)
    );
  };

  setRowsPerPage = rowsPerPage => {
    const { page, perPage } = this.state;
    if (rowsPerPage === perPage) {
      return;
    }

    localStorage.setItem('unpaidInvoices-perPage', rowsPerPage);
    this.setState({ page: 1, perPage: rowsPerPage, loading: true }, () =>
      this.getList(page, rowsPerPage)
    );
  };

  renderLoading = () => {
    return (
      <tr key="spinner">
        <td colSpan={9} className="text-center">
          <span className="spinner-border" role="status" aria-hidden="true" />
        </td>
      </tr>
    );
  };

  renderList = () => {
    const { list } = this.state;
    const rows = [];
    list.forEach(row => {
      const companyUrl = `/app/company/${row.companyId}`;
      let rowcolor = '';
      const days = moment().diff(moment(row.date), 'days');
      if (days > 60) rowcolor = 'table-danger';
      else if (days > 30) rowcolor = 'table-warning';
      rows.push(
        <tr key={row._id} className={rowcolor}>
          <th scope="row">{row.invoiceNum}</th>
          <td>{row.birInvoiceNum}</td>
          <td>{row.billingInvoiceNum}</td>
          <td>{row.soNum}</td>
          <td>
            <Link to={companyUrl}>{row.companyName}</Link>
          </td>
          <td>{dateFormat(row.date)}</td>
          <td className="text-right">{currencyFormat(row.amount)}</td>
          <td>
            {row.payments.map((p, k) => {
              if (k === 0) {
                return (
                  <span key={p._id}>
                    <strong>{p.orNum}</strong> - {currencyFormat(p.amount)}
                  </span>
                );
              }
              return (
                <span key={p._id}>
                  , <strong>{p.orNum}</strong> - {currencyFormat(p.amount)}
                </span>
              );
            })}
          </td>
          <td className="text-right">{currencyFormat(row.balance)}</td>
        </tr>
      );
    });
    return rows;
  };

  render() {
    const { loading, page, pages, perPage } = this.state;
    const { sidebar } = this.props;

    return (
      <div>
        <Header sidebar={sidebar} title="Unpaid Invoice" />
        <div className="row">
          <div className="col-sm-12">
            <Card body>
              <Row>
                <Col sm={8}>
                  <em>
                    * Yellow row means more than 30 days. Pink row means more
                    than 60 days.
                  </em>
                </Col>
                <Col sm={4}>
                  <Pagination
                    page={page}
                    pages={pages}
                    setCurrentPage={this.setCurrentPage}
                  />
                </Col>
              </Row>
              <Table striped hover responsive size="sm">
                <thead>
                  <tr className="text-center">
                    <th>ID</th>
                    <th>INV#</th>
                    <th>BL#</th>
                    <th>SO#</th>
                    <th>Customer</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>PAY#</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? this.renderLoading() : this.renderList()}
                </tbody>
              </Table>
              <Row>
                <Col sm={6}>
                  <RowsPerPage
                    perPage={perPage}
                    setRowsPerPage={this.setRowsPerPage}
                  />
                </Col>
                <Col sm={6}>
                  <Pagination
                    page={page}
                    pages={pages}
                    setCurrentPage={this.setCurrentPage}
                  />
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default UnpaidInvoice;
