const banks = [
  {
    value: '5fa4e777f479fdba05160095',
    label: 'BDO - Capitol'
  },
  {
    value: '5fa4e777f479fdba05160093',
    label: 'BDO - Meralco Ave'
  },
  {
    value: '5fa4e777f479fdba05160094',
    label: 'BDO - Pasig Pioneer - Static'
  },
  {
    value: '5fa4e777f479fdba0516009a',
    label: 'BDO - Pasig Pioneer - Megaduct'
  },
  {
    value: '5fa4e777f479fdba0516009c',
    label: 'BPI - Capitol Commons'
  },
  {
    value: '5fa4e777f479fdba0516009d',
    label: 'Eastwest Bank - 2255 Garnet'
  },
  {
    value: '5fa4e777f479fdba05160096',
    label: 'Metrobank - Escriva'
  },
  {
    value: '5fa4e777f479fdba0516009e',
    label: 'RCBC - Pasig'
  },
  {
    value: '5fa4e777f479fdba05160099',
    label: "Robinson's Bank - Meralco Ave"
  },
  {
    value: '5fa4e777f479fdba05160097',
    label: 'Security Bank - C Raymundo - 0343'
  },
  {
    value: '5fa4e777f479fdba05160098',
    label: 'Security Bank - C Raymundo - 1001'
  },
  {
    value: '5fa4e777f479fdba0516009b',
    label: 'Union Bank - Renaissance Tower'
  }
];

export default banks;
