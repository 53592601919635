import React, { Component } from 'react';
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Companies from '../company/companies';
import SalesAgent from '../users/salesAgent';

import Products from './products';

import { UserContext } from '../../context/user';

import API from '../../api';

class ProjectForm extends Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.projectForm = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setValidationSchema = Yup.object().shape({
    companyName: Yup.string().required('Required'),
    companyId: Yup.string().required('Required'),
    salesAgentUserName: Yup.string().required('Required'),
    salesAgentUserId: Yup.string().required('Required'),
    product: Yup.string().required('Required')
  });

  composeProjectFormFields = (errors, touched, isSubmitting) => {
    const { selectedProject } = this.props;
    const { user } = this.context;

    const excludedValues = [];
    if (['kdc', 'executive', 'sales'].indexOf(user.role) === -1) {
      excludedValues.push('Approved');
    }

    return (
      <div className="col-xs-12 m-a">
        <Row form>
          <Col xs={12}>
            <Row form>
              <Col sm={6}>
                <FormGroup>
                  <Label for="reference">Project Reference</Label>
                  <Input
                    tag={Field}
                    type="text"
                    name="reference"
                    className="form-control-plaintext"
                    readOnly
                    placeholder={
                      selectedProject
                        ? selectedProject.reference
                        : 'Auto generated'
                    }
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="companyName">Company Name</Label>
                  <Field
                    name="companyName"
                    component={Companies}
                    disabled={isSubmitting}
                    invalid={errors.companyName && touched.companyName}
                    includeCompanyInfo
                  />
                  {errors.companyName && touched.companyName ? (
                    <div className="text-danger">{errors.companyName}</div>
                  ) : null}
                  <Input tag={Field} type="hidden" name="companyId" />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={6}>
                <FormGroup>
                  <Label for="projectName">Project Name</Label>
                  <Input
                    tag={Field}
                    type="text"
                    name="projectName"
                    disabled={isSubmitting}
                    invalid={errors.projectName && touched.projectName}
                  />
                  <FormFeedback>{errors.projectName}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="product">Product</Label>
                  <Field
                    name="product"
                    component={Products}
                    disabled={isSubmitting}
                    invalid={errors.product && touched.product}
                  />
                  {errors.product && touched.product ? (
                    <div className="text-danger">{errors.product}</div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={6}>
                <FormGroup>
                  <Label for="salesAgentUserName">Sales Agent</Label>
                  <Field
                    name="salesAgentUserName"
                    component={SalesAgent}
                    disabled={isSubmitting}
                    invalid={
                      errors.salesAgentUserName && touched.salesAgentUserName
                    }
                  />
                  {errors.salesAgentUserName && touched.salesAgentUserName ? (
                    <div className="text-danger">
                      {errors.salesAgentUserName}
                    </div>
                  ) : null}
                  <Input tag={Field} type="hidden" name="salesAgentUserId" />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  composeProjectFormButtons = (
    errors,
    touched,
    isSubmitting,
    toggleProjectModal
  ) => {
    const { selectedProject } = this.props;

    return (
      <div className="col-sm-12">
        <FormGroup row>
          <Col className="text-right">
            <Button type="submit" color="primary" className="mr-1">
              {selectedProject ? 'Update' : 'Create'}
            </Button>
            <Button type="button" color="danger" onClick={toggleProjectModal}>
              Cancel
            </Button>
          </Col>
        </FormGroup>
      </div>
    );
  };

  setInitialValues = () => {
    const { selectedProject } = this.props;
    const {
      reference,
      companyId,
      companyName,
      projectName,
      salesAgentUserId,
      salesAgentUserName,
      product,
      status
    } = selectedProject || {};

    return {
      reference: reference || '',
      companyId: companyId || '',
      companyName: companyName || '',
      projectName: projectName || '',
      salesAgentUserId: salesAgentUserId || '',
      salesAgentUserName: salesAgentUserName || '',
      product: product || '',
      status: status || ''
    };
  };

  onSubmit = (values, { setSubmitting, setStatus, setErrors }) => {
    const { parent, selectedProject, toggleProjectModal } = this.props;

    if (selectedProject) values._id = selectedProject._id;

    if (!values.status) {
      values.status = 'For Quotation';
    }

    const promise = selectedProject
      ? API.ProjectUpdate(values)
      : API.ProjectAdd(values);

    return promise
      .then(() => {
        if (!this.mounted) return;
        setSubmitting(false);
        parent.setLastUpdate('listUpdateTs', new Date().getTime());
        toggleProjectModal();
      })
      .catch(e => {
        console.log('ProjectForm', e);
        setErrors({ submit: e.message });
        setStatus({ success: false });
        setSubmitting(false);
      });
  };

  render() {
    const { toggleProjectModal } = this.props;

    return (
      <Formik
        initialValues={this.setInitialValues()}
        validationSchema={this.setValidationSchema}
        onSubmit={this.onSubmit}
        ref={this.projectForm}
      >
        {props => {
          const { errors, touched, isSubmitting } = props;
          return (
            <Form>
              {this.composeProjectFormFields(errors, touched, isSubmitting)}
              {this.composeProjectFormButtons(
                errors,
                touched,
                isSubmitting,
                toggleProjectModal
              )}
            </Form>
          );
        }}
      </Formik>
    );
  }
}

ProjectForm.contextType = UserContext;

export default ProjectForm;
