import moment from 'moment';

const setDateString = (value, loadDefaultToday) => {
  if (!value) {
    if (loadDefaultToday) return undefined;
    return '';
  }
  return value;
};

const dateFormat = (value, loadDefaultToday = false) => {
  const dateString = setDateString(value, loadDefaultToday);
  if (!moment(dateString).isValid()) return '-';
  return moment(dateString).format('YY.MM.DD');
};

export const dateTimeFormat = (value, loadDefaultToday = false) => {
  const dateString = setDateString(value, loadDefaultToday);
  if (!moment(dateString).isValid()) return '-';
  return moment(dateString).format('llll');
};

export const userStringDateFormat = (
  value,
  format,
  loadDefaultToday = false
) => {
  const dateString = setDateString(value, loadDefaultToday);
  if (!moment(dateString).isValid()) return '-';
  return moment(dateString).format(format);
};

export const formatHour = value => {
  let min = '00';
  if (value.indexOf('30') > -1) min = '30';

  let hour = parseInt(value, 10);

  let latinExtentsion = 'AM';

  if (hour >= 12) {
    if (hour !== 12) hour -= 12;
    latinExtentsion = 'PM';
  }

  if (hour === 0) hour = 12;

  return `${hour < 10 ? `0${hour}` : hour}:${min} ${latinExtentsion}`;
};

export const getWeekDateRangeText = (date = moment().startOf('isoweek')) => {
  let startDate = moment(date);
  if (!moment(startDate).isValid()) startDate = moment().startOf('isoweek');

  const dateRange = startDate.format('MMM D');
  const endDate = startDate.add(6, 'day').format('MMM D, YYYY');
  return `${dateRange} -  ${endDate}`;
};

export const getWeekDateRange = (date = moment().startOf('isoweek')) => {
  let startDate = moment(date);
  if (!moment(startDate).isValid()) startDate = moment().startOf('isoweek');

  const dateRange = [];

  dateRange.push({
    stringDate: startDate.format('YYYY-MM-DD'),
    formattedDate: startDate.format('ddd MM/DD')
  });

  let index = 0;
  while (index < 6) {
    startDate.add(1, 'days');
    dateRange.push({
      stringDate: startDate.format('YYYY-MM-DD'),
      formattedDate: startDate.format('ddd MM/DD')
    });

    index += 1;
  }

  return dateRange;
};

export const getMonthDateRange = (date = moment(), adjustToStart = false) => {
  let startDate = moment(date);
  if (!moment(startDate).isValid()) startDate = moment();

  startDate = adjustToStart ? startDate.startOf('month') : startDate;
  const numberOfDays = startDate.daysInMonth();
  const dateRange = [];

  dateRange.push({
    stringDate: startDate.format('YYYY-MM-DD'),
    formattedDate: startDate.format('ddd MM/DD')
  });

  let index = 0;
  while (index < numberOfDays - 1) {
    startDate.add(1, 'days');
    dateRange.push({
      stringDate: startDate.format('YYYY-MM-DD'),
      formattedDate: startDate.format('ddd MM/DD')
    });

    index += 1;
  }

  return dateRange;
};

export default dateFormat;
