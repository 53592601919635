import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IntlMessages from '../util/IntlMessages';
import SidebarHeader from './SidebarHeader';
import NavLinkAutoClose from '../components/NavLinkAutoClose';

const Sidebar = ({ sidebar }) => {
  const { hidden, toggleSidebar } = sidebar;
  return (
    <div
      id="sidebar-wrapper"
      className={hidden ? 'hidden dark border-right' : 'dark border-right'}
    >
      <SidebarHeader />
      <div className="list-group list-group-flush">
        <NavLinkAutoClose to="/app/dashboard" toggleSidebar={toggleSidebar}>
          <FontAwesomeIcon className="mr-2" icon="home" />
          <IntlMessages id="sideBar.dashboard" />
        </NavLinkAutoClose>
        <NavLinkAutoClose
          to="/app/reports/activity-weekly"
          toggleSidebar={toggleSidebar}
        >
          <FontAwesomeIcon className="mr-2" icon="tasks" />
          <IntlMessages id="sideBar.activityWeekly" />
        </NavLinkAutoClose>
        <NavLinkAutoClose to="/app/employee" toggleSidebar={toggleSidebar}>
          <FontAwesomeIcon className="mr-2" icon="user" />
          <IntlMessages id="sideBar.employee" />
        </NavLinkAutoClose>
      </div>
    </div>
  );
};

export default Sidebar;
