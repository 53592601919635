import React, { Component } from 'react';
import { Button, Col, FormGroup, Badge } from 'reactstrap';
import dateFormat from '../../util/date';
import currencyFormat from '../../util/currency';
import { CLEARED_THRESHOLD } from '../../constants/others';

class SoView extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getSOBadge = so => {
    const badges = [];
    if (so.invoiceBalance > CLEARED_THRESHOLD) {
      badges.push(
        <Badge key="unpaid" pill color="danger">
          Unpaid
        </Badge>
      );
    }
    if (so.unbilledAmount > CLEARED_THRESHOLD) {
      badges.push(
        <Badge key="unbilled" pill color="warning">
          Unbilled
        </Badge>
      );
    }
    if (badges.length === 0) {
      badges.push(
        <Badge key="unbilled" pill color="success">
          Cleared
        </Badge>
      );
    }
    return badges;
  };

  composeSoViewFields = () => {
    const { selectedSo } = this.props;
    const badge = this.getSOBadge(selectedSo);
    return (
      <div className="mb-2">
        <dl className="row mb-0 pb-0">
          <dt className="col-6 col-sm-6 col-md-4">Sales Order:</dt>
          <dd className="col-6 col-sm-6 col-md-8">
            {selectedSo.soNum} {badge}
          </dd>
          <dt className="col-6 col-sm-6 col-md-4">Amount:</dt>
          <dd className="col-6 col-sm-6 col-md-8">
            {currencyFormat(selectedSo.poAmount, 'text-primary')}
          </dd>
          <dt className="col-6 col-sm-6 col-md-4">Unpaid Invoice:</dt>
          <dd className="col-6 col-sm-6 col-md-8">
            {currencyFormat(selectedSo.invoiceBalance, 'text-danger')}
          </dd>
          <dt className="col-6 col-sm-6 col-md-4">Unbilled:</dt>
          <dd className="col-6 col-sm-6 col-md-8">
            {currencyFormat(selectedSo.unbilledAmount, 'text-warning')}
          </dd>
          <dt className="col-6 col-sm-6 col-md-4">Project Name:</dt>
          <dd className="col-6 col-sm-6 col-md-8">
            {selectedSo.projectName ? selectedSo.projectName : '-'}
          </dd>
          <dt className="col-6 col-sm-6 col-md-4">Sales Agent:</dt>
          <dd className="col-6 col-sm-6 col-md-8">{selectedSo.salesAgent}</dd>
          <dt className="col-6 col-sm-6 col-md-4">PO Date:</dt>
          <dd className="col-6 col-sm-6 col-md-8">
            {dateFormat(selectedSo.poDate)}
          </dd>
          <dt className="col-6 col-sm-6 col-md-4">SO Date:</dt>
          <dd className="col-6 col-sm-6 col-md-8">
            {dateFormat(selectedSo.soDate)}
          </dd>
          <dt className="col-6 col-sm-6 col-md-4">Terms:</dt>
          <dd className="col-6 col-sm-6 col-md-8">
            {selectedSo.terms ? selectedSo.terms : '-'}
          </dd>
          <dt className="col-6 col-sm-6 col-md-4">TPC:</dt>
          <dd className="col-6 col-sm-6 col-md-8">
            {selectedSo.tpcAmount ? currencyFormat(selectedSo.tpcAmount) : '-'}
          </dd>
          <dt className="col-6 col-sm-6 col-md-4">Delivery Date:</dt>
          <dd className="col-6 col-sm-6 col-md-8">
            {dateFormat(selectedSo.deliveryDate)}
          </dd>
        </dl>
        <dl className="row mb-0 pb-0">
          <dt className="col-6 col-sm-6 col-md-4">Description:</dt>
          <dd className="col-6 col-sm-6 col-md-8">
            {selectedSo.description ? selectedSo.description : '-'}
          </dd>
        </dl>
        <dl className="row mb-0 pb-0">
          <dt className="col-6 col-sm-6 col-md-4">Remarks:</dt>
          <dd className="col-6 col-sm-6 col-md-8">
            {selectedSo.remarks ? selectedSo.remarks : '-'}
          </dd>
        </dl>
      </div>
    );
  };

  composeSoViewButtons = toggleSoViewModal => {
    return (
      <div className="col-sm-12">
        <FormGroup row>
          <Col className="text-right">
            <Button type="button" color="danger" onClick={toggleSoViewModal}>
              Close
            </Button>
          </Col>
        </FormGroup>
      </div>
    );
  };

  render() {
    const { toggleSoViewModal } = this.props;

    return (
      <div>
        {this.composeSoViewFields()}
        {this.composeSoViewButtons(toggleSoViewModal)}
      </div>
    );
  }
}

export default SoView;
