import React, { Component } from 'react';
import { Button, Col, FormGroup, Label } from 'reactstrap';

import { UserContext } from '../../context/user';
import dateFormat from '../../util/date';
import currencyFormat from '../../util/currency';

class QuotationView extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  composeQuotationViewFields = () => {
    const { selectedQuotation } = this.props;
    return (
      <div className="row" style={{ display: 'flex' }}>
        <div className="col-sm-12">
          <Label className="col-sm-3 text-right">Date</Label>
          <Label className="col-sm-9">
            {dateFormat(selectedQuotation.date)}
          </Label>
        </div>
        <div className="col-sm-12">
          <Label className="col-sm-3 text-right">Price</Label>
          <Label className="col-sm-9">
            {currencyFormat(selectedQuotation.price)}
          </Label>
        </div>
        <div className="col-sm-12">
          <Label className="col-sm-3 text-right">Estimator</Label>
          <Label className="col-sm-9">
            {selectedQuotation.marketingUserName}
          </Label>
        </div>
        <div className="col-sm-12">
          <Label className="col-sm-3 text-right">Scope</Label>
          <Label className="col-sm-9">{selectedQuotation.scopeOfWork}</Label>
        </div>
        <div className="col-sm-12">
          <Label className="col-sm-3 text-right">Remarks</Label>
          <Label className="col-sm-9">{selectedQuotation.remarks}</Label>
        </div>
      </div>
    );
  };

  composeQuotationViewButtons = toggleQuotationViewModal => {
    return (
      <div className="col-sm-12" style={{ marginTop: '21px' }}>
        <FormGroup row>
          <Col className="text-right">
            <Button
              type="button"
              color="danger"
              onClick={toggleQuotationViewModal}
            >
              Close
            </Button>
          </Col>
        </FormGroup>
      </div>
    );
  };

  render() {
    const { toggleQuotationViewModal } = this.props;

    return (
      <div>
        {this.composeQuotationViewFields()}
        {this.composeQuotationViewButtons(toggleQuotationViewModal)}
      </div>
    );
  }
}

QuotationView.contextType = UserContext;

export default QuotationView;
