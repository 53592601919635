import React from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../context/user';

class SignOut extends React.Component {
  componentDidMount() {
    const userCtx = this.context;

    if (userCtx.logout) {
      userCtx.logout();
    }
  }

  render() {
    return <Redirect to="/signin" />;
  }
}

SignOut.contextType = UserContext;

export default SignOut;
