/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Table,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/user';
import Loading from '../../components/Loading';
import dateFormat from '../../util/date';
import currencyFormat from '../../util/currency';
import sortArrayObjectsByKeys from '../../util/processArrayObjects';
import { CLEARED_THRESHOLD } from '../../constants/others';
import PaymentAddInvoice from '../payment/addInvoice';
import PurchaseOrderForm from '../purchaseOrder/form';
import PurchaseOrderProjectForm from '../purchaseOrder/projectForm';
import InvoiceAdd from '../invoice/poAdd';
import PaymentAdd from '../payment/add';
import API from '../../api';

import {
  ROLE_KDC,
  ROLE_EXECUTIVE,
  ROLE_EXECUTIVE_SECRETARY,
  ROLE_RECEIVABLE,
  ROLE_PAYABLE,
  ROLE_PURCHASING
} from '../../util/roles';

class Payables extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPO: null,
      showPOModal: false,
      showInvoiceModal: false,
      showPaymentModal: false,
      showPaymentAddInvoiceModal: false,
      showPOProjectModal: false,
      editPO: null
    };

    this.poList = React.createRef();
    this.invList = React.createRef();
    this.payList = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
    this.setPOListHeight();
  }

  componentDidUpdate(prevProps) {
    // Make selected SO view auto update
    const { company } = this.props;
    const { selectedPO } = this.state;

    if (selectedPO && prevProps.company !== company) {
      if (!(company && company.purchases)) return;

      setTimeout(() => {
        company.purchases.forEach(selected => {
          if (selected._id === selectedPO._id) {
            this.setState({ selectedPO: selected });
          }
        });
      });
    }

    this.setPOListHeight();
  }

  setPOListHeight = () => {
    if (window.innerWidth <= 992) return;

    if (!this.poList.current) return;

    const rect = this.poList.current.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const bottomMargin = 10;

    // .. Temporary code.
    // .. Add 35px as space for history at the bottom of the page.
    const height =
      window.innerHeight - (rect.top + scrollTop) - bottomMargin - 55;

    this.poList.current.style.height = `${height}px`;
    this.poList.current.style.overflow = 'auto';
  };

  hiliteInvoice = invoice => {
    const { selectedPayment, selectedInvoice } = this.state;

    if (selectedInvoice && selectedInvoice._id === invoice._id) {
      return 'table-primary selectableTR text-center';
    }

    if (!selectedPayment) return 'selectableTR text-center';
    for (let i = 0; i < selectedPayment.invoices.length; i += 1) {
      const inv = selectedPayment.invoices[i];
      if (inv.invoiceNum === invoice.invoiceNum) {
        return 'table-primary selectableTR text-center';
      }
    }
    return 'selectableTR text-center';
  };

  hilitePayment = payment => {
    const { selectedPayment, selectedInvoice } = this.state;

    if (selectedPayment && selectedPayment._id === payment._id) {
      return 'table-primary selectableTR';
    }

    if (!selectedInvoice) return 'selectableTR';
    for (let i = 0; i < selectedInvoice.payments.length; i += 1) {
      const pay = selectedInvoice.payments[i];
      if (pay.orNum === payment.orNum) {
        return 'table-primary selectableTR';
      }
    }
    return 'selectableTR';
  };

  assignInvoiceToPayment = pay => {
    this.setState({
      selectedInvoice: null,
      selectedPayment: pay,
      showPaymentAddInvoiceModal: true
    });
  };

  togglePOModal = po => {
    this.setState(prevState => {
      return {
        editPO: po,
        showPOModal: !prevState.showPOModal
      };
    });
  };

  togglePOProjectModal = po => {
    this.setState(prevState => {
      return {
        editPO: po,
        showPOProjectModal: !prevState.showPOProjectModal
      };
    });
  };

  toggleInvoiceModal = () => {
    this.setState(prevState => {
      return {
        showInvoiceModal: !prevState.showInvoiceModal
      };
    });
  };

  togglePaymentModal = () => {
    this.setState(prevState => {
      return {
        showPaymentModal: !prevState.showPaymentModal
      };
    });
  };

  togglePaymentAddInvoiceModal = () => {
    this.setState(prevState => {
      return {
        showPaymentAddInvoiceModal: !prevState.showPaymentAddInvoiceModal
      };
    });
  };

  POAdded = () => {
    const { getCompanyPayablesSummary } = this.props;

    this.setState({ showPOModal: false }, () => {
      getCompanyPayablesSummary();
    });
  };

  InvoiceAdded = () => {
    const { getCompanyPayablesSummary } = this.props;

    this.setState({ showInvoiceModal: false }, () => {
      getCompanyPayablesSummary();
    });
  };

  PaymentAdded = () => {
    const { getCompanyPayablesSummary } = this.props;

    this.setState({ showPaymentModal: false }, () => {
      getCompanyPayablesSummary();
    });
  };

  PaymentAddInvoiceAdded = () => {
    const { getCompanyPayablesSummary } = this.props;

    this.setState({ showPaymentAddInvoiceModal: false }, () => {
      getCompanyPayablesSummary();
    });
  };

  POProjectAdded = () => {
    const { getCompanyPayablesSummary } = this.props;

    this.setState({ showPOProjectModal: false }, () => {
      getCompanyPayablesSummary();
    });
  };

  selectPO = po => {
    const { selectedPO } = this.state;
    if (selectedPO && po._id === selectedPO._id) {
      this.setState({
        selectedPO: null,
        selectedInvoice: null,
        selectedPayment: null
      });
    } else {
      this.setState({
        selectedPO: po,
        selectedInvoice: null,
        selectedPayment: null
      });
      window.scrollTo(0, 0);
    }
  };

  selectInvoice = inv => {
    const { selectedInvoice } = this.state;
    if (selectedInvoice && inv._id === selectedInvoice._id) {
      this.setState({ selectedInvoice: null });
    } else {
      this.setState({ selectedInvoice: inv, selectedPayment: null });
      window.scrollTo(0, this.payList.current.offsetTop);
    }
  };

  selectPayment = pay => {
    const { selectedPayment } = this.state;
    if (selectedPayment && pay._id === selectedPayment._id) {
      this.setState({ selectedPayment: null });
    } else {
      this.setState({ selectedInvoice: null, selectedPayment: pay });
      window.scrollTo(0, this.invList.current.offsetTop);
    }
  };

  filterUnpaidInvoices = invoices => {
    if (!invoices) return invoices;

    const filtered = [];
    invoices.forEach(inv => {
      if (inv.balance <= CLEARED_THRESHOLD) return;
      filtered.push({ ...inv });
    });
    return filtered;
  };

  removeInvoice = payment => {
    if (!window.confirm(`Are you sure you want un-assign selected payment?`)) {
      return;
    }

    const { getCompanyPayablesSummary } = this.props;

    const { orNum } = payment;
    const { company } = this.props;
    const { invoices } = company;

    if (!invoices) return;

    const invoiceNumList = [];
    invoices.forEach(inv => {
      const { payments, invoiceNum } = inv;
      if (!payments) return;

      payments.forEach(pay => {
        const { orNum: paymentOrNum } = pay;

        if (paymentOrNum === orNum) {
          invoiceNumList.push(pay.invoiceNum || invoiceNum);
        }
      });
    });

    if (invoiceNumList.length) {
      const params = { orNum, invoiceNum: invoiceNumList };

      API.PaymentRemoveInvoice(params)
        .then(() => {
          if (!this.mounted) return;
          getCompanyPayablesSummary();
        })
        .catch(e => {
          console.log('PaymentRemoveInvoice Error', e);
        });
    }
  };

  deletePayment = payment => {
    if (!window.confirm(`Are you sure you want delete selected payment?`)) {
      return;
    }

    const { getCompanyPayablesSummary } = this.props;

    API.PaymentDelete({ id: payment._id })
      .then(() => {
        if (!this.mounted) return;
        getCompanyPayablesSummary();
      })
      .catch(err => {
        console.log(err);
      });
  };

  deleteInvoice = invoice => {
    if (!window.confirm(`Are you sure you want delete selected invoice?`)) {
      return;
    }

    const { amount, balance } = invoice;
    if (amount !== balance) {
      alert(
        'Cannot delete invoice.\n\nPlease make sure that the invoice you are trying to delete is not connected to any payment.'
      );
      return;
    }

    const { getCompanyPayablesSummary } = this.props;

    API.InvoiceDelete({ id: invoice._id })
      .then(() => {
        if (!this.mounted) return;
        getCompanyPayablesSummary();
      })
      .catch(err => {
        console.log(err);
      });
  };

  renderLoading = () => {
    return (
      <div className="mt-5">
        <Loading className="h-100" />
      </div>
    );
  };

  renderAddDropdownMenu = () => {
    return (
      <div style={{ padding: '0px 0px 21px', textAlign: 'right' }}>
        <UncontrolledButtonDropdown className="mr-1">
          <DropdownToggle caret color="primary" size="sm">
            Add
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={() => this.togglePOModal(null)}>
              Purchase Order
            </DropdownItem>
            <DropdownItem onClick={this.toggleInvoiceModal}>
              Invoice
            </DropdownItem>
            <DropdownItem onClick={this.togglePaymentModal}>
              Payment
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    );
  };

  renderPayableSummary = company => {
    let invoiceAmount = 0;
    let paidAmount = 0;
    let invoiceBalance = 0;
    let unbilledAmount = 0;

    if (company.payables) {
      invoiceAmount = company.payables.invoiceAmount || 0;
      paidAmount = company.payables.paidAmount || 0;
      invoiceBalance = company.payables.invoiceBalance || 0;
      unbilledAmount = company.payables.unbilledAmount || 0;
    }

    const invColor = invoiceAmount ? 'bg-primary' : 'bg-light text-dark';
    const paidColor = paidAmount ? 'bg-success' : 'bg-light text-dark';
    const unpaidColor = invoiceBalance ? 'bg-danger' : 'bg-light text-dark';
    const unbilledColor = unbilledAmount ? 'bg-warning' : 'bg-light text-dark';

    return (
      <Row className="mb-3 mx-1 py-1 px-1 bg-secondary text-white text-center">
        <Col lg={1} sm={2} xs={4}>
          Invoice
        </Col>
        <Col lg={2} sm={4} xs={8} className={invColor}>
          <strong>{currencyFormat(invoiceAmount)}</strong>
        </Col>
        <Col lg={1} sm={2} xs={4}>
          Paid
        </Col>
        <Col lg={2} sm={4} xs={8} className={paidColor}>
          <strong>{currencyFormat(paidAmount)}</strong>
        </Col>
        <Col lg={1} sm={2} xs={4}>
          Unpaid
        </Col>
        <Col lg={2} sm={4} xs={8} className={unpaidColor}>
          <strong>{currencyFormat(invoiceBalance)}</strong>
        </Col>
        <Col lg={1} sm={2} xs={4}>
          Unbilled
        </Col>
        <Col lg={2} sm={4} xs={8} className={unbilledColor}>
          <strong>{currencyFormat(unbilledAmount)}</strong>
        </Col>
      </Row>
    );
  };

  renderPOListContainer = () => {
    return (
      <Card>
        <CardHeader className="text-center">
          <strong>Purchase Order</strong>
        </CardHeader>
        <div ref={this.poList}>
          <CardBody className="px-1 py-0">{this.renderPOList()}</CardBody>
        </div>
      </Card>
    );
  };

  renderPOList() {
    const { company } = this.props;
    const { purchases } = company;

    const { selectedPO } = this.state;
    const sortedPurchases = purchases
      ? sortArrayObjectsByKeys(purchases, 'poNum')
      : [];

    return (
      <Table striped hover responsive size="sm">
        <thead>
          <tr>
            <th>PO#</th>
            <th>Project</th>
          </tr>
        </thead>
        <tbody>
          {sortedPurchases.length > 0 ? (
            sortedPurchases.map(row => {
              return (
                <tr
                  key={row._id}
                  className={
                    selectedPO && row._id === selectedPO._id
                      ? 'table-primary'
                      : 'selectableTR'
                  }
                  onClick={() => this.selectPO(row)}
                >
                  <th scope="row">
                    <div>{row.poNum}</div>
                  </th>
                  <td>
                    {row.projectName && <div>{row.projectName}</div>}
                    {currencyFormat(row.poAmount)}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <th scope="row" colSpan={2} className="text-center">
                -- none --
              </th>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }

  renderProjects = projects => {
    const projectList = [];
    projects.forEach((v, k) => {
      const key = k + new Date().getTime();
      projectList.push(
        <div key={key}>
          <Row>
            <Col sm={8} style={{ paddingTop: '7px' }}>
              {v.name}
            </Col>
            <Col sm={4} style={{ paddingTop: '7px' }}>
              {v.amount}
            </Col>
          </Row>
        </div>
      );
    });

    if (!projectList.length) {
      return <div style={{ paddingBottom: '10px' }}></div>;
    }

    return (
      <div style={{ paddingBottom: '21px' }}>
        <dl className="row mb-0 pb-0">
          <dt className="col-12 col-sm-12">Projects:</dt>
          <dd className="col-12 col-sm-12">{projectList}</dd>
        </dl>
      </div>
    );
  };

  renderPODetails() {
    const { selectedPO } = this.state;
    if (!selectedPO) return '';

    return (
      <Card className="mb-2">
        <CardHeader className="px-3">
          <Row>
            <Col sm={6}>
              <strong className="mr-1">Purchase Order</strong>
            </Col>
            <Col sm={6} className="text-right">
              Amount: {currencyFormat(selectedPO.poAmount, 'text-primary')}
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="bg-white">
          <dl className="row mb-0 pb-0">
            <dt className="col-6 col-sm-3">PO Date</dt>
            <dd className="col-6 col-sm-3">{dateFormat(selectedPO.poDate)}</dd>
            <dt className="col-6 col-sm-3">Delivery Date</dt>
            <dd className="col-6 col-sm-3">
              {dateFormat(selectedPO.deliveryDate)}
            </dd>
            <dt className="col-6 col-sm-3">PO Agent:</dt>
            <dd className="col-6 col-sm-3">
              {selectedPO.purchasingOfficerName
                ? selectedPO.purchasingOfficerName
                : '-'}
            </dd>
            <dt className="col-6 col-sm-3">MRF Number:</dt>
            <dd className="col-6 col-sm-3">
              {selectedPO.mrfNo ? selectedPO.mrfNo : '-'}
            </dd>
          </dl>
          <dl className="row mb-0 pb-0">
            <dt className="col-12 col-sm-12">Term:</dt>
            <dd className="col-12 col-sm-12">
              {selectedPO.terms ? selectedPO.terms : '-'}
            </dd>
          </dl>
          <dl className="row mb-0 pb-0">
            <dt className="col-12 col-sm-12">Description:</dt>
            <dd className="col-12 col-sm-12">
              {selectedPO.description ? selectedPO.description : '-'}
            </dd>
          </dl>
          <dl className="row mb-0 pb-0">
            <dt className="col-12 col-sm-12">Remarks:</dt>
            <dd className="col-12 col-sm-12">
              {selectedPO.remarks ? selectedPO.remarks : '-'}
            </dd>
          </dl>

          {this.renderProjects(selectedPO.projects)}

          <div className="text-right">
            <Button size="sm" onClick={() => this.togglePOModal(selectedPO)}>
              Edit
            </Button>

            <Button
              style={{ marginLeft: '10px' }}
              size="sm"
              onClick={() => this.togglePOProjectModal(selectedPO)}
            >
              {selectedPO.projects instanceof Array &&
              selectedPO.projects.length
                ? 'Update'
                : 'Add'}{' '}
              Project
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }

  renderUnassignedPaymentList = payments => {
    if (!payments) return null;

    const { user } = this.context;
    const { role } = user;

    const roles = [
      ROLE_KDC,
      ROLE_EXECUTIVE,
      ROLE_EXECUTIVE_SECRETARY,
      ROLE_RECEIVABLE,
      ROLE_PAYABLE,
      ROLE_PURCHASING
    ];

    const isDeletePaymentAllowed = roles.indexOf(role) > -1;

    const { selectedPO } = this.state;
    if (selectedPO) return null;

    let unassigned = _.filter(payments, p => {
      return p.excessAmount > 0;
    });

    unassigned = unassigned.map(row => {
      const trClass = this.hilitePayment(row);
      return (
        <tr key={row._id} className={trClass}>
          <th scope="row" onClick={() => this.assignInvoiceToPayment(row)}>
            {row.birOrNum}
          </th>

          <td
            className="d-none d-sm-table-cell"
            onClick={() => this.assignInvoiceToPayment(row)}
          >
            {dateFormat(row.date)}
          </td>
          <td
            className="d-none d-sm-table-cell text-right"
            onClick={() => this.assignInvoiceToPayment(row)}
          >
            {currencyFormat(row.amount)}
          </td>
          <td
            className="text-right"
            onClick={() => this.assignInvoiceToPayment(row)}
          >
            {currencyFormat(row.excessAmount)}
          </td>
          {isDeletePaymentAllowed ? (
            <td className="text-center">
              <Link
                to="/#"
                onClick={e => {
                  e.preventDefault();
                  this.deletePayment(row);
                }}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  className="ml-2 mr-2 text-danger"
                />
              </Link>
            </td>
          ) : (
            ''
          )}
        </tr>
      );
    });

    if (!unassigned || unassigned.length <= 0 || unassigned[0] === null) {
      return null;
    }

    return (
      <Card className="mb-3">
        <CardHeader className="text-center bg-danger text-white">
          <strong>Unassigned Payment</strong>
        </CardHeader>
        <CardBody className="px-1 py-0">
          <Table striped hover responsive size="sm">
            <thead>
              <tr>
                <th>OR#</th>
                <th className="d-none d-sm-table-cell">Date</th>
                <th className="d-none d-sm-table-cell text-center">Amount</th>
                <th className="text-center">Unassigned</th>
                {isDeletePaymentAllowed ? (
                  <th className="text-center"></th>
                ) : (
                  ''
                )}
              </tr>
            </thead>
            <tbody>{unassigned}</tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  renderInvoiceList = invoices => {
    if (!invoices) return '';

    const { user } = this.context;
    const { role } = user;

    const roles = [
      ROLE_KDC,
      ROLE_EXECUTIVE,
      ROLE_EXECUTIVE_SECRETARY,
      ROLE_RECEIVABLE,
      ROLE_PAYABLE,
      ROLE_PURCHASING
    ];

    const isRemoveInvoiceAllowed = roles.indexOf(role) > -1;

    return (
      <Table striped hover responsive size="sm">
        <thead>
          <tr className="text-center">
            <th>Status</th>
            <th>INV#</th>
            <th>BL#</th>
            <th className="d-none d-sm-table-cell">Date</th>
            <th>Amount</th>
            <th>Balance</th>
            {isRemoveInvoiceAllowed ? <th className="text-center"></th> : ''}
          </tr>
        </thead>
        <tbody>
          {invoices.length > 0 ? (
            invoices.map(row => {
              const trClass = this.hiliteInvoice(row);
              const badge =
                row.balance > CLEARED_THRESHOLD ? (
                  <Badge key="unpaid" pill color="danger">
                    Unpaid
                  </Badge>
                ) : (
                  <Badge key="paid" pill color="success">
                    Paid
                  </Badge>
                );
              return (
                <tr
                  key={row._id}
                  className={trClass}
                  onClick={() => this.selectInvoice(row)}
                >
                  <td>{badge}</td>
                  <th scope="row">{row.birInvoiceNum}</th>
                  <th scope="row">{row.billingInvoiceNum}</th>
                  <td className="d-none d-sm-table-cell">
                    {dateFormat(row.date)}
                  </td>
                  <td className="text-right">{currencyFormat(row.amount)}</td>
                  <td className="text-right">{currencyFormat(row.balance)}</td>
                  {isRemoveInvoiceAllowed ? (
                    <td className="text-center">
                      <>
                        {row.amount === row.balance ? (
                          <Link
                            to="/#"
                            onClick={e => {
                              e.preventDefault();
                              this.deleteInvoice(row);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="ml-2 mr-2 text-danger"
                            />
                          </Link>
                        ) : (
                          ''
                        )}
                      </>
                    </td>
                  ) : (
                    ''
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <th
                scope="row"
                colSpan={isRemoveInvoiceAllowed ? 7 : 6}
                className="text-center"
              >
                -- none --
              </th>
            </tr>
          )}
        </tbody>
      </Table>
    );
  };

  renderPoInvoiceList = () => {
    const { company } = this.props;

    return (
      <div ref={this.invList}>
        <Card className="mb-3">
          <CardHeader className="text-center">
            <strong>Invoice</strong>
          </CardHeader>
          <CardBody className="px-1 py-0 mb-3">
            {this.renderInvoiceList(company.invoices)}
          </CardBody>
        </Card>
      </div>
    );
  };

  renderPaymentList = payments => {
    if (!payments) return '';

    const { user } = this.context;
    const { role } = user;

    const roles = [
      ROLE_KDC,
      ROLE_EXECUTIVE,
      ROLE_EXECUTIVE_SECRETARY,
      ROLE_RECEIVABLE,
      ROLE_PAYABLE,
      ROLE_PURCHASING
    ];

    const isRemovePaymentAllowed = roles.indexOf(role) > -1;

    return (
      <Table striped hover responsive size="sm">
        <thead>
          <tr>
            <th>OR#</th>
            <th className="d-none d-sm-table-cell">Date</th>
            <th className="d-none d-sm-table-cell text-center">Tax</th>
            <th className="d-none d-sm-table-cell text-center">Amount</th>
            <th className="text-center">Total</th>
            {isRemovePaymentAllowed ? <th className="text-center"></th> : ''}
          </tr>
        </thead>
        <tbody>
          {payments.length > 0 ? (
            payments.map(row => {
              if (row.amount === row.excessAmount) return null;
              const trClass = this.hilitePayment(row);
              return (
                <tr
                  key={row._id}
                  className={trClass}
                  onClick={() => this.selectPayment(row)}
                >
                  <th scope="row">{row.birOrNum}</th>
                  <td className="d-none d-sm-table-cell">
                    {dateFormat(row.date)}
                  </td>
                  <td className="d-none d-sm-table-cell text-right">
                    {currencyFormat(row.tax)}
                  </td>
                  <td className="d-none d-sm-table-cell text-right">
                    {currencyFormat(row.subamount)}
                  </td>
                  <td className="text-right">{currencyFormat(row.amount)}</td>
                  {isRemovePaymentAllowed ? (
                    <td className="text-center">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.removeInvoice(row);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="ml-2 mr-2 text-danger"
                        />
                      </Link>
                    </td>
                  ) : (
                    ''
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <th
                scope="row"
                colSpan={isRemovePaymentAllowed ? 6 : 5}
                className="text-center"
              >
                -- none --
              </th>
            </tr>
          )}
        </tbody>
      </Table>
    );
  };

  renderPoPaymentList = payments => {
    return (
      <div ref={this.payList}>
        <Card className="mb-3">
          <CardHeader className="text-center">
            <strong>Payment</strong>
          </CardHeader>
          <CardBody className="px-1 py-0">
            {this.renderPaymentList(payments)}
          </CardBody>
        </Card>
      </div>
    );
  };

  renderPoModal = () => {
    const { company } = this.props;
    const { showPOModal, editPO } = this.state;

    return (
      <Modal isOpen={showPOModal} toggle={this.togglePOModal} backdrop="static">
        <ModalHeader>{editPO ? 'Edit' : 'Add'} Purchase Order</ModalHeader>
        <ModalBody>
          <PurchaseOrderForm
            close={this.togglePOModal}
            po={editPO}
            companyName={company.name}
            companyId={company._id}
            done={this.POAdded}
          />
        </ModalBody>
      </Modal>
    );
  };

  renderPoProjectModal = () => {
    const { showPOProjectModal, editPO } = this.state;

    return (
      <Modal
        isOpen={showPOProjectModal}
        toggle={this.togglePOProjectModal}
        backdrop="static"
      >
        <ModalHeader>
          {editPO ? 'Edit' : 'Add'} Project in Purchase Order
        </ModalHeader>
        <ModalBody>
          <PurchaseOrderProjectForm
            close={this.togglePOProjectModal}
            po={editPO}
            done={this.POProjectAdded}
          />
        </ModalBody>
      </Modal>
    );
  };

  renderInvoiceModal = () => {
    const { showInvoiceModal } = this.state;
    const { company } = this.props;

    const { _id, name, payables } = company;
    const { unbilledAmount } = payables;

    const companyData = { companyName: name, companyId: _id, unbilledAmount };

    return (
      <Modal
        isOpen={showInvoiceModal}
        toggle={this.toggleInvoiceModal}
        backdrop="static"
      >
        <ModalHeader>Add Invoice</ModalHeader>
        <ModalBody>
          <InvoiceAdd
            close={this.toggleInvoiceModal}
            company={companyData}
            done={this.InvoiceAdded}
          />
        </ModalBody>
      </Modal>
    );
  };

  renderPaymentModal = () => {
    const { showPaymentModal } = this.state;
    const { company } = this.props;

    return (
      <Modal
        isOpen={showPaymentModal}
        toggle={this.togglePaymentModal}
        backdrop="static"
      >
        <ModalHeader>Add Payment</ModalHeader>
        <ModalBody>
          <PaymentAdd
            close={this.togglePaymentModal}
            companyName={company.name}
            companyId={company._id}
            done={this.PaymentAdded}
            type="payables"
          />
        </ModalBody>
      </Modal>
    );
  };

  renderPaymentAddInvoiceModal = () => {
    const { company } = this.props;
    const { selectedPayment, showPaymentAddInvoiceModal } = this.state;

    const unpaidInvoices = this.filterUnpaidInvoices(company.invoices);

    return (
      <Modal
        isOpen={showPaymentAddInvoiceModal}
        toggle={this.togglePaymentAddInvoiceModal}
        backdrop="static"
      >
        <ModalHeader>Assign Invoice to Payment</ModalHeader>
        <ModalBody>
          <PaymentAddInvoice
            close={this.togglePaymentAddInvoiceModal}
            payment={selectedPayment}
            invoices={unpaidInvoices}
            done={this.PaymentAddInvoiceAdded}
            type="payables"
          />
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const { company } = this.props;

    if (!company) return this.renderLoading();

    return (
      <Row className="mb-3">
        <CardBody>
          {this.renderAddDropdownMenu()}

          {this.renderPayableSummary(company)}

          <Row className="mb-1">
            <Col md="4" className="flex-grow-1 mb-3">
              {this.renderPOListContainer()}
            </Col>

            <Col md="8">
              {this.renderPODetails()}
              {this.renderUnassignedPaymentList(company.payments)}
              {this.renderPoInvoiceList()}
              {this.renderPoPaymentList(company.payments)}
            </Col>
          </Row>
        </CardBody>

        {this.renderPoModal()}
        {this.renderInvoiceModal()}
        {this.renderPaymentModal()}
        {this.renderPaymentAddInvoiceModal()}
        {this.renderPoProjectModal()}
      </Row>
    );
  }
}

Payables.contextType = UserContext;

export default Payables;
