import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';

import SideBar from '../containers/Sidebar';

import Dashboard from './dashboard';
import Company from './company';
import CompanySummary from './company/summary';
import CompanyForm from './company/form';
import SalesOrder from './salesOrder';
import SalesOrderForm from './salesOrder/form';
import PurchaseOrder from './purchaseOrder';
import Invoice from './invoice';
import InvoiceSoAdd from './invoice/soAdd';
import InvoicePoAdd from './invoice/poAdd';
import Payment from './payment';
import PaymentAdd from './payment/add';
import Employee from './employee';
import EmployeeAdd from './employee/add';
import EmployeeChangePassword from './employee/changepwd';
import EmployeeEditProfile from './employee/editProfile';
import EmployeeSetPassword from './employee/setpwd';
import Bank from './bank';
import BankTransactions from './bank/transactions';
import WeeklySales from './reports/WeeklySales';
import UnpaidInvoice from './reports/UnpaidInvoice';
import UnbilledSO from './reports/UnbilledSO';
import ActivityWeekly from './reports/ActivityWeekly';
import Project from './project';
import ProjectSummary from './project/summary';
import AdminEmployeeUpdate from './employee/adminUpdateEmployee';

const RouteWithSidebar = ({ path, component: Comp, router }) => {
  return (
    <Route
      path={path}
      render={props => (
        <Comp
          {...props}
          sidebar={{
            hidden: router.state.hidden,
            toggleSidebar: router.toggleSidebar
          }}
        />
      )}
    />
  );
};

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: false,
      hidden: false
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const { width } = this.state;
    const { innerWidth } = window;
    if (width === false) {
      this.setState({ width: innerWidth });
      if (innerWidth < 992) {
        this.hideSidebar();
      } else {
        this.showSidebar();
      }
    }
  }

  hideSidebar() {
    this.setState({ hidden: true });
  }

  showSidebar() {
    this.setState({ hidden: false });
  }

  toggleSidebar() {
    this.setState(prevState => {
      return { hidden: !prevState.hidden };
    });
  }

  render() {
    const { match } = this.props;
    const { hidden } = this.state;

    return (
      <div className={hidden ? 'd-flex toggled' : 'd-flex'} id="wrapper">
        <SideBar
          sidebar={{
            hidden,
            toggleSidebar: this.toggleSidebar
          }}
        />
        <div id="page-content-wrapper" className={hidden ? 'hidden' : ''}>
          <Container fluid className="px-2 h-100">
            <Switch>
              <RouteWithSidebar
                path={`${match.url}/dashboard`}
                component={Dashboard}
                router={this}
              />

              <RouteWithSidebar
                path={`${match.url}/reports/activity-weekly`}
                component={ActivityWeekly}
                router={this}
              />

              <RouteWithSidebar
                path={`${match.url}/company/add`}
                component={CompanyForm}
                router={this}
              />
              <RouteWithSidebar
                path={`${match.url}/company/edit/:id`}
                component={CompanyForm}
                router={this}
              />
              <RouteWithSidebar
                path={`${match.url}/company/:id`}
                component={CompanySummary}
                router={this}
              />
              <RouteWithSidebar
                path={`${match.url}/company`}
                component={Company}
                router={this}
              />

              <RouteWithSidebar
                path={`${match.url}/project/:id`}
                component={ProjectSummary}
                router={this}
              />
              <RouteWithSidebar
                path={`${match.url}/project`}
                component={Project}
                router={this}
              />

              <RouteWithSidebar
                path={`${match.url}/bank/transactions/:bankId?`}
                component={BankTransactions}
                router={this}
              />

              <RouteWithSidebar
                path={`${match.url}/bank`}
                component={Bank}
                router={this}
              />

              <RouteWithSidebar
                path={`${match.url}/sales-order/add`}
                component={SalesOrderForm}
                router={this}
              />
              <RouteWithSidebar
                path={`${match.url}/sales-order`}
                component={SalesOrder}
                router={this}
              />

              <RouteWithSidebar
                path={`${match.url}/purchase-order`}
                component={PurchaseOrder}
                router={this}
              />
              <RouteWithSidebar
                path={`${match.url}/invoice/soAdd`}
                component={InvoiceSoAdd}
                router={this}
              />
              <RouteWithSidebar
                path={`${match.url}/invoice/poAdd`}
                component={InvoicePoAdd}
                router={this}
              />
              <RouteWithSidebar
                path={`${match.url}/invoice`}
                component={Invoice}
                router={this}
              />

              <RouteWithSidebar
                path={`${match.url}/payment/add`}
                component={PaymentAdd}
                router={this}
              />
              <RouteWithSidebar
                path={`${match.url}/payment`}
                component={Payment}
                router={this}
              />

              <RouteWithSidebar
                path={`${match.url}/employee/add`}
                component={EmployeeAdd}
                router={this}
              />
              <RouteWithSidebar
                path={`${match.url}/employee/edit-profile/:userId`}
                component={EmployeeEditProfile}
                router={this}
              />
              <RouteWithSidebar
                path={`${match.url}/employee/admin-update/:userId`}
                component={AdminEmployeeUpdate}
                router={this}
              />
              <RouteWithSidebar
                path={`${match.url}/employee/change-password`}
                component={EmployeeChangePassword}
                router={this}
              />
              <RouteWithSidebar
                path={`${match.url}/employee/set-password/:userId`}
                component={EmployeeSetPassword}
                router={this}
              />
              <RouteWithSidebar
                path={`${match.url}/employee`}
                component={Employee}
                router={this}
              />

              <RouteWithSidebar
                path={`${match.url}/reports/weekly-sales`}
                component={WeeklySales}
                router={this}
              />
              <RouteWithSidebar
                path={`${match.url}/reports/unpaid-invoice`}
                component={UnpaidInvoice}
                router={this}
              />
              <RouteWithSidebar
                path={`${match.url}/reports/unbilled-so`}
                component={UnbilledSO}
                router={this}
              />
            </Switch>
          </Container>
        </div>
        <div id="update-notification" className="toast update-notification">
          <div className="toast-header">New Update available!</div>
          <div className="toast-body">
            To get the update, please close this tab and open a new one.
            Refresh/reload is not enough.
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Routes);
