import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form, Field } from 'formik';
import Project from '../project/projects';
import { UserContext } from '../../context/user';

import API from '../../api';

class PurchaseOrderProjectForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: []
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { po } = this.props;

    let poProjects = [];
    if (po && po.projects && po.projects instanceof Array) {
      poProjects = po.projects;
    }

    this.setState({ projects: poProjects });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setInitialValues = () => {
    return {
      id: '',
      name: '',
      amount: ''
    };
  };

  renderPoProjectList = () => {
    const { projects } = this.state;

    const projectList = [];
    projects.forEach((v, k) => {
      const key = k + new Date().getTime();
      projectList.push(
        <div key={key}>
          <Row>
            <Col sm={7} style={{ paddingTop: '7px' }}>
              {v.name}
            </Col>
            <Col sm={3} style={{ paddingTop: '7px' }}>
              {v.amount}
            </Col>
            <Col sm={2}>
              <Button
                type="button"
                color="danger"
                onClick={() => {
                  this.removeProjectToList(k);
                }}
              >
                <FontAwesomeIcon className="icon" icon="minus" />
              </Button>
            </Col>
          </Row>
          <hr />
        </div>
      );
    });

    if (!projectList.length) {
      projectList.push(
        <div key={new Date().getTime()} style={{ textAlign: 'center' }}>
          Project list is empty <hr />
        </div>
      );
    }

    return <div>{projectList}</div>;
  };

  addProjectToList = (resetForm, formValues) => {
    const { po } = this.props;
    const { projects } = this.state;

    let updateProjectList = [formValues];
    if (projects && projects instanceof Array) {
      updateProjectList = [...projects, formValues];
    }

    let totalProjectAmount = 0;
    updateProjectList.forEach(v => {
      if (!Number.isNaN(v.amount)) {
        totalProjectAmount += v.amount;
      }
    });

    if (totalProjectAmount > po.poAmount) {
      alert('Total projects amount exceeded PO amount');
      return;
    }

    updateProjectList.forEach(v => {
      v.id = v.id ? v.id : v.projectId;
      v.name = v.name ? v.name : v.projectName;
    });

    this.setState({ projects: updateProjectList });
    resetForm();
  };

  removeProjectToList = index => {
    const { projects } = this.state;

    projects.splice(index, 1);

    this.setState({ projects });
  };

  composePurchaseOrderProjectFormFields = (
    resetForm,
    values,
    errors,
    touched,
    isSubmitting
  ) => {
    return (
      <div className="col-xs-12 m-a">
        {this.renderPoProjectList()}

        {errors.submit && (
          <Row form>
            <Col>
              <p className="text-danger">{errors.submit}</p>
            </Col>
          </Row>
        )}

        <Row form style={{ marginBottom: '25px' }}>
          <Col sm={5}>
            <FormGroup>
              <Label for="name">Projects *</Label>
              <Field
                name="name"
                component={Project}
                disabled={isSubmitting}
                invalid={errors.name && touched.name}
              />
              {errors.name && touched.name ? (
                <div className="text-danger">{errors.name}</div>
              ) : null}
              <Input tag={Field} type="hidden" name="id" />
            </FormGroup>
          </Col>
          <Col sm={5}>
            <FormGroup>
              <Label for="amount">Amount *</Label>
              <Input
                tag={Field}
                type="number"
                min="0"
                step="0.01"
                name="amount"
                disabled={isSubmitting}
                invalid={errors.amount && touched.amount}
              />
              <FormFeedback>{errors.amount}</FormFeedback>
            </FormGroup>
          </Col>
          <Col sm={2}>
            <FormGroup>
              <Label
                for="addProjectbtn"
                style={{
                  width: '100%',
                  display: 'inline-block',
                  marginBottom: '.5rem'
                }}
              >
                &nbsp;
              </Label>
              <Button
                type="button"
                color="success"
                onClick={() => {
                  this.addProjectToList(resetForm, values);
                }}
              >
                <FontAwesomeIcon className="icon" icon="plus" />
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  };

  composePurchaseOrderProjectFormButtons = (errors, touched, isSubmitting) => {
    const { close, po, addButtonLabel } = this.props;

    return (
      <div className="col-sm-12">
        <FormGroup row>
          <Col className="text-right">
            <Button
              type="submit"
              color="primary"
              className="mr-1"
              disabled={isSubmitting}
            >
              {addButtonLabel || (po ? 'Save' : 'Add')}
            </Button>
            <Button type="button" color="danger" onClick={close}>
              Cancel
            </Button>
          </Col>
        </FormGroup>
      </div>
    );
  };

  onSubmit = (values, { setSubmitting, setStatus, setErrors }) => {
    const { po, done, close } = this.props;
    const { projects } = this.state;
    if (!po) {
      setStatus({ success: false });
      setSubmitting(false);
      return {};
    }

    setSubmitting(false);

    return API.PurchaseOrderUpdateProject({ _id: po._id, projects })
      .then(() => {
        if (!this.mounted) return;
        setSubmitting(false);
        close();
        done();
      })
      .catch(e => {
        console.log('PurchaseOrderProjectForm', e);
        setErrors({ submit: e.message });
        setStatus({ success: false });
        setSubmitting(false);
      });
  };

  render() {
    return (
      <Formik onSubmit={this.onSubmit} initialValues={this.setInitialValues()}>
        {props => {
          const { resetForm, values, errors, touched, isSubmitting } = props;

          return (
            <Form>
              {this.composePurchaseOrderProjectFormFields(
                resetForm,
                values,
                errors,
                touched,
                isSubmitting
              )}

              {this.composePurchaseOrderProjectFormButtons(
                errors,
                touched,
                isSubmitting
              )}
            </Form>
          );
        }}
      </Formik>
    );
  }
}

PurchaseOrderProjectForm.contextType = UserContext;

export default PurchaseOrderProjectForm;
