import React, { Component } from 'react';
import Select from 'react-select';
import API from '../../api';

export default class Purchasing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameOptions: []
    };
  }

  async componentDidMount() {
    this.mounted = true;
    const purchasingOfficers = await this.nameOptions('*ALL*');
    this.setState({ nameOptions: purchasingOfficers });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  nameOptions = async user => {
    return API.PurchasingSearch(user)
      .then(list => {
        if (!this.mounted) return [];

        return list.map(a => {
          return { value: a._id, label: a.name };
        });
      })
      .catch(() => Promise.resolve([]));
  };

  onNameSelect = user => {
    const { form } = this.props;
    const { values } = form;
    values.purchasingOfficerName = user.label;
    values.purchasingOfficerId = user.value;
    form.setValues(values);
  };

  noOptionsMessage = ({ inputValue }) => {
    if (inputValue && inputValue.length < 3) {
      return 'Enter at least 3 letters';
    }
    return 'No results found';
  };

  render() {
    const { nameOptions } = this.state;
    const { form, name } = this.props;
    const { values } = form;
    const { purchasingOfficerId, purchasingOfficerName } = values;

    const value = {
      value: purchasingOfficerId,
      label: purchasingOfficerName
    };

    return (
      <Select
        placeholder="type name"
        options={nameOptions}
        onChange={this.onNameSelect}
        value={value}
        name={name}
      />
    );
  }
}
