import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';

export default class DateRange extends Component {
  constructor(props) {
    super(props);
    const { startDate, endDate } = this.props;
    this.state = {
      startDate,
      endDate
    };
  }

  render() {
    const { startDate, endDate } = this.state;
    const { setDateRange } = this.props;
    return (
      <Form inline className="mb-2">
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <Label for="startDate" className="mr-sm-2">
            Start
          </Label>
          <Input
            type="date"
            name="startDate"
            id="startDate"
            bsSize="sm"
            max={endDate}
            value={startDate}
            onChange={e => this.setState({ startDate: e.currentTarget.value })}
          />
        </FormGroup>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <Label for="endDate" className="mr-sm-2">
            End
          </Label>
          <Input
            type="date"
            name="endDate"
            id="endDate"
            bsSize="sm"
            min={startDate}
            value={endDate}
            onChange={e => this.setState({ endDate: e.currentTarget.value })}
          />
        </FormGroup>
        <Button
          color="primary"
          size="sm"
          onClick={() => setDateRange(startDate, endDate)}
        >
          Set
        </Button>
      </Form>
    );
  }
}
