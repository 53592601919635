import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Alert } from 'reactstrap';

import { UserContext } from '../context/user';
import '../styles/app.css';
import AppLocale from '../langProvider';

import MainApp from '../routes/index';
import SignIn from './SignIn';
import SignOut from './SignOut';

const Error404 = () => <h2>Error404</h2>;

const RestrictedRoute = ({ component: Comp, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authUser ? (
        <Comp {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class App extends Component {
  componentDidMount() {
    const { initURL, history, setInitUrl } = this.props;
    if (initURL === '') {
      setInitUrl(history.location.pathname);
    }
  }

  render() {
    const { match, location, initURL, locale } = this.props;
    const { user } = this.context;

    let authUser = false;
    if (user && user.role && user.role !== 'guest') {
      authUser = true;
    }

    if (location.pathname === '/') {
      if (!authUser) {
        return <Redirect to="/signin" />;
      }
      if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return <Redirect to="/app/dashboard" />;
      }
      return <Redirect to={initURL} />;
    }
    if (authUser && location.pathname === '/signin') {
      if (user.role === 'admin') {
        return <Redirect to="/app/company" />;
      }
      return <Redirect to="/app/dashboard" />;
    }

    const currentAppLocale = AppLocale[locale];
    return (
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <div className="app-main">
          <Switch>
            <RestrictedRoute
              path={`${match.url}app`}
              authUser={authUser}
              component={MainApp}
            />
            <Route path="/signin" component={SignIn} />
            <Route path="/signout" component={SignOut} />
            <Route component={Error404} />
          </Switch>
          {process.env.REACT_APP_TEST ? (
            <div id="test-site">
              <Alert color="danger">
                <strong>THIS WEBSITE IS FOR TESTING ONLY</strong>
              </Alert>
            </div>
          ) : null}
        </div>
      </IntlProvider>
    );
  }
}

App.contextType = UserContext;

export default App;
