import React, { Component } from 'react';
import { CardBody, Row, Col } from 'reactstrap';

import {
  camelCaseToSpacesLowerCase,
  titleCase
} from '../../util/processString';
import { customCurrencyFormat } from '../../util/currency';
import { dateTimeFormat } from '../../util/date';

class CompanyHistory extends Component {
  processLineBreaks = text => {
    let keyIndex = '';
    let index = '';
    return text.split('\n').map((i, key) => {
      index += 1;
      keyIndex = key + index;

      if (!i) {
        return (
          <div key={keyIndex}>
            <br />
          </div>
        );
      }

      return <div key={keyIndex}>{i}</div>;
    });
  };

  composeChangesEntries = (entry, index, multiple) => {
    const { _id, username, historyFor, date } = entry;
    const { changes } = entry.data;
    const { text, field, oldValue, newValue, changes: fieldChanges } = changes[
      index
    ];

    let ccField = camelCaseToSpacesLowerCase(field || '');

    let content = '';
    let secondaryContent = '';
    if (text) {
      content = text;
    } else if (fieldChanges) {
      console.log(ccField);
      if (ccField.indexOf('receivables.') > -1) {
        ccField = ccField.replace('receivables.', '( receivables )');
      } else if (ccField.indexOf('payables.') > -1) {
        ccField = ccField.replace('payables.', '( payables )');
      }

      content = `update ${historyFor} ${ccField} `;

      fieldChanges.forEach(value => {
        if (secondaryContent) {
          secondaryContent += '\n';
        }

        const { indexName, changes: arrayChanges } = value;
        if (arrayChanges instanceof Array) {
          secondaryContent += `\n${indexName}:`;

          arrayChanges.forEach(v => {
            const { field: f, oldValue: ov, newValue: nv } = v;
            secondaryContent += `\n- ${titleCase(f)}: change from '${ov ||
              'NONE'}' to '${nv}'`;
          });
        } else if (arrayChanges.text) {
          secondaryContent += `\n- ${arrayChanges.text}`;
        }
      });
    } else {
      const numberFormatArray = [
        'so amount',
        'po amount',
        'paid amount',
        'invoice amount',
        'invoice balance',
        'unbilled amount',
        'receivables.amount',
        'receivables.paid amount',
        'receivables.invoice amount',
        'receivables.invoice balance',
        'receivables.unbilled amount',
        'payables.amount',
        'payables.paid amount',
        'payables.invoice amount',
        'payables.invoice balance',
        'payables.unbilled amount'
      ];

      const numberFormat = numberFormatArray.indexOf(ccField) > -1 ? 1 : 0;
      const ov = numberFormat ? `P${customCurrencyFormat(oldValue)}` : oldValue;
      const nv = numberFormat ? `P${customCurrencyFormat(newValue)}` : newValue;

      if (ccField.indexOf('receivables.') > -1) {
        ccField = ccField.replace('receivables.', '( receivables ) ');
      } else if (ccField.indexOf('payables.') > -1) {
        ccField = ccField.replace('payables.', '( payables ) ');
      }

      content = `changed ${historyFor} ${ccField} from ${ov ||
        'NONE'} to ${nv}`;
    }

    const key = multiple ? `${_id}_${index}` : _id;

    return (
      <div
        key={key}
        className="col-xs-12"
        style={{
          padding: '0px 10px',
          width: '100%'
        }}
      >
        <strong>{username}</strong> {content}
        {secondaryContent ? this.processLineBreaks(secondaryContent) : ''}
        <br />
        <small>{dateTimeFormat(date)}</small>
        <hr />
      </div>
    );
  };

  renderNoHistoryContent = () => {
    return <div className="row"> No history record found. </div>;
  };

  renderHistory = () => {
    const { companyHistory } = this.props;

    if (!companyHistory) {
      return this.renderNoHistoryContent();
    }

    const historyList = companyHistory.list;
    if (!(historyList && historyList.length)) {
      return this.renderNoHistoryContent();
    }

    const list = historyList.map(entry => {
      const { changes } = entry.data;
      const changesLength = changes.length;
      if (!changesLength) {
        return '';
      }

      if (changesLength > 1) {
        const content = [];
        changes.forEach((value, key) => {
          content.push(this.composeChangesEntries(entry, key, 1));
        });

        return content;
      }

      return this.composeChangesEntries(entry, 0);
    });

    return <div className="row">{list}</div>;
  };

  render() {
    return (
      <Row className="mb-3">
        <CardBody>
          <Row>
            <Col xs={12}>{this.renderHistory()}</Col>
          </Row>
        </CardBody>
      </Row>
    );
  }
}

export default CompanyHistory;
