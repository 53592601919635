import React from 'react';
import Select from 'react-select';

const SingleSelect = ({ options, field, form, disabled, onChange }) => (
  <Select
    options={options}
    name={field.name}
    value={options ? options.find(option => option.value === field.value) : ''}
    onChange={option => {
      form.setFieldValue(field.name, option.value);
      if (onChange) onChange(option);
    }}
    onBlur={field.onBlur}
    isDisabled={disabled}
  />
);
export default SingleSelect;
