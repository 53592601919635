import React, { Component } from 'react';
import Select from 'react-select';

export default class SalesAgent extends Component {
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  nameOptions = user => {
    if (!this.mounted) return [];

    const { form } = this.props;
    const { companySalesAgentIds, companySalesAgentNames } = form.values;

    if (!(companySalesAgentNames && companySalesAgentNames.length)) return [];

    const assignedList = [];
    companySalesAgentNames.map((v, i) => {
      if (!user) {
        assignedList.push({ value: companySalesAgentIds[i], label: v });
      } else if (v.toLowerCase().includes(user.toLowerCase())) {
        assignedList.push({ value: companySalesAgentIds[i], label: v });
      }
      return null;
    });

    return assignedList;
  };

  onNameSelect = user => {
    const { form } = this.props;
    const { values } = form;
    values.salesAgentUserName = user.label;
    values.salesAgentUserId = user.value;
    form.setValues(values);
  };

  render() {
    const { form, name } = this.props;
    const { values } = form;
    const {
      salesAgentUserId,
      salesAgentUserName,
      companySalesAgentNames
    } = values;

    let value = {
      value: salesAgentUserId,
      label: salesAgentUserName
    };

    let nameOptions = [];
    if (companySalesAgentNames && companySalesAgentNames.length) {
      nameOptions = this.nameOptions();

      let found = 0;
      nameOptions.forEach(salesAgent => {
        if (salesAgent.value === salesAgentUserId) found = 1;
      });

      if (!found) {
        value = {
          value: '',
          label: ''
        };
      }
    }

    return (
      <Select
        placeholder="type name"
        options={nameOptions}
        onChange={this.onNameSelect}
        value={value}
        name={name}
      />
    );
  }
}
