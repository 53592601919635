import React, { Component } from 'react';
import {
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardBody
} from 'reactstrap';
import classnames from 'classnames';
import Header from '../../containers/Header';
import Loading from '../../components/Loading';
import API from '../../api';
import Info from './info';
import Receivables from './receivables';
import Payables from './payables';
import CompanyHistory from './history';
import { DEFAULT_PER_PAGE } from '../../constants/others';

class Summary extends Component {
  tabsInfo = [
    {
      tabKey: 'info',
      tabLabel: 'Info'
    },
    {
      tabKey: 'receivables',
      tabLabel: 'Receivables',
      hidden: [
        'payable',
        'purchasing',
        'documentController'
        // 'executiveSecretary'
      ]
    },
    {
      tabKey: 'payables',
      tabLabel: 'Payables',
      hidden: ['receivable']
    },
    {
      tabKey: 'history',
      tabLabel: 'History'
    }
  ];

  constructor(props) {
    super(props);

    const { match, user } = props;
    const { params } = match;

    const dateGetTime = new Date().getTime();

    let activeTab = 'info';
    if (user.role === 'receivable') {
      activeTab = 'receivables';
    } else if (
      user.role === 'payable' ||
      user.role === 'purchasing'
      // || user.role === 'documentController'
    ) {
      activeTab = 'payables';
    }

    this.state = {
      _id: params.id,
      company: {},
      companyHistory: {},
      loading: true,
      errorMessage: null,
      activeTab,
      page: 1,
      perPage: DEFAULT_PER_PAGE,
      renderReceivables: dateGetTime,
      renderPayables: dateGetTime
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    const { activeTab } = this.state;

    if (activeTab === 'info') {
      this.getCompany();
    } else if (activeTab === 'receivables') {
      this.getCompanyReceivablesSummary();
    } else if (activeTab === 'payables') {
      this.getCompanyPayablesSummary();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getCompany() {
    this.setState({ loading: true });

    const { _id } = this.state;
    API.Company(_id)
      .then(company => {
        if (!this.mounted) return;
        this.setState({ company, loading: false });
      })
      .catch(e => {
        console.log(e);
        if (!this.mounted) return;
        this.setState({ errorMessage: e.message, loading: false });
      });
  }

  getCompanyReceivablesSummary() {
    this.setState({ loading: true });

    const { _id } = this.state;
    API.CompanyReceivableSummary(_id)
      .then(company => {
        if (!this.mounted) return;
        this.setState({
          company,
          renderReceivables: new Date().getTime(),
          loading: false
        });
      })
      .catch(e => {
        console.log(e);
        if (!this.mounted) return;
        this.setState({ errorMessage: e.message, loading: false });
      });
  }

  getCompanyPayablesSummary() {
    this.setState({ loading: true });

    const { _id } = this.state;
    API.CompanyPayableSummary(_id)
      .then(company => {
        if (!this.mounted) return;
        this.setState({
          company,
          renderPayables: new Date().getTime(),
          loading: false
        });
      })
      .catch(e => {
        console.log(e);
        if (!this.mounted) return;
        this.setState({ errorMessage: e.message, loading: false });
      });
  }

  getCompanyRelatedHistory() {
    this.setState({ loading: true });

    const { _id, page, perPage } = this.state;
    API.CompanyHistory(_id, page, perPage)
      .then(companyHistory => {
        if (!this.mounted) return;
        this.setState({
          companyHistory,
          loading: false
        });
      })
      .catch(e => {
        console.log(e);
        if (!this.mounted) return;
        this.setState({ errorMessage: e.message, loading: false });
      });
  }

  renderLoading = () => {
    return (
      <div className="mt-5">
        <Loading className="h-100" />
      </div>
    );
  };

  renderError = errorMessage => {
    return <div className="mt-5 text-danger">Error: {errorMessage}</div>;
  };

  renderTabMenu = () => {
    const { activeTab } = this.state;
    const {
      user: { role }
    } = this.props;

    const menus = this.tabsInfo.map(row => {
      if (row.hidden && row.hidden.indexOf(role) !== -1) {
        return null;
      }

      return (
        <NavItem key={row.tabKey}>
          <NavLink
            className={classnames({ active: activeTab === row.tabKey })}
            onClick={() => this.toggle(row.tabKey)}
          >
            {row.tabLabel}
          </NavLink>
        </NavItem>
      );
    });

    return <Nav tabs>{menus}</Nav>;
  };

  renderInfo = () => {
    const { activeTab, company, loading, errorMessage } = this.state;
    const { history } = this.props;

    if (loading || activeTab !== 'info') return this.renderLoading();
    if (errorMessage) return this.renderError(errorMessage);

    return <Info company={company} history={history} />;
  };

  renderReceivables = () => {
    const {
      activeTab,
      company,
      loading,
      errorMessage,
      renderReceivables
    } = this.state;
    const { history } = this.props;

    if (loading || activeTab !== 'receivables') return this.renderLoading();
    if (errorMessage) return this.renderError(errorMessage);

    return (
      <Receivables
        company={company}
        history={history}
        renderReceivables={renderReceivables}
        getCompanyReceivablesSummary={() => {
          this.getCompanyReceivablesSummary();
        }}
      />
    );
  };

  renderPayables = () => {
    const {
      activeTab,
      company,
      loading,
      errorMessage,
      renderPayables
    } = this.state;
    const { history } = this.props;

    if (loading || activeTab !== 'payables') return this.renderLoading();
    if (errorMessage) return this.renderError(errorMessage);

    return (
      <Payables
        company={company}
        history={history}
        renderPayables={renderPayables}
        getCompanyPayablesSummary={() => {
          this.getCompanyPayablesSummary();
        }}
      />
    );
  };

  renderHistory = () => {
    const { activeTab, companyHistory, loading, errorMessage } = this.state;
    const { history } = this.props;

    if (loading || activeTab !== 'history') return this.renderLoading();
    if (errorMessage) return this.renderError(errorMessage);

    return <CompanyHistory companyHistory={companyHistory} history={history} />;
  };

  rightNav() {
    const { history } = this.props;
    return (
      <div>
        <Button color="danger" size="sm" onClick={() => history.goBack()}>
          Back
        </Button>
      </div>
    );
  }

  renderTabContent = () => {
    const { activeTab } = this.state;

    const content = this.tabsInfo.map(row => {
      return (
        <TabPane key={row.tabKey} tabId={row.tabKey}>
          <Row className="mb-3">
            <Col sm={12}>
              <Card style={{ borderTop: '0px' }}>
                <CardBody>{this[`render${row.tabLabel}`]()}</CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      );
    });

    return <TabContent activeTab={activeTab}>{content}</TabContent>;
  };

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      if (tab === 'info') {
        this.getCompany();
      } else if (tab === 'receivables') {
        this.getCompanyReceivablesSummary();
      } else if (tab === 'payables') {
        this.getCompanyPayablesSummary();
      } else if (tab === 'history') {
        this.getCompanyRelatedHistory();
      }

      this.setState({ activeTab: tab, loading: true });
    }
  }

  render() {
    const { company } = this.state;
    const { sidebar } = this.props;

    return (
      <div>
        <Header
          sidebar={sidebar}
          title={company && company.name ? company.name : ''}
          mode="buttons"
          rightNav={this.rightNav()}
        />

        {this.renderTabMenu()}
        {this.renderTabContent()}
      </div>
    );
  }
}

export default Summary;
