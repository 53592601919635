import React from 'react';
import Header from '../../containers/Header';
import Activity from '../activity';

const DashboardDefault = ({ sidebar }) => {
  return (
    <div>
      <Header sidebar={sidebar} title="Dashboard" />

      <div className="row">
        <div className="col-sm-6">
          <Activity />
        </div>
      </div>
    </div>
  );
};

export default DashboardDefault;
