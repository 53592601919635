export const ROLE_ALL = 'all';
export const ROLE_ONLYME = 'onlyme';
export const ROLE_PAYABLE = 'payable';
export const ROLE_RECEIVABLE = 'receivable';
export const ROLE_COLLECTOR = 'collector';
export const ROLE_DOCUMENT_CONTROLLER = 'documentController';
export const ROLE_ENGINEERING = 'engineering';
export const ROLE_ENGINEERING_SECRETARY = 'engineeringSecretary';
export const ROLE_EXECUTIVE = 'executive';
export const ROLE_EXECUTIVE_SECRETARY = 'executiveSecretary';
export const ROLE_KDC = 'kdc';
export const ROLE_HR = 'hr';
export const ROLE_MARKETING = 'marketing';
export const ROLE_MARKETING_SECRETARY = 'marketingSecretary';
export const ROLE_MARKETING_MANAGER = 'marketingManager';
export const ROLE_OFFICE_ADMIN = 'admin';
export const ROLE_OFFICE_ADMIN_STAFF = 'adminStaff';
export const ROLE_PURCHASING = 'purchasing';
export const ROLE_SALES = 'sales';

export const ROLE_ALL_LABEL = 'All';
export const ROLE_ONLYME_LABEL = 'Only Me';
export const ROLE_PAYABLE_LABEL = 'Accounts Payable';
export const ROLE_RECEIVABLE_LABEL = 'Accounts Receivable';
export const ROLE_COLLECTOR_LABEL = 'Collector';
export const ROLE_DOCUMENT_CONTROLLER_LABEL = 'Document Controller';
export const ROLE_ENGINEERING_LABEL = 'Engineering';
export const ROLE_ENGINEERING_SECRETARY_LABEL = 'Engineering Secretary';
export const ROLE_EXECUTIVE_LABEL = 'Executive';
export const ROLE_EXECUTIVE_SECRETARY_LABEL = 'Executive Secretary';
export const ROLE_KDC_LABEL = 'KDC';
export const ROLE_HR_LABEL = 'HR';
export const ROLE_MARKETING_LABEL = 'Marketing';
export const ROLE_MARKETING_SECRETARY_LABEL = 'Marketing Secretary';
export const ROLE_MARKETING_MANAGER_LABEL = 'Marketing Manager';
export const ROLE_OFFICE_ADMIN_LABEL = 'Office Admin';
export const ROLE_OFFICE_ADMIN_STAFF_LABEL = 'Office Admin Staff';
export const ROLE_PURCHASING_LABEL = 'Purchasing';
export const ROLE_SALES_LABEL = 'Sales';

export const getRoleProperNames = {
  [`${ROLE_PAYABLE}`]: `${ROLE_PAYABLE_LABEL}`,
  [`${ROLE_RECEIVABLE}`]: `${ROLE_RECEIVABLE_LABEL}`,
  [`${ROLE_COLLECTOR}`]: `${ROLE_COLLECTOR_LABEL}`,
  [`${ROLE_DOCUMENT_CONTROLLER}`]: `${ROLE_DOCUMENT_CONTROLLER_LABEL}`,
  [`${ROLE_ENGINEERING}`]: `${ROLE_ENGINEERING_LABEL}`,
  [`${ROLE_ENGINEERING_SECRETARY}`]: `${ROLE_ENGINEERING_SECRETARY_LABEL}`,
  [`${ROLE_EXECUTIVE}`]: `${ROLE_EXECUTIVE_LABEL}`,
  [`${ROLE_EXECUTIVE_SECRETARY}`]: `${ROLE_EXECUTIVE_SECRETARY_LABEL}`,
  [`${ROLE_KDC}`]: `${ROLE_KDC_LABEL}`,
  [`${ROLE_HR}`]: `${ROLE_HR_LABEL}`,
  [`${ROLE_MARKETING}`]: `${ROLE_MARKETING_LABEL}`,
  [`${ROLE_MARKETING_SECRETARY}`]: `${ROLE_MARKETING_SECRETARY_LABEL}`,
  [`${ROLE_MARKETING_MANAGER}`]: `${ROLE_MARKETING_MANAGER_LABEL}`,
  [`${ROLE_OFFICE_ADMIN}`]: `${ROLE_OFFICE_ADMIN_LABEL}`,
  [`${ROLE_OFFICE_ADMIN_STAFF}`]: `${ROLE_OFFICE_ADMIN_STAFF_LABEL}`,
  [`${ROLE_PURCHASING}`]: `${ROLE_PURCHASING_LABEL}`,
  [`${ROLE_MARKETING_SECRETARY}`]: `${ROLE_MARKETING_SECRETARY_LABEL}`,
  [`${ROLE_SALES}`]: `${ROLE_SALES_LABEL}`
};

export const VIRTUAL_ROLES = [
  { value: ROLE_ALL, label: ROLE_ALL_LABEL },
  { value: ROLE_ONLYME, label: ROLE_ONLYME_LABEL }
];

export const ACTUAL_ROLES = [
  { value: ROLE_PAYABLE, label: ROLE_PAYABLE_LABEL },
  { value: ROLE_RECEIVABLE, label: ROLE_RECEIVABLE_LABEL },
  { value: ROLE_COLLECTOR, label: ROLE_COLLECTOR_LABEL },
  { value: ROLE_DOCUMENT_CONTROLLER, label: ROLE_DOCUMENT_CONTROLLER_LABEL },
  { value: ROLE_ENGINEERING, label: ROLE_ENGINEERING_LABEL },
  {
    value: ROLE_ENGINEERING_SECRETARY,
    label: ROLE_ENGINEERING_SECRETARY_LABEL
  },
  { value: ROLE_EXECUTIVE, label: ROLE_EXECUTIVE_LABEL },
  { value: ROLE_EXECUTIVE_SECRETARY, label: ROLE_EXECUTIVE_SECRETARY_LABEL },
  { value: ROLE_KDC, label: ROLE_KDC_LABEL },
  { value: ROLE_HR, label: ROLE_HR_LABEL },
  { value: ROLE_MARKETING, label: ROLE_MARKETING_LABEL },
  { value: ROLE_MARKETING_SECRETARY, label: ROLE_MARKETING_SECRETARY_LABEL },
  { value: ROLE_MARKETING_MANAGER, label: ROLE_MARKETING_MANAGER_LABEL },
  { value: ROLE_OFFICE_ADMIN, label: ROLE_OFFICE_ADMIN_LABEL },
  { value: ROLE_OFFICE_ADMIN_STAFF, label: ROLE_OFFICE_ADMIN_STAFF_LABEL },
  { value: ROLE_PURCHASING, label: ROLE_PURCHASING_LABEL },
  { value: ROLE_SALES, label: ROLE_SALES_LABEL }
];

const ALL_ROLES = [...VIRTUAL_ROLES, ...ACTUAL_ROLES];

export const handleRolesSelection = role => {
  let selectedRoles = role;
  const roleLength = role.length;
  if (roleLength > 1) {
    const virtualIndex = roleLength - 1;
    if (role[virtualIndex] === VIRTUAL_ROLES[0]) {
      selectedRoles = [VIRTUAL_ROLES[0]];
    } else if (role[virtualIndex] === VIRTUAL_ROLES[1]) {
      selectedRoles = [VIRTUAL_ROLES[1]];
    } else {
      selectedRoles = selectedRoles.filter(
        item => item !== VIRTUAL_ROLES[0] && item !== VIRTUAL_ROLES[1]
      );
    }
  }

  return selectedRoles;
};

export default ALL_ROLES;
