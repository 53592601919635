import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faFile,
  faFileAlt,
  faSignOutAlt,
  faBuilding,
  faShoppingCart,
  faFileInvoiceDollar,
  faReceipt,
  faUser,
  faStepBackward,
  faStepForward,
  faPlus,
  faMinus,
  faChevronLeft,
  faChevronRight,
  faUserCircle,
  faCaretDown,
  faCaretUp,
  faList,
  faBars,
  faPiggyBank,
  faMoneyCheckAlt,
  faMoneyBillAlt,
  faFilter,
  faTasks,
  faAngleLeft,
  faAngleRight,
  faClipboardList
} from '@fortawesome/free-solid-svg-icons';
import { GlobalHistory } from './util/history';
import { UserContext, initUser } from './context/user';
import App from './containers/App';
import Loading from './components/Loading';
import API from './api';

class BootApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: initUser,
      initURL: '',
      loading: true
    };
    this.cookies = new Cookies();

    // FontAwesomeIcon
    library.add(
      faHome,
      faFile,
      faFileAlt,
      faSignOutAlt,
      faBuilding,
      faShoppingCart,
      faFileInvoiceDollar,
      faReceipt,
      faUser,
      faStepBackward,
      faStepForward,
      faPlus,
      faMinus,
      faChevronLeft,
      faChevronRight,
      faUserCircle,
      faCaretDown,
      faCaretUp,
      faList,
      faBars,
      faPiggyBank,
      faMoneyCheckAlt,
      faMoneyBillAlt,
      faFilter,
      faTasks,
      faAngleLeft,
      faAngleRight,
      faClipboardList
    );
  }

  async componentDidMount() {
    const loggedin = this.cookies.get(process.env.REACT_APP_COOKIE);
    if (!loggedin) {
      this.setState({ loading: false });
      return;
    }

    const user = localStorage.getItem('user');
    if (user) {
      this.setState({ user: JSON.parse(user), loading: false });
      return;
    }

    // show error in UI
    this.cookies.remove(process.env.REACT_APP_COOKIE);
    this.setState({ loading: false });
    console.log('Invalid cookie');
  }

  setInitUrl = initURL => {
    this.setState({ initURL });
  };

  login = async ({ username, password }) => {
    try {
      const user = await API.Login({ username, password });
      localStorage.setItem('user', JSON.stringify(user));
      this.setState({ user });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(new Error('Unable to signin'));
    }
  };

  logout = () => {
    return API.Logout()
      .then(() => this.handleLogout())
      .catch(() => this.handleLogout());
  };

  handleLogout() {
    this.cookies.remove(process.env.REACT_APP_COOKIE);
    this.setState({ user: initUser });
  }

  render() {
    const { loading, user, initURL } = this.state;
    if (loading) {
      return <Loading />;
    }

    return (
      <UserContext.Provider
        value={{
          user,
          login: this.login,
          logout: this.logout
        }}
      >
        <Router>
          <div>
            <GlobalHistory />
            <Switch>
              <Route
                path="/"
                component={props => (
                  <App
                    {...props}
                    initURL={initURL}
                    setInitUrl={this.setInitUrl}
                    locale="en"
                  />
                )}
              />
            </Switch>
          </div>
        </Router>
      </UserContext.Provider>
    );
  }
}

export default BootApp;
