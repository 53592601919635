import React, { Component } from 'react';
import { UserContext } from '../../context/user';
import DashboardDefault from './dashboard.default';
import DashboardExecutive from './dashboard.executive';
import DashboardSales from './dashboard.sales';

class Dashboard extends Component {
  render() {
    const { user } = this.context;
    let company = null;
    switch (user.role) {
      case 'kdc':
      case 'executive':
        company = <DashboardExecutive {...this.props} />;
        break;
      case 'sales':
        company = <DashboardSales {...this.props} />;
        break;
      default:
        company = <DashboardDefault {...this.props} />;
        break;
    }

    return company;
  }
}

Dashboard.contextType = UserContext;

export default Dashboard;
