import React, { Component } from 'react';
import { Card, Table, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Header from '../../containers/Header';
import Pagination from '../../components/Pagination';
import RowsPerPage from '../../components/RowsPerPage';
import currencyFormat from '../../util/currency';
import API from '../../api';
import { DEFAULT_PER_PAGE } from '../../constants/others';

class PurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      page: 1,
      pages: 1,
      perPage: DEFAULT_PER_PAGE,
      loading: true
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { page, perPage } = this.state;
    let savedPerPage = localStorage.getItem('PurchaseOrder-perPage');
    if (savedPerPage) {
      savedPerPage = parseInt(savedPerPage, 10);
      this.setState({ perPage: savedPerPage });
      this.getList(page, savedPerPage);
    } else {
      this.getList(page, perPage);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getList(page, perPage) {
    API.PurchaseOrders(page, perPage)
      .then(res => {
        if (!this.mounted) return;
        this.setState({ ...res, loading: false });
      })
      .catch(err => {
        console.log(err);
        if (!this.mounted) return;
        this.setState({ loading: false });
      });
  }

  setCurrentPage = curPage => {
    const { page, perPage } = this.state;
    if (curPage === page) {
      return;
    }
    this.setState({ page: curPage, loading: true }, () =>
      this.getList(curPage, perPage)
    );
  };

  setRowsPerPage = rowsPerPage => {
    const { page, perPage } = this.state;
    if (rowsPerPage === perPage) {
      return;
    }

    localStorage.setItem('PurchaseOrder-perPage', rowsPerPage);
    this.setState({ page: 1, perPage: rowsPerPage, loading: true }, () =>
      this.getList(page, rowsPerPage)
    );
  };

  renderLoading = () => {
    return (
      <tr key="spinner">
        <td colSpan={6} className="text-center">
          <span className="spinner-border" role="status" aria-hidden="true" />
        </td>
      </tr>
    );
  };

  renderList = () => {
    const { list } = this.state;
    return list.map(row => {
      const companyUrl = `/app/company/${row.companyId}`;
      return (
        <tr key={row._id}>
          <th scope="row">{row.poNum}</th>
          <td>
            <Link to={companyUrl}>{row.companyName}</Link>
          </td>
          <td>{row.productCategory}</td>
          <td>{row.projectName}</td>
          <td className="text-right">{currencyFormat(row.poAmount)}</td>
        </tr>
      );
    });
  };

  render() {
    const { loading, page, pages, perPage } = this.state;
    const { sidebar } = this.props;
    return (
      <div>
        <Header sidebar={sidebar} title="Purchase Order" />
        <div className="row">
          <div className="col-sm-12">
            <Card body>
              <Row>
                <Col sm={12}>
                  <Pagination
                    page={page}
                    pages={pages}
                    setCurrentPage={this.setCurrentPage}
                  />
                </Col>
              </Row>
              <Table striped hover responsive size="sm">
                <thead>
                  <tr>
                    <th>PO#</th>
                    <th>Supplier</th>
                    <th>Category</th>
                    <th>Project</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? this.renderLoading() : this.renderList()}
                </tbody>
              </Table>
              <Row>
                <Col sm={6}>
                  <RowsPerPage
                    perPage={perPage}
                    setRowsPerPage={this.setRowsPerPage}
                  />
                </Col>
                <Col sm={6}>
                  <Pagination
                    page={page}
                    pages={pages}
                    setCurrentPage={this.setCurrentPage}
                  />
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default PurchaseOrder;
