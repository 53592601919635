import React from 'react';
import { Field } from 'formik';

export default function FormikCheckbox(props) {
  const { name } = props;
  return (
    <Field name={name}>
      {({ field, form }) => {
        const inputprops = { ...props };
        delete inputprops.onChecked;
        delete inputprops.onUnchecked;
        delete inputprops.onPreCheck;
        return (
          <input
            type="checkbox"
            {...inputprops}
            checked={field.value.includes(props.value)}
            onChange={() => {
              if (field.value.includes(props.value)) {
                const nextValue = field.value.filter(
                  value => value !== props.value
                );
                // console.log("unchecked");
                form.setFieldValue(props.name, nextValue);
                if (props.onUnchecked) {
                  props.onUnchecked(form, props.value);
                }
              } else {
                // console.log("checked");
                // if precheck returns false, do not check
                if (props.onPreCheck && !props.onPreCheck(form, props.value)) {
                  return;
                }
                const nextValue = field.value.concat(props.value);
                form.setFieldValue(props.name, nextValue);
                if (props.onChecked) {
                  props.onChecked(form, props.value);
                }
              }
            }}
          />
        );
      }}
    </Field>
  );
}
