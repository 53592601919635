import React, { Component } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';

import _ from 'lodash';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import API from '../../api';
import Header from '../../containers/Header';
import { UserContext } from '../../context/user';
import { ACTUAL_ROLES, ROLE_KDC, ROLE_EXECUTIVE } from '../../util/roles';

class AdminEmployeeUpdate extends Component {
  constructor(props) {
    super(props);

    const { match } = props;
    const { params } = match;

    this.state = {
      userId: params.userId,
      userInfo: null,
      originalUserInfo: null
    };

    this.editProfileForm = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;

    const { userId } = this.state;
    API.User(userId)
      .then(userInfo => {
        if (!this.mounted) return;
        this.setState({ userInfo, originalUserInfo: userInfo });
      })
      .catch(e => {
        console.log(e);
        if (!this.mounted) return;
        console.log(e.message);
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onSubmit = (values, { setSubmitting, setStatus, setErrors }) => {
    const { history } = this.props;
    const { originalUserInfo } = this.state;
    const { user } = this.context;

    const changes = [];
    if (values) {
      _.forEach(values, (u, k) => {
        if (k === '_id') return u;

        let value = u;
        if (['salesAgent', 'estimator'].indexOf(k) > -1) {
          value = !(value === false || value === '');
        }

        if (value !== originalUserInfo[k]) changes.push(k);

        return u;
      });
    }

    if (!changes.length) {
      console.log('No changes made.');
      setSubmitting(false);
      return {};
    }

    return API.UserUpdate(values)
      .then(async () => {
        if (!this.mounted) return;
        setSubmitting(false);
        alert('User information successfully updated.');

        if (values._id !== user._id) {
          history.goBack();
          return;
        }

        let logout = 0;
        const keyToLogout = ['firstName', 'lastName', 'username'];
        _.each(changes, key => {
          if (logout) return;

          if (keyToLogout.indexOf(key) > -1) {
            logout = 1;
          }
        });

        if (logout) {
          console.log('Automatic logout.');
          await API.Logout();
          history.push('/app/dashboard');
        }
      })
      .catch(e => {
        console.log('AdminEmployeeUpdateForm', e);
        setErrors({ submit: e.message });
        setStatus({ success: false });
        setSubmitting(false);
      });
  };

  setInitialValues = () => {
    const { userInfo } = this.state;

    const {
      _id,
      username,
      role,
      email,
      firstName,
      lastName,
      mobileNumber,
      landlineNumber,
      salesAgent,
      estimator,
      active
    } = userInfo || {};

    return {
      _id: _id || '',
      role: role || '',
      username: username || '',
      email: email || '',
      firstName: firstName || '',
      lastName: lastName || '',
      mobileNumber: mobileNumber || '',
      landlineNumber: landlineNumber || '',
      salesAgent: salesAgent || '',
      estimator: estimator || '',
      active: active || ''
    };
  };

  setValidationSchema = Yup.object({
    username: Yup.string()
      .trim()
      .min(4, 'Minimum of 4 characters')
      .max(25, 'Maximum of 25 characters')
      .matches(/^[a-zA-Z0-9]+$/, 'Must only contain letters and numbers')
      .required('Username is required'),
    email: Yup.string()
      .trim()
      .email('Invalid email format'),
    firstName: Yup.string()
      .trim()
      .required('First name is required'),
    lastName: Yup.string()
      .trim()
      .required('Last name is required'),
    mobileNumber: Yup.string().trim(),
    landlineNumber: Yup.string().trim()
  });

  composeEditProfileFormFields = (values, errors, touched) => {
    const { user } = this.context;

    const isAdmin = [ROLE_KDC, ROLE_EXECUTIVE].indexOf(user.role) > -1;

    return (
      <div className="col-xs-12 m-a">
        {errors.submit && (
          <Row form>
            <Col>
              <Alert color="danger">{errors.submit}</Alert>
            </Col>
          </Row>
        )}

        <Row form>
          <Input tag={Field} type="hidden" name="_id" />
          <Col sm={6}>
            <FormGroup>
              <Label for="UserName">Username *</Label>
              <Input
                tag={Field}
                type="text"
                name="username"
                placeholder=""
                autoComplete="off"
                invalid={errors.username && touched.username}
              />
              <FormFeedback>{errors.username}</FormFeedback>
            </FormGroup>
          </Col>
          {isAdmin ? (
            <Col sm={6}>
              <FormGroup>
                <Label for="role">Role *</Label>
                <Field component="select" name="role" className="form-control">
                  {ACTUAL_ROLES.map(r => (
                    <option key={r.value} value={r.value}>
                      {r.label}
                    </option>
                  ))}
                </Field>
              </FormGroup>
            </Col>
          ) : (
            <Col sm={6}>
              <FormGroup>
                <Label for="Email">Email</Label>
                <Input
                  tag={Field}
                  type="email"
                  name="email"
                  placeholder=""
                  autoComplete="off"
                  invalid={errors.email && touched.email}
                />
                <FormFeedback>{errors.email}</FormFeedback>
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row form>
          <Col sm={6}>
            <FormGroup>
              <Label for="FirstName">First Name *</Label>
              <Input
                tag={Field}
                type="text"
                name="firstName"
                placeholder=""
                autoComplete="off"
                invalid={errors.firstName && touched.firstName}
              />
              <FormFeedback>{errors.firstName}</FormFeedback>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="LastName">Last Name *</Label>
              <Input
                tag={Field}
                type="text"
                name="lastName"
                placeholder=""
                autoComplete="off"
                invalid={errors.lastName && touched.lastName}
              />
              <FormFeedback>{errors.lastName}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm={6}>
            <FormGroup>
              <Label for="MobileNumber">Mobile Number</Label>
              <Input
                tag={Field}
                type="tel"
                name="mobileNumber"
                placeholder=""
                autoComplete="off"
                invalid={errors.mobileNumber && touched.mobileNumber}
              />
              <FormFeedback>{errors.mobileNumber}</FormFeedback>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="LandlineNumber">Landline Number</Label>
              <Input
                tag={Field}
                type="tel"
                name="landlineNumber"
                placeholder=""
                autoComplete="off"
                invalid={errors.landlineNumber && touched.landlineNumber}
              />
              <FormFeedback>{errors.landlineNumber}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        {isAdmin ? (
          <Row form>
            <Col sm={6}>
              <FormGroup>
                <Label for="Email">Email</Label>
                <Input
                  tag={Field}
                  type="email"
                  name="email"
                  placeholder=""
                  autoComplete="off"
                  invalid={errors.email && touched.email}
                />
                <FormFeedback>{errors.email}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        ) : (
          ''
        )}
        <Row>
          <Col sm={6}>
            <FormGroup>
              <Label for="salesAgent">Other Roles</Label>
              <FormGroup check>
                <Label check style={{ fontSize: '17px' }}>
                  <Input
                    tag={Field}
                    type="checkbox"
                    name="salesAgent"
                    checked={values.salesAgent}
                  />
                  Sales Agent
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="estimator"> </Label>
              <FormGroup check>
                <Label check style={{ fontSize: '17px' }}>
                  <Input
                    tag={Field}
                    type="checkbox"
                    name="estimator"
                    checked={values.estimator}
                  />
                  Estimator
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <FormGroup>
              <Label for="active">Status</Label>
              <FormGroup check>
                <Label check style={{ fontSize: '17px' }}>
                  <Input
                    tag={Field}
                    type="checkbox"
                    name="active"
                    checked={
                      !(values.active === `false` || values.active === false)
                    }
                  />{' '}
                  Active
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  };

  composeEditProfileFormButtons = (errors, touched, isSubmitting) => {
    return (
      <div className="col-sm-12" style={{ padding: '0px' }}>
        <FormGroup row>
          <Col>
            <Button type="submit" color="primary" disabled={isSubmitting}>
              Update
            </Button>
          </Col>
        </FormGroup>
      </div>
    );
  };

  rightNav() {
    const { history } = this.props;
    return (
      <div>
        <Button color="danger" size="sm" onClick={history.goBack}>
          Back
        </Button>
      </div>
    );
  }

  render() {
    const { sidebar } = this.props;
    const { userInfo } = this.state;
    if (!userInfo) return '';

    return (
      <div>
        <Header
          sidebar={sidebar}
          title="Edit Profile"
          mode="buttons"
          rightNav={this.rightNav()}
        />
        <div className="row">
          <div className="col-sm-12">
            <Card body>
              <Formik
                initialValues={this.setInitialValues()}
                validationSchema={this.setValidationSchema}
                onSubmit={this.onSubmit}
                ref={this.editProfileForm}
              >
                {props => {
                  const { values, errors, touched, isSubmitting } = props;
                  return (
                    <Form autoComplete="off">
                      {this.composeEditProfileFormFields(
                        values,
                        errors,
                        touched,
                        isSubmitting
                      )}

                      {this.composeEditProfileFormButtons(
                        errors,
                        touched,
                        isSubmitting
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

AdminEmployeeUpdate.contextType = UserContext;

export default AdminEmployeeUpdate;
