import React, { Component } from 'react';
import { Card, Row, Col, Badge, Button } from 'reactstrap';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import Header from '../../containers/Header';
import Pagination from '../../components/Pagination';
import RowsPerPage from '../../components/RowsPerPage';
import DateRange from '../../components/DateRange';
import currencyFormat from '../../util/currency';
import dateFormat from '../../util/date';
import { DEFAULT_PER_PAGE } from '../../constants/others';
import BANKS from '../../constants/banks';
import BankTrnxList from './list';

const ALL_BANKS = { value: 'all', label: 'All Banks' };
class BankTransactions extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    const { params } = match;
    const banks = [];
    if (params.bankId) {
      BANKS.forEach(b => {
        if (params.bankId === b.value) {
          banks.push(b);
        }
      });
    }

    this.state = {
      banks,
      page: 1,
      pages: 1,
      perPage: DEFAULT_PER_PAGE,
      startDate: moment()
        .subtract(15, 'days')
        .format('YYYY-MM-DD'),
      endDate: moment()
        .add(15, 'days')
        .format('YYYY-MM-DD'),
      loading: false,
      refreshTrnxs: false
    };
  }

  handleSuccess = msg => {
    toast.success(msg);

    this.setState(prevState => {
      return {
        refreshTrnxs: !prevState.refreshTrnxs
      };
    });
  };

  getMethodBadge = method => {
    return method === 'cash' ? (
      <Badge color="info">Cash</Badge>
    ) : (
      <Badge color="secondary">Cheque</Badge>
    );
  };

  getStatusBadge = status => {
    if (status === 'pending') {
      return <Badge color="danger">Pending</Badge>;
    }
    if (status === 'clearing') {
      return <Badge color="warning">Clearing</Badge>;
    }
    if (status === 'cleared') {
      return <Badge color="success">Cleared</Badge>;
    }
    return null;
  };

  setCurrentPage = curPage => {
    const { page } = this.state;
    if (curPage === page) {
      return;
    }
    this.setState({ page: curPage });
  };

  setRowsPerPage = rowsPerPage => {
    const { perPage } = this.state;
    if (rowsPerPage === perPage) {
      return;
    }

    localStorage.setItem('bankTransactions-perPage', rowsPerPage);
    this.setState({ page: 1, perPage: rowsPerPage });
  };

  setDateRange = (start, end) => {
    const { startDate, endDate } = this.state;
    if (startDate === start && endDate === end) {
      return;
    }

    this.setState({
      startDate: start,
      endDate: end,
      page: 1,
      refreshTrnxs: new Date().getTime()
    });
  };

  setPages = pages => {
    this.setState({ pages });
  };

  onNameSelect = banks => {
    const { banks: oBanks } = this.state;

    if (
      oBanks.length === banks.length ||
      (!banks.length && oBanks.length === 1 && oBanks[0].value === 'all')
    ) {
      return;
    }

    if (banks.length <= 0 || banks.length === BANKS.length) {
      this.setState({
        banks: [ALL_BANKS],
        refreshTrnxs: new Date().getTime()
      });

      return;
    }
    const lastIndex = banks.length - 1;
    if (banks[0].value === 'all') {
      banks.splice(0, 1);
    } else if (banks[lastIndex].value === 'all') {
      banks.splice(0, lastIndex);
    }
    this.setState({ banks, refreshTrnxs: new Date().getTime() });
  };

  renderLoading = () => {
    return (
      <div key="spinner" className="text-center">
        <span className="spinner-border" role="status" aria-hidden="true" />
      </div>
    );
  };

  renderList = () => {
    const { list } = this.state;
    return list.map(row => {
      const amt = currencyFormat(row.amount);
      const bal = currencyFormat(row.availableBalance);
      let amtfmt = '';
      if (row.type === 'deposit') {
        amtfmt = (
          <span>
            <span className="text-success">{amt}</span>
            {row.availableBalance ? (
              <span>
                <br />
                <span className="text-muted">{bal}</span>
              </span>
            ) : null}
          </span>
        );
      } else {
        amtfmt = <span className="text-danger">({amt})</span>;
      }

      return (
        <tr key={row._id}>
          <th scope="row" className="text-center">
            {this.getMethodBadge(row.paymentMethod)}
            <br />
            {this.getStatusBadge(row.status)}
          </th>
          <td className="text-center">{dateFormat(row.date)}</td>
          <td>
            {row.remarks ? (
              <span>
                <strong>{row.remarks}</strong> <br />
                <span className="text-muted">{row.bankName}</span>
              </span>
            ) : (
              <strong>{row.bankName}</strong>
            )}
          </td>
          <td className="text-right">{amtfmt}</td>
        </tr>
      );
    });
  };

  rightNav() {
    const { history } = this.props;
    return (
      <div>
        <Button
          color="danger"
          size="sm"
          onClick={() => history.push('/app/bank/')}
        >
          Back
        </Button>
      </div>
    );
  }

  render() {
    const {
      banks,
      loading,
      page,
      pages,
      perPage,
      startDate,
      endDate,
      refreshTrnxs
    } = this.state;
    const { sidebar } = this.props;
    const query = {
      bankId: banks.length === 0 ? 'all' : banks.map(b => b.value).join(','),
      startDate,
      endDate
    };
    const selectOptions = [ALL_BANKS, ...BANKS];
    const selectValue = banks.length === 0 ? [ALL_BANKS] : banks;

    return (
      <div>
        <Header
          sidebar={sidebar}
          title="Bank Transactions"
          mode="buttons"
          rightNav={this.rightNav()}
        />
        <div className="row">
          <div className="col-sm-12">
            <Card body>
              <Row>
                <Col sm={7}>
                  <DateRange
                    startDate={startDate}
                    endDate={endDate}
                    setDateRange={this.setDateRange}
                  />
                  <Select
                    className="mb-2"
                    options={selectOptions}
                    isMulti
                    onChange={this.onNameSelect}
                    value={selectValue}
                  />
                </Col>

                <Col sm={5}>
                  <Pagination
                    page={page}
                    pages={pages}
                    setCurrentPage={this.setCurrentPage}
                  />
                </Col>
              </Row>
              {loading ? (
                this.renderLoading()
              ) : (
                <BankTrnxList
                  query={query}
                  refresh={refreshTrnxs}
                  page={page}
                  setPages={this.setPages}
                  perPage={perPage}
                  handleSuccess={this.handleSuccess}
                  handleError={this.handleError}
                />
              )}
              <Row>
                <Col sm={6}>
                  <RowsPerPage
                    perPage={perPage}
                    setRowsPerPage={this.setRowsPerPage}
                  />
                </Col>
                <Col sm={6}>
                  <Pagination
                    page={page}
                    pages={pages}
                    setCurrentPage={this.setCurrentPage}
                  />
                </Col>
              </Row>
            </Card>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default BankTransactions;
