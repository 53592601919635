const PROJECT_PRODUCTS = [
  { value: 'Busduct', label: 'Busduct' },
  { value: 'Controller', label: 'Controller' },
  { value: 'Generator', label: 'Generator' },
  { value: 'Services', label: 'Services' },
  { value: 'Synchro', label: 'Synchro' },
  { value: 'Others', label: 'Others' }
];

export default PROJECT_PRODUCTS;
