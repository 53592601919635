import _ from 'lodash';
import moment from 'moment';
import { kuha, kuhaP } from '../util/kuha';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const URL = {
  LOGIN: `${BASE_URL}user/login`,
  LOGOUT: `${BASE_URL}user/logout`,
  REGISTER: `${BASE_URL}user/register`,
  VERIFY: `${BASE_URL}user/verify`,
  USER: `${BASE_URL}user`,
  USER_UPDATE: `${BASE_URL}user/updateUserInfo`,
  USER_LIST: `${BASE_URL}user/list/`,
  USER_LIST_ALL: `${BASE_URL}user/listAll`,
  USER_CHANGE_PASSWORD: `${BASE_URL}user/change-password`,
  USER_SET_PASSWORD: `${BASE_URL}user/set-password`,
  BANK_LIST: `${BASE_URL}bank/`,
  BANK_DEPOSIT: `${BASE_URL}bank/deposit`,
  BANK_WITHDRAW: `${BASE_URL}bank/withdrawal`,
  BANK_TRNX_BOUNCE: `${BASE_URL}bank/transaction/bounce`,
  BANK_TRNX_RESCHED: `${BASE_URL}bank/transaction/resched`,
  BANK_TRNX_CLEARING: `${BASE_URL}bank/transaction/clearing`,
  BANK_TRNX_CLEARED: `${BASE_URL}bank/transaction/cleared`,
  BANK_TRANSACTIONS: `${BASE_URL}bank/transactions/`,
  COMPANY: `${BASE_URL}company/`,
  COMPANY_LIST: `${BASE_URL}company/list/`,
  COMPANY_SUMMARY: `${BASE_URL}company/summary/`,
  COMPANY_RECEIVABLE_SUMMARY: `${BASE_URL}company/receivable-summary/`,
  COMPANY_PAYABLE_SUMMARY: `${BASE_URL}company/payable-summary/`,
  COMPANY_INFO: `${BASE_URL}company/info/`,
  INVOICE: `${BASE_URL}invoice/`,
  INVOICE_LIST: `${BASE_URL}invoice/list/`,
  INVOICE_UNPAID: `${BASE_URL}invoice/unpaid/`,
  PAYMENT: `${BASE_URL}payment/`,
  PAYMENT_LIST: `${BASE_URL}payment/list/`,
  PAYMENT_ADD_INVOICE: `${BASE_URL}payment/add-invoice`,
  PAYMENT_REMOVE_INVOICE: `${BASE_URL}payment/remove-invoice`,
  SALES_ORDER: `${BASE_URL}sales-order/`,
  SALES_ORDER_LIST: `${BASE_URL}sales-order/list/`,
  SALES_ORDER_LIST_BY_COMPANY: `${BASE_URL}sales-order/listByCompanyId/`,
  SALES_ORDER_UNBILLED: `${BASE_URL}sales-order/unbilled/`,
  SEARCH: `${BASE_URL}search/`,
  SEARCH_REBUILD: `${BASE_URL}search/rebuild/`,
  REPORTS: `${BASE_URL}reports/`,
  REPORTS_REBUILD: `${BASE_URL}reports/rebuild/`,
  COMPANY_HISTORY: `${BASE_URL}history/list/`,
  ACTIVITY: `${BASE_URL}activity/`,
  ACTIVITY_LIST: `${BASE_URL}activity/list`,
  ACTIVITY_WEEKLY: `${BASE_URL}activity/weeklyReport/`,
  PROJECT: `${BASE_URL}project/`,
  PROJECT_LINK_SO: `${BASE_URL}project/linkSo/`,
  PROJECT_UPDATE_STATUS: `${BASE_URL}project/updateStatus/`,
  PROJECT_LIST: `${BASE_URL}project/list/`,
  PROJECT_SUMMARY: `${BASE_URL}project/summary/`,
  QUOTATION: `${BASE_URL}quotation/`,
  PURCHASE_ORDER: `${BASE_URL}purchase-order/`,
  PURCHASE_ORDER_LIST: `${BASE_URL}purchase-order/list/`,
  PURCHASE_ORDER_PROJECT: `${BASE_URL}purchase-order/project`
};

export default {
  Login: async ({ username, password }) => {
    const ret = await kuha.post(URL.LOGIN, { username, password });
    return ret;
  },
  Logout: async () => {
    const ret = await kuha.get(URL.LOGOUT);
    return ret;
  },
  VerifyUser: async () => {
    const ret = await kuha.get(URL.VERIFY);
    return ret;
  },
  Users: async (page, perPage) => {
    const ret = await kuhaP.get(`${URL.USER_LIST + page}/${perPage}`);
    return ret;
  },
  User: async userId => {
    const ret = await kuhaP.get(`${URL.USER}/${userId}`);
    return ret;
  },
  UserUpdate: async userInfo => {
    const ret = await kuhaP.put(URL.USER_UPDATE, userInfo);
    return ret;
  },
  AllUsers: async () => {
    const ret = await kuhaP.get(`${URL.USER_LIST_ALL}`);
    return ret;
  },
  RegisterUser: async user => {
    const ret = await kuhaP.post(URL.REGISTER, user);
    return ret;
  },
  UserChangePassword: async pwd => {
    const ret = await kuhaP.post(URL.USER_CHANGE_PASSWORD, pwd);
    return ret;
  },
  UserSetPassword: async pwd => {
    const ret = await kuhaP.post(URL.USER_SET_PASSWORD, pwd);
    return ret;
  },
  Banks: async () => {
    const ret = await kuhaP.get(URL.BANK_LIST);
    return ret;
  },
  BankDeposit: async trxn => {
    const ret = await kuhaP.post(URL.BANK_DEPOSIT, trxn);
    return ret;
  },
  BankWithdraw: async trxn => {
    const ret = await kuhaP.post(URL.BANK_WITHDRAW, trxn);
    return ret;
  },
  BankTrnxBounce: async trxn => {
    const ret = await kuhaP.put(URL.BANK_TRNX_BOUNCE, trxn);
    return ret;
  },
  BankTrnxResched: async trxn => {
    const ret = await kuhaP.put(URL.BANK_TRNX_RESCHED, trxn);
    return ret;
  },
  BankTrnxClearing: async trxn => {
    const ret = await kuhaP.put(URL.BANK_TRNX_CLEARING, trxn);
    return ret;
  },
  BankTrnxCleared: async trxn => {
    const ret = await kuhaP.put(URL.BANK_TRNX_CLEARED, trxn);
    return ret;
  },
  BankTransactions: async (page, perPage, query) => {
    let url = `${URL.BANK_TRANSACTIONS + page}/${perPage}`;
    if (query) {
      const q = _.map(query, (val, key) => {
        return `${key}=${val}`;
      });
      url += `?${q.join('&')}`;
    }
    const ret = await kuhaP.get(url);
    return ret;
  },
  Companies: async (page, perPage) => {
    const ret = await kuhaP.get(`${URL.COMPANY_LIST + page}/${perPage}`);
    return ret;
  },
  Company: async id => {
    const ret = await kuhaP.get(URL.COMPANY + id);
    return ret;
  },
  CompanyUpdate: async comp => {
    const ret = await kuhaP.put(URL.COMPANY, comp);
    return ret;
  },
  CompanySummary: async id => {
    const ret = await kuhaP.get(URL.COMPANY_SUMMARY + id);
    return ret;
  },
  CompanyReceivableSummary: async id => {
    const ret = await kuhaP.get(URL.COMPANY_RECEIVABLE_SUMMARY + id);
    return ret;
  },
  CompanyPayableSummary: async id => {
    const ret = await kuhaP.get(URL.COMPANY_PAYABLE_SUMMARY + id);
    return ret;
  },
  CompanyAdd: async company => {
    const ret = await kuhaP.post(URL.COMPANY, company);
    return ret;
  },
  CompanySearch: async company => {
    const ret = await kuhaP.get(`${URL.SEARCH}company?s=${company}`);
    return ret;
  },
  Invoices: async (page, perPage) => {
    const ret = await kuhaP.get(`${URL.INVOICE_LIST + page}/${perPage}`);
    return ret;
  },
  InvoiceAdd: async inv => {
    const ret = await kuhaP.post(URL.INVOICE, inv);
    return ret;
  },
  InvoiceSearch: async inv => {
    const ret = await kuhaP.get(`${URL.SEARCH}invoice?s=${inv}`);
    return ret;
  },
  UnpaidInvoices: async (page, perPage) => {
    const ret = await kuhaP.get(`${URL.INVOICE_UNPAID + page}/${perPage}`);
    return ret;
  },
  InvoiceDelete: async params => {
    const { id } = params;
    const ret = await kuhaP.delete(`${URL.INVOICE}${id}`);
    return ret;
  },
  Payments: async (page, perPage) => {
    const ret = await kuhaP.get(`${URL.PAYMENT_LIST + page}/${perPage}`);
    return ret;
  },
  PaymentAdd: async pay => {
    const ret = await kuhaP.post(URL.PAYMENT, pay);
    return ret;
  },
  PaymentAddInvoice: async pay => {
    const ret = await kuhaP.post(URL.PAYMENT_ADD_INVOICE, pay);
    return ret;
  },
  PaymentRemoveInvoice: async pay => {
    const ret = await kuhaP.post(URL.PAYMENT_REMOVE_INVOICE, pay);
    return ret;
  },
  PaymentDelete: async params => {
    const { id } = params;
    const ret = await kuhaP.delete(`${URL.PAYMENT}${id}`);
    return ret;
  },
  PaymentSearch: async pay => {
    const ret = await kuhaP.get(`${URL.SEARCH}payment?s=${pay}`);
    return ret;
  },
  SalesOrders: async (page, perPage) => {
    const ret = await kuhaP.get(`${URL.SALES_ORDER_LIST + page}/${perPage}`);
    return ret;
  },
  SalesOrdersListByCompanyId: async id => {
    const ret = await kuhaP.get(`${URL.SALES_ORDER_LIST_BY_COMPANY}${id}`);
    return ret;
  },
  SalesOrdersDetails: async id => {
    const ret = await kuhaP.get(`${URL.SALES_ORDER + id}`);
    return ret;
  },
  SalesOrdersRemove: async id => {
    const ret = await kuhaP.delete(`${URL.SALES_ORDER + id}`);
    return ret;
  },
  UnbilledSalesOrders: async (page, perPage) => {
    const ret = await kuhaP.get(
      `${URL.SALES_ORDER_UNBILLED + page}/${perPage}`
    );
    return ret;
  },
  SalesOrderAdd: async so => {
    const ret = await kuhaP.post(URL.SALES_ORDER, so);
    return ret;
  },
  SalesOrderUpdate: async so => {
    const ret = await kuhaP.put(URL.SALES_ORDER, so);
    return ret;
  },
  SalesOrderSearch: async so => {
    const ret = await kuhaP.get(`${URL.SEARCH}sales-order?s=${so}`);
    return ret;
  },
  SalesAgentSearch: async agent => {
    const ret = await kuhaP.get(`${URL.SEARCH}sales-agent?s=${agent}`);
    return ret;
  },
  SearchRebuild: async key => {
    const ret = await kuhaP.get(URL.SEARCH_REBUILD + key);
    return ret;
  },
  ReportsReceivables: async () => {
    const ret = await kuhaP.get(`${URL.REPORTS}receivables`);
    return ret;
  },
  ReportsSalesLast12Months: async () => {
    const ret = await kuhaP.get(`${URL.REPORTS}sales-last-12`);
    return ret;
  },
  ReportsSalesYearly: async () => {
    const ret = await kuhaP.get(`${URL.REPORTS}sales-yearly`);
    return ret;
  },
  ReportsSalesAllLast12Months: async () => {
    const ret = await kuhaP.get(`${URL.REPORTS}sales-all-last-12`);
    return ret;
  },
  ReportsSalesAllYearly: async () => {
    const ret = await kuhaP.get(`${URL.REPORTS}sales-all-yearly`);
    return ret;
  },
  ReportsSalesLast12MonthsEveryone: async () => {
    const ret = await kuhaP.get(`${URL.REPORTS}sales-last-12-everyone`);
    return ret;
  },
  ReportsSalesAllMonthly: async () => {
    const ret = await kuhaP.get(`${URL.REPORTS}sales-all-monthly`);
    return ret;
  },
  ReportsDashboard: async role => {
    const ret = await kuhaP.get(`${URL.REPORTS}dashboard/${role}`);
    return ret;
  },
  ReportsRebuild: async key => {
    const ret = await kuhaP.get(URL.REPORTS_REBUILD + key);
    return ret;
  },
  WeeklySales: async (start, end) => {
    const ret = await kuhaP.get(
      `${URL.REPORTS}sales-all-weekly?start=${start}&end=${end}`
    );
    return ret;
  },
  ReportsSalesMonthly: async () => {
    const ret = await kuhaP.get(`${URL.REPORTS}sales-monthly`);
    return ret;
  },
  CompanyHistory: async (id, page, perPage) => {
    const ret = await kuhaP.get(
      `${URL.COMPANY_HISTORY}${id}/${page}/${perPage}`
    );
    return ret;
  },
  ActivityAdd: async activity => {
    const ret = await kuhaP.post(URL.ACTIVITY, activity);
    return ret;
  },
  ActivityList: async params => {
    const { roles } = params;
    const { projectId } = params;
    let { dateFilter } = params;

    if (!dateFilter) dateFilter = moment().format('YYYY-MM-DD');

    const ret = await kuhaP.get(
      `${URL.ACTIVITY_LIST}/${dateFilter}/${roles}/${projectId || ''}`
    );
    return ret;
  },
  ActivityDelete: async params => {
    const { id } = params;
    const ret = await kuhaP.delete(`${URL.ACTIVITY}${id}`);
    return ret;
  },
  ActivityUpdate: async activity => {
    const ret = await kuhaP.put(URL.ACTIVITY, activity);
    return ret;
  },
  UserActivityWeekly: async (userId, dateStart, dateEnd) => {
    if (!userId) return Promise.reject();
    if (!dateStart) return Promise.reject();
    if (!dateEnd) return Promise.reject();

    const ret = await kuhaP.get(
      `${URL.ACTIVITY_WEEKLY}${userId}/${dateStart}/${dateEnd}`
    );
    return ret;
  },
  MarketingManagerSearch: async name => {
    const ret = await kuhaP.get(`${URL.SEARCH}marketing-manager?s=${name}`);
    return ret;
  },
  EstimatorSearch: async name => {
    const ret = await kuhaP.get(`${URL.SEARCH}estimator?s=${name}`);
    return ret;
  },
  PurchasingSearch: async name => {
    const ret = await kuhaP.get(`${URL.SEARCH}purchasing?s=${name}`);
    return ret;
  },
  ProjectAdd: async project => {
    const ret = await kuhaP.post(URL.PROJECT, project);
    return ret;
  },
  ProjectUpdate: async project => {
    const ret = await kuhaP.put(URL.PROJECT, project);
    return ret;
  },
  ProjectLinkSo: async project => {
    const ret = await kuhaP.put(URL.PROJECT_LINK_SO, project);
    return ret;
  },
  ProjectUpdateStatus: async project => {
    const ret = await kuhaP.put(URL.PROJECT_UPDATE_STATUS, project);
    return ret;
  },
  Projects: async (page, perPage) => {
    const ret = await kuhaP.get(`${URL.PROJECT_LIST + page}/${perPage}`);
    return ret;
  },
  ProjectSearch: async project => {
    const ret = await kuhaP.get(`${URL.SEARCH}project?s=${project}`);
    return ret;
  },
  ProjectSummary: async id => {
    const ret = await kuhaP.get(URL.PROJECT_SUMMARY + id);
    return ret;
  },
  MarketingSearch: async name => {
    const ret = await kuhaP.get(`${URL.SEARCH}marketing?s=${name}`);
    return ret;
  },
  QuotationAdd: async quotation => {
    const ret = await kuhaP.post(URL.QUOTATION, quotation);
    return ret;
  },
  QuotationUpdate: async quotation => {
    const ret = await kuhaP.put(URL.QUOTATION, quotation);
    return ret;
  },
  PurchaseOrders: async (page, perPage) => {
    const ret = await kuhaP.get(`${URL.PURCHASE_ORDER_LIST + page}/${perPage}`);
    return ret;
  },
  PurchaseOrderAdd: async po => {
    const ret = await kuhaP.post(URL.PURCHASE_ORDER, po);
    return ret;
  },
  PurchaseOrderUpdate: async po => {
    const ret = await kuhaP.put(URL.PURCHASE_ORDER, po);
    return ret;
  },
  PurchaseOrderUpdateProject: async po => {
    const ret = await kuhaP.put(URL.PURCHASE_ORDER_PROJECT, po);
    return ret;
  }
};
