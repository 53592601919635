import React, { Component } from 'react';
import { UserContext } from '../context/user';
import SidebarAll from './Sidebar.all';
import SidebarAdmin from './Sidebar.admin';
import SidebarReceivable from './Sidebar.receivable';
import SidebarPayable from './Sidebar.payable';
import SidebarSales from './Sidebar.sales';
import SidebarDefault from './Sidebar.default';
import SidebarMarketingManager from './Sidebar.marketingManager';
import SidebarExecutiveSecretary from './Sidebar.executiveSecretary';
import SidebarOfficeAdminStaff from './Sidebar.officeAdminStaff';
import SidebarPurchasing from './Sidebar.purchasing';
import SidebarDocumentCollector from './Sidebar.documentCollector';
import SidebarHr from './Sidebar.hr';

class Sidebar extends Component {
  render() {
    const { user } = this.context;
    let sidebar = null;
    switch (user.role) {
      case 'admin':
        sidebar = <SidebarAdmin {...this.props} />;
        break;
      case 'adminStaff':
        sidebar = <SidebarOfficeAdminStaff {...this.props} />;
        break;
      case 'receivable':
        sidebar = <SidebarReceivable {...this.props} />;
        break;
      case 'payable':
        sidebar = <SidebarPayable {...this.props} />;
        break;
      case 'sales':
        sidebar = <SidebarSales {...this.props} />;
        break;
      case 'kdc':
      case 'executive':
        sidebar = <SidebarAll {...this.props} />;
        break;
      case 'executiveSecretary':
        sidebar = <SidebarExecutiveSecretary {...this.props} />;
        break;
      case 'marketing':
      case 'marketingManager':
        sidebar = <SidebarMarketingManager {...this.props} />;
        break;
      case 'purchasing':
        sidebar = <SidebarPurchasing {...this.props} />;
        break;
      case 'documentController':
        sidebar = <SidebarDocumentCollector {...this.props} />;
        break;
      case 'hr':
        sidebar = <SidebarHr {...this.props} />;
        break;
      default:
        sidebar = <SidebarDefault {...this.props} />;
        break;
    }

    return sidebar;
  }
}
Sidebar.contextType = UserContext;

export default Sidebar;
