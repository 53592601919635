import React, { Component } from 'react';
import { UserContext } from '../../context/user';
import SummaryPayableReceivable from './summary.accounting';
import SummaryDefault from './summary.default';

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = { mounted: false };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    const { mounted } = this.state;
    if (!mounted) return '';

    const { user } = this.context;
    let summary = null;
    switch (user.role) {
      case 'kdc':
      case 'executive':
      case 'executiveSecretary':
      case 'receivable':
      case 'payable':
      case 'purchasing':
      case 'documentController':
        summary = <SummaryPayableReceivable {...this.props} user={user} />;
        break;
      default:
        summary = <SummaryDefault {...this.props} user={user} />;
        break;
    }

    return summary;
  }
}

Summary.contextType = UserContext;

export default Summary;
