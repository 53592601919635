import React from 'react';
import { Button, UncontrolledAlert } from 'reactstrap';
import { UserContext } from '../context/user';
import IntlMessages from '../util/IntlMessages';
import logo from '../images/logo-with-name.png';

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      loading: false,
      errorMessage: false
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentDidUpdate() {
    const { showMessage, history, hideMessage } = this.props;
    const { user } = this.context;
    let authUser = false;
    if (user && user.role && user.role !== 'guest') {
      authUser = true;
    }
    if (showMessage) {
      setTimeout(() => {
        hideMessage();
      }, 100);
    }
    if (authUser) {
      history.push('/');
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleLogin = e => {
    const { username, password } = this.state;
    e.preventDefault();

    const userCtx = this.context;
    this.setState({ loading: true });
    userCtx
      .login({ username, password })
      .then(() => {
        if (!this.mounted) return;
        this.setState({ loading: false });
      })
      .catch(err => {
        if (!this.mounted) return;
        console.log('err', err);

        this.setState({ loading: false, errorMessage: err.message });
      });
  };

  render() {
    const { username, password, loading, errorMessage } = this.state;

    return (
      <form className="form-signin text-center">
        <img className="mb-4" src={logo} alt="logo" width="300px" />
        <h4 className="h4 mb-3 font-weight-normal">Please sign in</h4>
        {errorMessage && (
          <UncontrolledAlert color="danger">{errorMessage}</UncontrolledAlert>
        )}
        <div className="form-group mb-3">
          <input
            placeholder="Username"
            onChange={event => this.setState({ username: event.target.value })}
            defaultValue={username}
            className="form-control form-control-lg"
            autoComplete="username"
            name="username"
            disabled={loading}
          />
        </div>

        <div className="form-group mb-3">
          <input
            type="password"
            placeholder="Password"
            onChange={event => this.setState({ password: event.target.value })}
            defaultValue={password}
            className="form-control form-control-lg"
            autoComplete="current-password"
            name="password"
            disabled={loading}
          />
        </div>

        <div className="mb-3 d-flex align-items-center justify-content-between">
          <Button
            onClick={this.handleLogin}
            color="primary"
            className="text-uppercase"
            disabled={loading}
            type="submit"
          >
            {loading && (
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              />
            )}
            <IntlMessages id="appModule.signIn" />
          </Button>
        </div>
      </form>
    );
  }
}

SignIn.contextType = UserContext;

export default SignIn;
