import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

const RowsPerPage = props => {
  const { perPage, setRowsPerPage } = props;
  return (
    <div>
      <span>Rows Per Page:</span>
      <ButtonGroup>
        <Button
          id="perPageGroup"
          size="sm"
          color="light"
          active={perPage === 10}
          onClick={() => setRowsPerPage(10)}
        >
          10
        </Button>
        <Button
          size="sm"
          color="light"
          active={perPage === 25}
          onClick={() => setRowsPerPage(25)}
        >
          25
        </Button>
        <Button
          size="sm"
          color="light"
          active={perPage === 50}
          onClick={() => setRowsPerPage(50)}
        >
          50
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default RowsPerPage;
