import React, { Component } from 'react';
import Select from 'react-select';
import {
  Button,
  Card,
  FormGroup,
  Label,
  Input,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
  Badge
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { formatHour, userStringDateFormat } from '../../util/date';

import { UserContext } from '../../context/user';
import API from '../../api';

import autoRefreshList, { clearAutoRefreshList } from '../../util/scheduler';

import ALL_ROLES, {
  VIRTUAL_ROLES,
  handleRolesSelection
} from '../../util/roles';

class ActivityList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      loading: true,
      roles: [VIRTUAL_ROLES[0]],
      dateFilter: moment().format('YYYY-MM-DD'),
      showFilter: 0,
      openDropdown: ''
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.getList();
  }

  componentDidUpdate(prevProps) {
    const { listUpdateTs } = this.props;
    if (prevProps.listUpdateTs !== listUpdateTs) {
      this.getList();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    clearAutoRefreshList('getList');
  }

  getList(options = {}) {
    this.setState({ loading: true });
    const { roles, dateFilter } = this.state;
    const { projectId } = this.props;

    let paramRoles = roles;
    if (options.roles) paramRoles = options.roles;

    let paramDateFilter = dateFilter;
    if (options.dateFilter) paramDateFilter = options.dateFilter;

    const rolesParam = [];
    if (paramRoles && paramRoles instanceof Array && paramRoles.length > 0) {
      paramRoles.forEach(r => {
        rolesParam.push(r.value);
      });
    }

    const params = {};
    params.dateFilter = paramDateFilter;
    params.roles = rolesParam.join(',');

    if (projectId) params.projectId = projectId;

    API.ActivityList(params)
      .then(res => {
        if (!this.mounted) return;
        this.setState({ list: res, loading: false });
        autoRefreshList(this, 'getList');
      })
      .catch(err => {
        console.log(err);
        if (!this.mounted) return;
        this.setState({ loading: false });
        autoRefreshList(this, 'getList');
      });
  }

  renderLoading = () => {
    return (
      <div key="spinner">
        <div colSpan={6} className="text-center">
          <span className="spinner-border" role="status" aria-hidden="true" />
        </div>
      </div>
    );
  };

  getActivityMenu = (row, allowEdit) => {
    const { _id: rowId, log } = row;
    const { openDropdown } = this.state;

    const open = openDropdown === rowId;
    return (
      <ButtonDropdown
        direction="down"
        isOpen={open}
        toggle={() => {
          if (!open) {
            this.setState({ openDropdown: rowId });
          } else {
            this.setState({ openDropdown: '' });
          }
        }}
        style={{
          position: 'relavtive',
          top: '0px',
          right: '0px'
        }}
      >
        <DropdownToggle
          caret
          color="light"
          style={{
            padding: '0px',
            height: '20px',
            paddingRight: '3px'
          }}
        />
        <DropdownMenu right>
          {allowEdit ? (
            <DropdownItem style={{ fontSize: '14px' }}>
              <NavLink
                href="#"
                onClick={() => {
                  const { parent } = this.props;
                  parent.setSelectedActivity(row);
                }}
              >
                Edit
              </NavLink>
            </DropdownItem>
          ) : (
            ''
          )}

          <DropdownItem style={{ fontSize: '14px' }}>
            <NavLink
              href="#"
              onClick={() => {
                this.setState({ loading: true });

                if (
                  !window.confirm(
                    `Are you sure you want delete this activity?\n\n${log}`
                  )
                ) {
                  this.setState({ loading: false });
                  return;
                }

                API.ActivityDelete({ id: rowId })
                  .then(() => {
                    if (!this.mounted) return;
                    this.setState({ loading: false });
                    this.getList();
                  })
                  .catch(err => {
                    console.log(err);
                    if (!this.mounted) return;
                    this.setState({ loading: false });
                  });
              }}
            >
              Delete
            </NavLink>
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  };

  getAudienceMenu = row => {
    const { whoShouldSeeThis } = row;

    const audienceList = [];
    ALL_ROLES.filter(item => {
      if (whoShouldSeeThis.indexOf(item.value) > -1) {
        audienceList.push(item.label);
      }

      return item;
    });

    const audienceListString = audienceList.join(', ');
    return (
      <div className="small">
        <FontAwesomeIcon icon="user" /> {audienceListString}
      </div>
    );
  };

  renderList = user => {
    const { dateFilter } = this.state;
    const { _id: userId } = user;

    const { list } = this.state;
    if (list.length <= 0) {
      return (
        <div key={0} className="text-center">
          -- No activities --
        </div>
      );
    }

    let allowEdit = 1;
    const startOfCurrentWeekDate = moment().startOf('isoWeek');
    const selectedDate = moment(dateFilter);

    if (!selectedDate.isSameOrAfter(startOfCurrentWeekDate)) {
      allowEdit = 0;
    }

    return list.map((row, index) => {
      return (
        <div key={row._id}>
          <hr
            className={!index ? 'hidden' : ''}
            style={{ display: !index ? 'none' : '' }}
          />

          <div className="row" style={{ display: 'flex' }}>
            <div className="col-sm-9">
              <div style={{ fontWeight: 'bold', fontSize: '15px' }}>
                {row.name}
              </div>
              <div style={{ fonSize: '11px' }}>
                {userStringDateFormat(row.date, 'MM / DD / YYYY')},{' '}
                {formatHour(row.timeStart)} - {formatHour(row.timeEnd)}{' '}
                {userId === row.userId ? this.getAudienceMenu(row) : ''}
              </div>
            </div>

            <div className="col-sm-3" style={{ textAlign: 'right' }}>
              {userId === row.userId
                ? this.getActivityMenu(row, allowEdit)
                : ''}
            </div>

            <div
              className="col-sm-12"
              style={{ paddingTop: '21px', fontSize: '14px' }}
            >
              {row.log}
            </div>

            {row.projectName ? (
              <div
                className="col-sm-12"
                style={{
                  paddingTop: '21px',
                  fontSize: '13px',
                  color: '#365899'
                }}
              >
                <Badge color="info">{row.projectName}</Badge>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      );
    });
  };

  onRoleSelect = role => {
    const selectedRoles = handleRolesSelection(role);
    this.setState({ roles: selectedRoles });
    this.getList({ roles: selectedRoles });
  };

  onDateFilterChange = event => {
    let date = event.currentTarget.value;
    if (!date) date = moment().format('YYYY-MM-DD');
    this.setState({ dateFilter: date });
    this.getList({ dateFilter: date });
  };

  getDateFilter = () => {
    const { dateFilter } = this.state;

    if (!dateFilter) return moment().format('YYYY-MM-DD');

    return dateFilter;
  };

  toggleFilterVisibility = () => {
    const { showFilter } = this.state;
    this.setState({ showFilter: !showFilter });
  };

  render() {
    const { user } = this.context;
    const { loading, roles, showFilter } = this.state;
    const selectOptions = ALL_ROLES;

    const selectValue = roles.length === 0 ? [] : roles;

    let filterHeight = '0px';
    let filterOpacity = 0;
    if (showFilter) {
      filterOpacity = 1;
      filterHeight = '100%';
    }

    return (
      <div>
        <Card
          body
          style={{
            borderRadius: '0px',
            borderTop: '0px',
            borderBottom: '0px',
            paddingTop: '0px',
            paddingBottom: '0px'
          }}
        >
          <div>
            <Button
              onClick={this.toggleFilterVisibility}
              color="link small"
              className="text-left"
              style={{
                padding: '0px',
                fontSize: '14px',
                textDecoration: 'none',
                color: '#212529'
              }}
            >
              <FontAwesomeIcon className="icon mr-1" icon="filter" />
              Filter
              <FontAwesomeIcon className="ml-1" icon="caret-down" />
            </Button>
          </div>
          <hr />
        </Card>
        <Card
          body
          style={{
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            borderTop: '0px',
            paddingTop: '7px',
            marginBottom: '21px'
          }}
        >
          <div
            className="filters mb7"
            style={{
              height: filterHeight,
              opacity: filterOpacity,
              border: '1px solid #ccc',
              borderRadius: '.25rem',
              transition: 'opacity .3s, height .3s'
            }}
          >
            <div style={{ margin: '21px' }}>
              <div className="mb5">
                <FormGroup>
                  <Label for="soDate">Filter By Date: </Label>
                  <Input
                    type="date"
                    value={this.getDateFilter()}
                    onChange={this.onDateFilterChange}
                    name="dateFilter"
                  />
                </FormGroup>
              </div>

              <div>
                <FormGroup>
                  <Label for="roleFilter">Filter By Role: </Label>
                  <Select
                    options={selectOptions}
                    isMulti
                    onChange={this.onRoleSelect}
                    value={selectValue}
                  />
                </FormGroup>
              </div>
            </div>
          </div>

          <div style={{ paddingTop: '27px' }}>
            {loading ? this.renderLoading() : this.renderList(user)}
          </div>
        </Card>
      </div>
    );
  }
}

ActivityList.contextType = UserContext;

export default ActivityList;
