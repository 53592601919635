import React from 'react';

const currencyFormat = (value, className) => {
  const val = value || 0;
  let spanClass = '';
  if (className && val > 0) {
    spanClass = className;
  }
  return (
    <span className={spanClass}>
      &#8369;
      {new Intl.NumberFormat('en-PH', {
        style: 'decimal',
        currencyDisplay: 'symbol',
        currency: 'PHP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(val)}
    </span>
  );
};

export const currencyRound = number => {
  if (!number) return 0;

  const num = parseFloat(number);
  if (Number.isNaN(num)) return 0;

  // reduce to 2 decimal places
  // do not use tofixed coz it returns a string
  return Math.round(num * 100) / 100;
};

export const customCurrencyFormat = number => {
  if (!number) return '0.00';

  const num = parseFloat(number);
  if (Number.isNaN(num)) return '0.00';

  const formattedPrice = new Intl.NumberFormat('en-PH', {
    style: 'decimal',
    currencyDisplay: 'symbol',
    currency: 'PHP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(num);

  return formattedPrice;
};

export default currencyFormat;
