import React, { Component } from 'react';
import Select from 'react-select';
import BANKS from '../../constants/banks';

export default class BankSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banks: BANKS
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onNameSelect = bank => {
    const { form } = this.props;
    const { values } = form;
    values.bankName = bank.label;
    values.bankId = bank.value;
    form.setValues(values);
  };

  noOptionsMessage = () => {
    return 'No results found';
  };

  render() {
    const { banks } = this.state;
    const { form, name, disabled } = this.props;
    const { values } = form;
    const value = { label: values.bankName, value: values.bankId };

    return (
      <Select
        placeholder="type agent name"
        options={banks}
        onChange={this.onNameSelect}
        noOptionsMessage={this.noOptionsMessage}
        value={value}
        name={name}
        isDisabled={disabled}
      />
    );
  }
}
