import React, { Component } from 'react';
import {
  Card,
  Table,
  Button,
  Row,
  Col,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/lib/Async';
import { debounce } from 'lodash';
import { UserContext } from '../../context/user';
import Header from '../../containers/Header';
import Pagination from '../../components/Pagination';
import RowsPerPage from '../../components/RowsPerPage';
import currencyFormat from '../../util/currency';
import { titleCase } from '../../util/processString';
import API from '../../api';
import { DEFAULT_PER_PAGE, CLEARED_THRESHOLD } from '../../constants/others';

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      page: 1,
      pages: 1,
      perPage: DEFAULT_PER_PAGE,
      loading: true
    };
    this.debounceCompany = debounce(this.getCompanies, 500);
  }

  componentDidMount() {
    this.mounted = true;
    const { page, perPage } = this.state;
    let storedPerPage = localStorage.getItem('company-perPage');
    if (storedPerPage) {
      storedPerPage = parseInt(storedPerPage, 10);
      this.setState({ perPage: storedPerPage });
      this.getList(page, storedPerPage);
    } else {
      this.getList(page, perPage);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setCurrentPage = curPage => {
    const { page, perPage } = this.state;
    if (curPage === page) {
      return;
    }
    this.setState({ page: curPage, loading: true }, () =>
      this.getList(curPage, perPage)
    );
  };

  onNameSelect = company => {
    const { history } = this.props;
    history.push(`/app/company/${company.value}`);
  };

  setRowsPerPage = newPerPage => {
    const { page, perPage } = this.state;
    if (newPerPage === perPage) {
      return;
    }

    localStorage.setItem('company-perPage', newPerPage);
    this.setState({ page: 1, perPage: newPerPage, loading: true }, () =>
      this.getList(page, newPerPage)
    );
  };

  getList(page, perPage) {
    API.Companies(page, perPage)
      .then(res => {
        if (!this.mounted) return;
        this.setState({ ...res, loading: false });
      })
      .catch(err => {
        console.log(err);
        if (!this.mounted) return;
        this.setState({ loading: false });
      });
  }

  getCompanies = (company, cb) => {
    if (company.length < 3) {
      cb([]);
      return;
    }

    API.CompanySearch(company)
      .then(list => {
        if (!this.mounted) return;
        const options = list.map(com => {
          return { value: com._id, label: com.name };
        });
        cb(options);
      })
      .catch(() => cb([]));
  };

  loadOptions = (company, cb) => {
    this.debounceCompany(company, cb);
  };

  rebuildCache = name => {
    if (name === 'all') {
      const list = [
        'project',
        'company',
        'sales-order',
        'invoice',
        'payment',
        'marketing-manager',
        'marketing',
        'sales-agent',
        'estimator',
        'purchasing'
      ];

      list.forEach(key => {
        API.SearchRebuild(key)
          .then(() => alert(`${titleCase(key)} rebuild started`))
          .catch(e => console.log(e));
      });
      return;
    }

    API.SearchRebuild(name)
      .then(() => alert(`${titleCase(name)} rebuild started`))
      .catch(e => console.log(e));
  };

  renderLoading = () => {
    return (
      <tr key="spinner">
        <td colSpan={7} className="text-center">
          <span className="spinner-border" role="status" aria-hidden="true" />
        </td>
      </tr>
    );
  };

  noOptionsMessage = ({ inputValue }) => {
    if (inputValue && inputValue.length < 3) {
      return 'Enter at least 3 letters';
    }
    return 'No results found';
  };

  rightNav = () => {
    const { user } = this.context;
    let kdc = null;
    if (user && user.role && user.role === 'kdc') {
      kdc = (
        <UncontrolledButtonDropdown className="mr-2">
          <DropdownToggle caret size="sm">
            KDC
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem header>Rebuild Cache</DropdownItem>
            <DropdownItem onClick={() => this.rebuildCache('all')}>
              All
            </DropdownItem>
            <DropdownItem onClick={() => this.rebuildCache('project')}>
              Project
            </DropdownItem>
            <DropdownItem onClick={() => this.rebuildCache('company')}>
              Company
            </DropdownItem>
            <DropdownItem onClick={() => this.rebuildCache('sales-order')}>
              Sales Order
            </DropdownItem>
            <DropdownItem onClick={() => this.rebuildCache('invoice')}>
              Invoice
            </DropdownItem>
            <DropdownItem onClick={() => this.rebuildCache('payment')}>
              Payment
            </DropdownItem>
            <DropdownItem
              onClick={() => this.rebuildCache('marketing-manager')}
            >
              Marketing Manager
            </DropdownItem>
            <DropdownItem onClick={() => this.rebuildCache('marketing')}>
              Marketing
            </DropdownItem>
            <DropdownItem onClick={() => this.rebuildCache('purchasing')}>
              Purchasing
            </DropdownItem>
            <DropdownItem onClick={() => this.rebuildCache('sales-agent')}>
              Sales Agent
            </DropdownItem>
            <DropdownItem onClick={() => this.rebuildCache('estimator')}>
              Estimator
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      );
    }

    return (
      <div>
        {kdc}
        <Link to="/app/company/add">
          <Button color="primary" size="sm">
            Add
          </Button>
        </Link>
      </div>
    );
  };

  renderList() {
    const { list } = this.state;
    return list.map(row => {
      const companyUrl = `/app/company/${row._id}`;
      let tdclass = '';
      if (row.receivables.invoiceBalance > CLEARED_THRESHOLD) {
        tdclass = 'text-right bg-danger text-white';
      }
      return (
        <tr key={row._id}>
          <td>
            <Link to={companyUrl}>{row.name}</Link>
          </td>
          <td className="text-right">{row.salesAgent.join(', ')}</td>
          <td className="text-right">
            {currencyFormat(row.receivables.paidAmount)}
          </td>
          <td className={tdclass}>
            {currencyFormat(row.receivables.invoiceBalance)}
          </td>
          <td className="text-right">
            {currencyFormat(row.receivables.unbilledAmount)}
          </td>
          <td className="text-right">
            {currencyFormat(row.receivables.amount)}
          </td>
        </tr>
      );
    });
  }

  render() {
    const { loading, page, pages, perPage } = this.state;
    const { sidebar } = this.props;

    return (
      <div>
        <Header
          sidebar={sidebar}
          title="Company"
          mode="buttons"
          rightNav={this.rightNav()}
        />
        <div className="row">
          <div className="col-sm-12">
            <Card body>
              <Row>
                <Col sm={6}>
                  <AsyncSelect
                    placeholder="Search company"
                    openMenuOnClick={false}
                    loadOptions={this.loadOptions}
                    onChange={this.onNameSelect}
                    noOptionsMessage={this.noOptionsMessage}
                    className="mb-2"
                  />
                </Col>
                <Col sm={6}>
                  <Pagination
                    page={page}
                    pages={pages}
                    setCurrentPage={this.setCurrentPage}
                  />
                </Col>
              </Row>
              <Table striped hover responsive>
                <thead>
                  <tr className="text-center">
                    <th>Name</th>
                    <th>Sales Agent</th>
                    <th>Paid</th>
                    <th>Unpaid</th>
                    <th>Unbilled</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? this.renderLoading() : this.renderList()}
                </tbody>
              </Table>
              <Row>
                <Col sm={6}>
                  <RowsPerPage
                    perPage={perPage}
                    setRowsPerPage={this.setRowsPerPage}
                  />
                </Col>
                <Col sm={6}>
                  <Pagination
                    page={page}
                    pages={pages}
                    setCurrentPage={this.setCurrentPage}
                  />
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

Company.contextType = UserContext;

export default Company;
