import React, { Component } from 'react';
import { Card, Table, Button, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/lib/Async';
import { debounce } from 'lodash';
import Header from '../../containers/Header';
import Pagination from '../../components/Pagination';
import RowsPerPage from '../../components/RowsPerPage';
import API from '../../api';
import { DEFAULT_PER_PAGE } from '../../constants/others';
import { UserContext } from '../../context/user';

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      page: 1,
      pages: 1,
      perPage: DEFAULT_PER_PAGE,
      loading: true
    };
    this.debounceCompany = debounce(this.getCompanies, 500);
  }

  componentDidMount() {
    this.mounted = true;
    const { page, perPage } = this.state;
    let storedPerPage = localStorage.getItem('company-perPage');
    if (storedPerPage) {
      storedPerPage = parseInt(storedPerPage, 10);
      this.setState({ perPage: storedPerPage });
      this.getList(page, storedPerPage);
    } else {
      this.getList(page, perPage);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setCurrentPage = curPage => {
    const { page, perPage } = this.state;
    if (curPage === page) {
      return;
    }
    this.setState({ page: curPage, loading: true }, () =>
      this.getList(curPage, perPage)
    );
  };

  onNameSelect = async company => {
    const { user } = this.context;

    const companyInfo = await API.Company(company.value);
    if (!companyInfo) return;

    // Allow sales to redirect only if he/she is one of the sales agents of the selected company
    const isSales = user.role === 'sales';
    if (isSales) {
      const isInSales =
        companyInfo.salesAgentId &&
        companyInfo.salesAgentId.indexOf(user._id) > -1;

      if (!(isSales && isInSales)) return;
    }

    const { history } = this.props;
    history.push(`/app/company/${company.value}`);
  };

  setRowsPerPage = newPerPage => {
    const { page, perPage } = this.state;
    if (newPerPage === perPage) {
      return;
    }

    localStorage.setItem('company-perPage', newPerPage);
    this.setState({ page: 1, perPage: newPerPage, loading: true }, () =>
      this.getList(page, newPerPage)
    );
  };

  getList(page, perPage) {
    API.Companies(page, perPage)
      .then(res => {
        if (!this.mounted) return;
        this.setState({ ...res, loading: false });
      })
      .catch(err => {
        console.log(err);
        if (!this.mounted) return;
        this.setState({ loading: false });
      });
  }

  getCompanies = (company, cb) => {
    API.CompanySearch(company)
      .then(list => {
        if (!this.mounted) return;
        const options = list.map(com => {
          return { value: com._id, label: com.name };
        });
        cb(options);
      })
      .catch(() => cb([]));
  };

  loadOptions = (company, cb) => {
    this.debounceCompany(company, cb);
  };

  rebuildCache = name => {
    API.SearchRebuild(name)
      .then(() => alert('Rebuild started'))
      .catch(e => console.log(e));
  };

  renderLoading = () => {
    return (
      <tr key="spinner">
        <td colSpan={7} className="text-center">
          <span className="spinner-border" role="status" aria-hidden="true" />
        </td>
      </tr>
    );
  };

  noOptionsMessage = ({ inputValue }) => {
    if (inputValue && inputValue.length < 3) {
      return 'Enter at least 3 letters';
    }
    return 'No results found';
  };

  rightNav = () => {
    const { role } = this.props;
    const allowedUser = [
      'kdc',
      'executive',
      'executiveSecretary',
      'marketingManager',
      'documentController'
    ];
    if (allowedUser.indexOf(role) === -1) return null;

    return (
      <div>
        <Link to="/app/company/add">
          <Button color="primary" size="sm">
            Add
          </Button>
        </Link>
      </div>
    );
  };

  renderList() {
    const { list } = this.state;
    return list.map(row => {
      const companyUrl = `/app/company/${row._id}`;
      let address = row.address1 ? row.address1 : '';

      if (row.address2) {
        address += address ? `, ${row.address2}` : row.address2;
      }

      if (row.city) {
        address += address ? `, ${row.city}` : row.city;
      }

      return (
        <tr key={row._id}>
          <td className="text-center">
            <Link to={companyUrl}>{row.name}</Link>
          </td>
          <td className="text-center">{address}</td>
          <td className="text-center">{row.landline}</td>
          <td className="text-center">{row.fax}</td>
        </tr>
      );
    });
  }

  render() {
    const { loading, page, pages, perPage } = this.state;
    const { sidebar } = this.props;

    return (
      <div>
        <Header
          sidebar={sidebar}
          title="Company"
          mode="buttons"
          rightNav={this.rightNav()}
        />
        <div className="row">
          <div className="col-sm-12">
            <Card body>
              <Row>
                <Col sm={6}>
                  <AsyncSelect
                    placeholder="Search company"
                    openMenuOnClick={false}
                    loadOptions={this.loadOptions}
                    onChange={this.onNameSelect}
                    noOptionsMessage={this.noOptionsMessage}
                    className="mb-2"
                  />
                </Col>
                <Col sm={6}>
                  <Pagination
                    page={page}
                    pages={pages}
                    setCurrentPage={this.setCurrentPage}
                  />
                </Col>
              </Row>
              <Table striped hover responsive>
                <thead>
                  <tr className="text-center">
                    <th>Name</th>
                    <th>Address</th>
                    <th>Landline</th>
                    <th>Fax</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? this.renderLoading() : this.renderList()}
                </tbody>
              </Table>
              <Row>
                <Col sm={6}>
                  <RowsPerPage
                    perPage={perPage}
                    setRowsPerPage={this.setRowsPerPage}
                  />
                </Col>
                <Col sm={6}>
                  <Pagination
                    page={page}
                    pages={pages}
                    setCurrentPage={this.setCurrentPage}
                  />
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

Company.contextType = UserContext;

export default Company;
