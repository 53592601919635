import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Table } from 'reactstrap';
import { UserContext } from '../../context/user';
import currencyFormat from '../../util/currency';
import dateFormat from '../../util/date';

class QuotationList extends Component {
  constructor(props) {
    super(props);
    this.quotationList = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
    this.setQuotationListHeight();
  }

  componentDidUpdate() {
    this.setQuotationListHeight();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setQuotationListHeight() {
    if (window.innerWidth <= 992) return;
    if (!this.quotationList.current) return;
    const rect = this.quotationList.current.getBoundingClientRect();

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    const bottomMargin = 10;
    const height = window.innerHeight - (rect.top + scrollTop) - bottomMargin;

    this.quotationList.current.style.height = `${height}px`;
    this.quotationList.current.style.overflow = 'auto';
  }

  renderQuotationList(quotations = []) {
    const { selectedQuotation, setSelectedQuotation } = this.props;

    return (
      <Table striped hover responsive size="sm">
        <thead>
          <tr>
            <th>Date</th>
            <th>Price</th>
            <th>Estimator</th>
            <th>Scope</th>
            <th>Revision #</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {quotations.length > 0 ? (
            quotations.map(row => {
              return (
                <tr
                  key={row._id}
                  className={
                    selectedQuotation && row._id === selectedQuotation._id
                      ? 'table-primary'
                      : 'selectableTR'
                  }
                  onClick={() => {
                    setSelectedQuotation(row);
                  }}
                >
                  <td>
                    <div>{dateFormat(row.date)}</div>
                  </td>
                  <th scope="row">
                    <div>{currencyFormat(row.price)}</div>
                  </th>
                  <td>
                    <div>{row.marketingUserName}</div>
                  </td>
                  <td>
                    <div>{row.scopeOfWork}</div>
                  </td>
                  <td>
                    <div className="text-center">
                      {row.revisionNumber ? row.revisionNumber : ''}
                    </div>
                  </td>
                  <td>
                    <div>{row.remarks}</div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <th scope="row" colSpan={6} className="text-center">
                -- none --
              </th>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }

  render() {
    const { quotations } = this.props;
    return (
      <Card>
        <CardHeader className="text-center">
          <strong>Quotation</strong>
        </CardHeader>
        <div ref={this.quotationList}>
          <CardBody className="px-1 py-0">
            {this.renderQuotationList(quotations)}
          </CardBody>
        </div>
      </Card>
    );
  }
}

QuotationList.contextType = UserContext;

export default QuotationList;
