import React, { Component } from 'react';
import { UserContext } from '../../context/user';
import CompanyAll from './index.all';
import CompanyDefault from './index.default';

class Company extends Component {
  render() {
    const { user } = this.context;
    let company = null;
    switch (user.role) {
      case 'kdc':
      case 'executive':
      case 'executiveSecretary':
      case 'receivable':
      case 'payable':
      case 'purchasing':
      case 'documentController':
        company = <CompanyAll {...this.props} />;
        break;
      default:
        company = <CompanyDefault {...this.props} role={user.role} />;
        break;
    }

    return company;
  }
}

Company.contextType = UserContext;

export default Company;
