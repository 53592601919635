import React, { Component, Fragment } from 'react';
import {
  UncontrolledAlert,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import API from '../../api';
import BankSelect from './bankSelect';
import SingleSelect from '../../components/SingleSelect';
import CompanySelect from './company';

const DEPOSIT = 'deposit';
// const WITHDRAWAL = 'withdrawal';
const CASH = 'cash';
const CHEQUE = 'cheque';

class BankTrxnForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuccess: false
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onSubmit = (values, { setSubmitting, setStatus, setErrors, resetForm }) => {
    const { handleSuccess, type } = this.props;
    this.setState({ showSuccess: false });

    const promise =
      type === DEPOSIT ? API.BankDeposit(values) : API.BankWithdraw(values);

    promise
      .then(() => {
        if (!this.mounted) return;
        setSubmitting(false);
        resetForm();
        if (type === DEPOSIT) {
          handleSuccess('Deposit added!');
        } else {
          handleSuccess('Withdrawal added!');
        }
      })
      .catch(e => {
        console.log(DEPOSIT, e);
        if (!this.mounted) return;
        setStatus({ success: false });
        setSubmitting(false);
        setErrors({ submit: e.message });
      });
  };

  setDefaultMethod = (setFieldValue, date, method) => {
    if (moment(date).isSameOrBefore(moment(), 'day')) {
      if (method === CASH) {
        setFieldValue('status', 'cleared');
        return;
      }

      // cheque
      if (moment(date).isBefore(moment().subtract(3, 'days'), 'day')) {
        setFieldValue('status', 'cleared');
        return;
      }
      setFieldValue('status', 'clearing');
      return;
    }

    setFieldValue('status', 'pending');
  };

  onChangePaymentMethod = (setFieldValue, values, method) => {
    this.setDefaultMethod(setFieldValue, values.date, method.value);
  };

  onChangeDate = (setFieldValue, values, evt) => {
    const newDate = evt.target.value;
    setFieldValue('date', newDate);
    this.setDefaultMethod(setFieldValue, newDate, values.paymentMethod);
  };

  renderStatus = (values, isSubmitting, errors, touched) => {
    const statusOptions = [
      { value: 'clearing', label: 'Clearing' },
      { value: 'cleared', label: 'Cleared' }
    ];
    if (moment(values.date).isSameOrBefore(moment(), 'day')) {
      if (values.paymentMethod === CASH) {
        return (
          <Input
            tag={Field}
            readOnly
            type="text"
            name="status"
            value="Cleared"
            disabled={isSubmitting}
          />
        );
      }
      // cheque
      return (
        <Field
          name="status"
          component={SingleSelect}
          options={statusOptions}
          disabled={isSubmitting}
          invalid={errors.status && touched.status}
        />
      );
    }

    return (
      <Input
        tag={Field}
        readOnly
        type="text"
        name="status"
        value="Pending"
        disabled={isSubmitting}
      />
    );
  };

  render() {
    const { showSuccess } = this.state;
    const { type, close } = this.props;
    const methodOptions = [
      { value: CASH, label: 'Cash' },
      { value: CHEQUE, label: 'Cheque' }
    ];

    return (
      <Formik
        initialValues={{
          type, // deposit or withdrawal
          paymentMethod: CHEQUE, // cash or cheque
          status: 'clearing', // pending, clearing, cleared
          amount: 0,
          date: moment().format('YYYY-MM-DD'),
          remarks: '',
          bankName: '',
          bankId: '',
          chequeNumber: '',
          companyName: '',
          issuingBankName: '',
          issuingBankBranch: ''
        }}
        validationSchema={Yup.object().shape({
          bankName: Yup.string().required('Required'),
          bankId: Yup.string().required('Required'),
          amount: Yup.number()
            .moreThan(0, 'Needs to be more than zero')
            .required('Required'),
          date: Yup.date().required('Required'),
          paymentMethod: Yup.string()
            .oneOf([CASH, CHEQUE])
            .required('Required'),
          status: Yup.string()
            .oneOf(['pending', 'clearing', 'cleared'])
            .required('Required'),
          chequeNumber: Yup.string().when('paymentMethod', {
            is: val => val === CHEQUE,
            then: Yup.string().required('Required')
          }),
          issuingBankName: Yup.string().when(['paymentMethod', 'type'], {
            is: (paymentMethodVal, typeVal) =>
              paymentMethodVal === CHEQUE && typeVal === DEPOSIT,
            then: Yup.string().required('Required')
          }),
          issuingBankBranch: Yup.string().when(['paymentMethod', 'type'], {
            is: (paymentMethodVal, typeVal) =>
              paymentMethodVal === CHEQUE && typeVal === DEPOSIT,
            then: Yup.string().required('Required')
          }),
          remarks: Yup.string().required('Required')
        })}
        onSubmit={this.onSubmit}
      >
        {({ setFieldValue, values, errors, touched, isSubmitting }) => (
          <Form>
            {showSuccess && (
              <Row form>
                <Col>
                  <UncontrolledAlert color="success">
                    Deposit success!
                  </UncontrolledAlert>
                </Col>
              </Row>
            )}
            {errors.submit && (
              <Row form>
                <Col>
                  <p className="text-danger">{errors.submit}</p>
                </Col>
              </Row>
            )}
            <Row form>
              <Col sm={12}>
                <FormGroup>
                  <Label for="bankName">Bank Account *</Label>
                  <Field
                    name="bankName"
                    disabled={isSubmitting}
                    component={BankSelect}
                  />
                  {errors.bankName && touched.bankName ? (
                    <div className="text-danger">{errors.bankName}</div>
                  ) : null}
                  <Input tag={Field} type="hidden" name="bankId" />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={6}>
                <FormGroup>
                  <Label for="paymentMethod">Method *</Label>
                  <Field
                    name="paymentMethod"
                    component={SingleSelect}
                    options={methodOptions}
                    onChange={opt =>
                      this.onChangePaymentMethod(setFieldValue, values, opt)
                    }
                    disabled={isSubmitting}
                    invalid={errors.paymentMethod && touched.paymentMethod}
                  />
                  <FormFeedback>{errors.paymentMethod}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="date">
                    {type === DEPOSIT ? 'Deposit' : 'Withdraw'} Date *
                  </Label>
                  <Input
                    tag={Field}
                    type="date"
                    name="date"
                    onChange={d => this.onChangeDate(setFieldValue, values, d)}
                    disabled={isSubmitting}
                    invalid={errors.date && touched.date}
                  />
                  <FormFeedback>{errors.date}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={6}>
                <FormGroup>
                  <Label for="amount">Amount in Peso *</Label>
                  <Input
                    tag={Field}
                    type="number"
                    name="amount"
                    min="0"
                    step="0.01"
                    placeholder="0.00"
                    disabled={isSubmitting}
                    invalid={errors.amount && touched.amount}
                  />
                  <FormFeedback>{errors.amount}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="paymentMethod">Status *</Label>
                  {this.renderStatus(values, isSubmitting, errors, touched)}
                  <FormFeedback>{errors.status}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            {values.paymentMethod === CHEQUE ? (
              <Fragment>
                <Row form>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="chequeNumber">Cheque Number *</Label>
                      <Input
                        tag={Field}
                        type="text"
                        name="chequeNumber"
                        disabled={isSubmitting}
                        invalid={errors.chequeNumber && touched.chequeNumber}
                      />
                      <FormFeedback>{errors.chequeNumber}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="companyName">
                        {type === DEPOSIT ? 'From' : 'For'} Company
                      </Label>
                      <Field name="companyName" component={CompanySelect} />
                      {errors.companyName && touched.companyName ? (
                        <div className="text-danger">{errors.companyName}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                {values.type === DEPOSIT ? (
                  <Row form>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="chequeNumber">Issuing Bank *</Label>
                        <Input
                          tag={Field}
                          type="text"
                          name="issuingBankName"
                          disabled={isSubmitting}
                          invalid={
                            errors.issuingBankName && touched.issuingBankName
                          }
                        />
                        <FormFeedback>{errors.issuingBankName}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="chequeNumber">Issuing Bank Branch *</Label>
                        <Input
                          tag={Field}
                          type="text"
                          name="issuingBankBranch"
                          disabled={isSubmitting}
                          invalid={
                            errors.issuingBankBranch &&
                            touched.issuingBankBranch
                          }
                        />
                        <FormFeedback>{errors.issuingBankBranch}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}
              </Fragment>
            ) : null}
            <Row form>
              <Col sm={12}>
                <FormGroup>
                  <Label for="remarks">Remarks *</Label>
                  <Input
                    tag={Field}
                    type="text"
                    name="remarks"
                    placeholder=""
                    disabled={isSubmitting}
                    invalid={errors.remarks && touched.remarks}
                  />
                  <FormFeedback>{errors.remarks}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <FormGroup row>
              <Col className="text-right">
                <Button
                  type="submit"
                  color="primary"
                  className="mr-1"
                  disabled={isSubmitting}
                >
                  {type === DEPOSIT ? 'Deposit' : 'Withdraw'}
                </Button>
                <Button type="button" color="danger" onClick={close}>
                  Cancel
                </Button>
              </Col>
            </FormGroup>
          </Form>
        )}
      </Formik>
    );
  }
}

export default BankTrxnForm;
