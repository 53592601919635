import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Button
} from 'reactstrap';
import Header from '../../containers/Header';
import Loading from '../../components/Loading';
import SalesOrderForm from '../salesOrder/form';
import dateFormat from '../../util/date';
import currencyFormat from '../../util/currency';
import API from '../../api';
import Info from './info';
import { CLEARED_THRESHOLD } from '../../constants/others';
import { UserContext } from '../../context/user';
import {
  ROLE_KDC,
  ROLE_EXECUTIVE,
  ROLE_EXECUTIVE_SECRETARY,
  ROLE_RECEIVABLE,
  ROLE_SALES
} from '../../util/roles';

class Summary extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    const { params } = match;
    this.state = {
      _id: params.id,
      company: {
        sales: [],
        invoices: [],
        payments: []
      },
      editSO: null,
      selectedSO: null,
      showSOModal: false,
      loading: true,
      errorMessage: null
    };

    this.soList = React.createRef();
    this.invList = React.createRef();
    this.payList = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
    this.getCompany();
  }

  componentDidUpdate() {
    this.setSOListHeight();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getCompany() {
    const { _id } = this.state;
    API.CompanySummary(_id)
      .then(company => {
        if (!this.mounted) return;
        this.setState({ company, loading: false });
      })
      .catch(e => {
        console.log(e);
        if (!this.mounted) return;
        this.setState({ errorMessage: e.message, loading: false });
      });
  }

  setSOListHeight() {
    if (window.innerWidth <= 992) return;
    if (!this.soList.current) return;
    const rect = this.soList.current.getBoundingClientRect();

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    const bottomMargin = 10;
    const height = window.innerHeight - (rect.top + scrollTop) - bottomMargin;

    this.soList.current.style.height = `${height}px`;
    this.soList.current.style.overflow = 'auto';
  }

  toggleSOModal = so => {
    this.setState(prevState => {
      return {
        editSO: so,
        showSOModal: !prevState.showSOModal
      };
    });
  };

  SOAdded = () => {
    this.setState({ loading: true, showSOModal: false }, () =>
      this.getCompany()
    );
  };

  isPaymentInSO = (so, payment) => {
    if (!so) return true;
    if (!payment.invoices) return false;

    for (let i = 0; i < payment.invoices.length; i += 1) {
      const inv = payment.invoices[i];
      if (inv.soNum === so.soNum) {
        return true;
      }
    }
    return false;
  };

  hiliteInvoice = invoice => {
    const { selectedPayment, selectedInvoice } = this.state;

    if (selectedInvoice && selectedInvoice._id === invoice._id) {
      return 'table-primary selectableTR text-center';
    }

    if (!selectedPayment) return 'selectableTR text-center';
    for (let i = 0; i < selectedPayment.invoices.length; i += 1) {
      const inv = selectedPayment.invoices[i];
      if (inv.invoiceNum === invoice.invoiceNum) {
        return 'table-primary selectableTR text-center';
      }
    }
    return 'selectableTR text-center';
  };

  hilitePayment = payment => {
    const { selectedPayment, selectedInvoice } = this.state;

    if (selectedPayment && selectedPayment._id === payment._id) {
      return 'table-primary selectableTR';
    }

    if (!selectedInvoice) return 'selectableTR';
    for (let i = 0; i < selectedInvoice.payments.length; i += 1) {
      const pay = selectedInvoice.payments[i];
      if (pay.orNum === payment.orNum) {
        return 'table-primary selectableTR';
      }
    }
    return 'selectableTR';
  };

  filterInvoices = (invoices, so) => {
    if (!so) return [];

    const filtered = [];
    invoices.forEach(inv => {
      if (inv.soNum !== so.soNum) return;
      filtered.push(inv);
    });
    return filtered;
  };

  selectPayment = pay => {
    const { selectedPayment } = this.state;
    if (selectedPayment && pay._id === selectedPayment._id) {
      this.setState({ selectedPayment: null });
    } else {
      this.setState({ selectedInvoice: null, selectedPayment: pay });
      window.scrollTo(0, this.invList.current.offsetTop);
    }
  };

  selectSO(so) {
    const { selectedSO } = this.state;
    if (selectedSO && so._id === selectedSO._id) {
      this.setState({ selectedSO: null });
    } else {
      this.setState({ selectedSO: so });
      window.scrollTo(0, 0);
    }
  }

  selectInvoice(inv) {
    const { selectedInvoice } = this.state;
    if (selectedInvoice && inv._id === selectedInvoice._id) {
      this.setState({ selectedInvoice: null });
    } else {
      this.setState({ selectedInvoice: inv, selectedPayment: null });
      window.scrollTo(0, this.payList.current.offsetTop);
    }
  }

  filterPayments(payments, so) {
    if (!so) return [];

    const filtered = [];
    payments.forEach(pay => {
      if (!this.isPaymentInSO(so, pay)) return;
      filtered.push(pay);
    });
    return filtered;
  }

  rightNav() {
    const { history } = this.props;
    return (
      <div>
        <Button
          color="primary"
          size="sm"
          onClick={() => this.toggleSOModal(null)}
          className="mr-1"
        >
          Add Sales Order
        </Button>
        <Button
          color="danger"
          size="sm"
          onClick={() => history.push('/app/company/')}
        >
          Back
        </Button>
      </div>
    );
  }

  renderSO() {
    const { selectedSO } = this.state;

    const { user } = this.context;
    const allowedUserSOEdit = [
      ROLE_KDC,
      ROLE_EXECUTIVE,
      ROLE_EXECUTIVE_SECRETARY,
      ROLE_RECEIVABLE,
      ROLE_SALES
    ];

    let editButton = '';
    if (user && user.role && allowedUserSOEdit.indexOf(user.role) > -1) {
      editButton = (
        <div className="text-right">
          <Button size="sm" onClick={() => this.toggleSOModal(selectedSO)}>
            Edit
          </Button>
        </div>
      );
    }

    return (
      <Card>
        <CardHeader className="px-3">
          <Row>
            <Col sm={6}>
              <strong className="mr-1">Sales Order: {selectedSO.soNum}</strong>
            </Col>
            <Col sm={6} className="text-right">
              Contract Price:{' '}
              {currencyFormat(selectedSO.poAmount, 'text-primary')}
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="pb-0 mb-3">
          <dl className="row mb-0 pb-0">
            <dt className="col-6 col-sm-3">Project Name</dt>
            <dd className="col-6 col-sm-3">
              {selectedSO.projectName ? selectedSO.projectName : '-'}
            </dd>
            <dt className="col-6 col-sm-3">Sales Agent</dt>
            <dd className="col-6 col-sm-3">{selectedSO.salesAgent}</dd>
            <dt className="col-6 col-sm-3">PO Date</dt>
            <dd className="col-6 col-sm-3">{dateFormat(selectedSO.poDate)}</dd>
            <dt className="col-6 col-sm-3">SO Date</dt>
            <dd className="col-6 col-sm-3">{dateFormat(selectedSO.soDate)}</dd>
            <dt className="col-6 col-sm-3">Terms</dt>
            <dd className="col-6 col-sm-3">
              {selectedSO.terms ? selectedSO.terms : '-'}
            </dd>
            <dt className="col-6 col-sm-3">TPC</dt>
            <dd className="col-6 col-sm-3">
              {currencyFormat(selectedSO.tpcAmount)}
            </dd>
            <dt className="col-6 col-sm-3">Delivery Date</dt>
            <dd className="col-6 col-sm-3">
              {dateFormat(selectedSO.deliveryDate)}
            </dd>
          </dl>
          <dl className="row mb-0 pb-0">
            <dt className="col-12 col-sm-12">Description:</dt>
            <dd className="col-12 col-sm-12">
              {selectedSO.description ? selectedSO.description : '-'}
            </dd>
          </dl>
          <dl className="row mb-0 pb-0">
            <dt className="col-12 col-sm-12">Remarks:</dt>
            <dd className="col-12 col-sm-12">
              {selectedSO.remarks ? selectedSO.remarks : '-'}
            </dd>
          </dl>
          {editButton}
        </CardBody>
      </Card>
    );
  }

  renderSOList(sales) {
    const { selectedSO } = this.state;

    return (
      <Table striped hover responsive size="sm">
        <thead>
          <tr>
            <th>SO#</th>
            <th>Project</th>
          </tr>
        </thead>
        <tbody>
          {sales.length > 0 ? (
            sales.map(row => {
              return (
                <tr
                  key={row._id}
                  className={
                    selectedSO && row._id === selectedSO._id
                      ? 'table-primary'
                      : 'selectableTR'
                  }
                  onClick={() => this.selectSO(row)}
                >
                  <th scope="row">
                    <div>{row.soNum}</div>
                  </th>
                  <td>
                    {row.projectName && <div>{row.projectName}</div>}
                    {currencyFormat(row.poAmount)}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <th scope="row" colSpan={2} className="text-center">
                -- none --
              </th>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }

  renderInvoiceList(invoices) {
    return (
      <Table striped hover responsive size="sm">
        <thead>
          <tr className="text-center">
            <th>Status</th>
            <th>INV#</th>
            <th>BL#</th>
            <th className="d-none d-sm-table-cell">Date</th>
            <th>Amount</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {invoices.length > 0 ? (
            invoices.map(row => {
              const trClass = this.hiliteInvoice(row);
              return (
                <tr
                  key={row._id}
                  className={trClass}
                  onClick={() => this.selectInvoice(row)}
                >
                  <td>{row.balance > CLEARED_THRESHOLD ? 'Unpaid' : 'Paid'}</td>
                  <th scope="row">{row.birInvoiceNum}</th>
                  <th scope="row">{row.billingInvoiceNum}</th>
                  <td className="d-none d-sm-table-cell">
                    {dateFormat(row.date)}
                  </td>
                  <td className="text-right">{currencyFormat(row.amount)}</td>
                  <td className="text-right">{currencyFormat(row.balance)}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <th scope="row" colSpan={6} className="text-center">
                -- none --
              </th>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }

  renderPaymentList(payments) {
    return (
      <Table striped hover responsive size="sm">
        <thead>
          <tr>
            <th>OR#</th>
            <th className="d-none d-sm-table-cell">Date</th>
            <th className="d-none d-sm-table-cell text-center">Tax</th>
            <th className="d-none d-sm-table-cell text-center">Amount</th>
            <th className="text-center">Total</th>
          </tr>
        </thead>
        <tbody>
          {payments.length > 0 ? (
            payments.map(row => {
              if (row.amount === row.excessAmount) return null;
              const trClass = this.hilitePayment(row);
              return (
                <tr
                  key={row._id}
                  className={trClass}
                  onClick={() => this.selectPayment(row)}
                >
                  <th scope="row">{row.birOrNum}</th>
                  <td className="d-none d-sm-table-cell">
                    {dateFormat(row.date)}
                  </td>
                  <td className="d-none d-sm-table-cell text-right">
                    {currencyFormat(row.tax)}
                  </td>
                  <td className="d-none d-sm-table-cell text-right">
                    {currencyFormat(row.subamount)}
                  </td>
                  <td className="text-right">{currencyFormat(row.amount)}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <th scope="row" colSpan={5} className="text-center">
                -- none --
              </th>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }

  render() {
    const {
      company,
      editSO,
      selectedSO,
      showSOModal,
      loading,
      errorMessage
    } = this.state;
    const { sidebar, history } = this.props;

    if (loading) {
      return (
        <div className="mt-5">
          <Loading className="h-100" />
        </div>
      );
    }

    if (errorMessage) {
      return <div className="mt-5 text-danger">Error: {errorMessage}</div>;
    }

    const invoices = this.filterInvoices(company.invoices, selectedSO);
    const payments = this.filterPayments(company.payments, selectedSO);

    return (
      <div>
        <Header
          sidebar={sidebar}
          title={company.name}
          mode="buttons"
          rightNav={this.rightNav()}
        />
        <Info company={company} history={history} collapse />
        <Row className="mb-4">
          <Col md="4" className="flex-grow-1 mb-3">
            <Card>
              <CardHeader className="text-center">
                <strong>Sales Order</strong>
              </CardHeader>
              <div ref={this.soList}>
                <CardBody className="px-1 py-0">
                  {this.renderSOList(company.sales)}
                </CardBody>
              </div>
            </Card>
          </Col>
          <Col md="8">
            {selectedSO && this.renderSO()}

            <div ref={this.invList}>
              <Card className="mb-3">
                <CardHeader className="text-center">
                  <strong>Invoice</strong>
                </CardHeader>
                <CardBody className="px-1 py-0 mb-3">
                  {this.renderInvoiceList(invoices)}
                </CardBody>
              </Card>
            </div>

            <div ref={this.payList}>
              <Card className="mb-3">
                <CardHeader className="text-center">
                  <strong>Payment</strong>
                </CardHeader>
                <CardBody className="px-1 py-0">
                  {this.renderPaymentList(payments)}
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        <Modal
          isOpen={showSOModal}
          toggle={this.toggleSOModal}
          backdrop="static"
        >
          <ModalHeader>{editSO ? 'Edit' : 'Add'} Sales Order</ModalHeader>
          <ModalBody>
            <SalesOrderForm
              close={this.toggleSOModal}
              so={editSO}
              companyName={company.name}
              companyId={company._id}
              salesAgent={company.salesAgent}
              salesAgentId={company.salesAgentId}
              done={this.SOAdded}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

Summary.contextType = UserContext;

export default Summary;
