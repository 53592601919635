import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { debounce } from 'lodash';
import API from '../../api';

export default class CompanySelect extends Component {
  constructor(props) {
    super(props);
    this.debounceCompany = debounce(this.getCompanies, 500);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getCompanies = (company, cb) => {
    API.CompanySearch(company)
      .then(list => {
        if (!this.mounted) return;
        const options = list.map(com => {
          return { value: com._id, label: com.name };
        });
        options.push({ value: company, label: company });
        cb(options);
      })
      .catch(() => cb([]));
  };

  loadOptions = (company, cb) => {
    this.debounceCompany(company, cb);
  };

  nameOptions = agent => {
    return API.getCompanies(agent)
      .then(list => {
        if (!this.mounted) return [];
        return list.map(a => {
          return { value: a._id, label: a.name };
        });
      })
      .catch(() => Promise.resolve([]));
  };

  onNameSelect = com => {
    const { form } = this.props;
    const { values } = form;
    values.companyName = com.label;
    form.setValues(values);
  };

  noOptionsMessage = () => {
    return null;
  };

  render() {
    const { form, name } = this.props;
    const { values } = form;
    const value = { label: values.companyName, value: values.companyName };

    return (
      <AsyncSelect
        placeholder="Optional company name"
        openMenuOnClick={false}
        loadOptions={this.loadOptions}
        onChange={this.onNameSelect}
        noOptionsMessage={this.noOptionsMessage}
        value={value}
        name={name}
      />
    );
  }
}
