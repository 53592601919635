const sortArrayObjectsByKeys = (array, key, highestToLowest = 1) => {
  if (!(array instanceof Array)) return array;

  array.sort((a, b) => {
    const keyA = a[key].toLowerCase();
    const keyB = b[key].toLowerCase();

    if (highestToLowest) {
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
    } else {
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
    }

    return 0;
  });

  return array;
};

export default sortArrayObjectsByKeys;
