import React, { PureComponent } from 'react';
import { Card, CardTitle } from 'reactstrap';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Cell,
  Legend
} from 'recharts';
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';
import API from '../../api';
import Loading from '../../components/Loading';

import Header from '../../containers/Header';

import Activity from '../activity';

// const COLORS = ['#ffc107', '#dc3545', '#28a745', '#6c757d'];
const BASE_UNIT = 1;
const MONTHLY_QUOTA = 4200000 / BASE_UNIT;
const YEARLY_QUOTA = 50000000 / BASE_UNIT;

export default class DashboardSales extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      yearly: [],
      last12Months: [],
      targetReport: []
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.getReports();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async getReports() {
    const yearlyResult = await API.ReportsSalesYearly();
    const monthlyResult = await API.ReportsSalesMonthly();

    const last12Months = [];
    let cur = moment().subtract(11, 'month');
    let end = moment().format('YY-MM');
    while (end >= cur.format('YY-MM')) {
      const curMonth = cur.format('YY-MM');
      const r = _.find(monthlyResult, v => v.period === curMonth);
      last12Months.push({
        name: cur.format('MMM YY'),
        sales: r ? r.value / BASE_UNIT || 0 : 0
      });
      cur = cur.add(1, 'month');
    }

    const total = {
      '2020': 0,
      '2019': 0,
      '2018': 0,
      '2017': 0
    };
    const targetReport = [];
    let target = MONTHLY_QUOTA;
    cur = moment().startOf('year');
    end = moment()
      .endOf('year')
      .format('YY-MM');
    while (end >= cur.format('YY-MM')) {
      const month = cur.format('MM');
      const mn20 = `20-${month}`;
      let r20 = _.find(monthlyResult, v => v.period === mn20);
      const mn19 = `19-${month}`;
      let r19 = _.find(monthlyResult, v => v.period === mn19);
      const mn18 = `18-${month}`;
      let r18 = _.find(monthlyResult, v => v.period === mn18);
      const mn17 = `17-${month}`;
      let r17 = _.find(monthlyResult, v => v.period === mn17);
      total['2020'] += r20 ? r20.value / BASE_UNIT : 0;
      total['2019'] += r19 ? r19.value / BASE_UNIT : 0;
      total['2018'] += r18 ? r18.value / BASE_UNIT : 0;
      total['2017'] += r17 ? r17.value / BASE_UNIT : 0;
      if (month === moment().format('MM') && !r20) {
        r20 = true;
      } else if (month === '01') {
        // January
        r20 = true;
        r19 = true;
        r18 = true;
        r17 = true;
      }
      targetReport.push({
        name: cur.format('MMM'),
        '2020': r20 ? total['2020'] : null,
        '2019': r19 ? total['2019'] : null,
        '2018': r18 ? total['2018'] : null,
        '2017': r17 ? total['2017'] : null,
        target
      });
      cur = cur.add(1, 'month');
      target += MONTHLY_QUOTA;
    }

    const yearly = [];
    cur = moment().subtract(2, 'year');
    end = moment().format('YYYY');
    while (end >= cur.format('YYYY')) {
      const curYear = cur.format('YYYY');
      const r = _.find(yearlyResult, v => v.period === curYear);
      yearly.push({
        name: cur.format('YYYY'),
        sales: r ? r.value / BASE_UNIT || 0 : 0
      });
      cur = cur.add(1, 'year');
    }
    if (this.mounted)
      this.setState({ targetReport, yearly, last12Months, loading: false });
  }

  formatYAxis = v => {
    const amount = numeral(v).format('0.[00]a');
    return `\u20B1${amount}`;
  };

  formatTooltip = value => {
    const amount = numeral(value).format('0.[00]a');
    return [`\u20B1${amount}`];
  };

  render() {
    const { loading, last12Months, targetReport, yearly } = this.state;
    const { sidebar } = this.props;

    if (loading) {
      return (
        <div className="h-100">
          <Header sidebar={sidebar} title="Dashboard" />
          <Loading />
        </div>
      );
    }

    return (
      <div>
        <Header sidebar={sidebar} title="Dashboard" />

        <div className="row">
          <div className="col-sm-6">
            <Activity />
          </div>

          <div className="col-sm-6">
            <div className="col-xs-12 mb-2">
              <Card body>
                <CardTitle>
                  <h4>Sales Monthly Target</h4>
                </CardTitle>
                <ResponsiveContainer
                  width="100%"
                  minWidth={300}
                  minHeight={200}
                  style={{ margin: 'auto' }}
                >
                  <LineChart
                    data={targetReport}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={this.formatYAxis} />
                    <Tooltip formatter={this.formatTooltip} />
                    <Line
                      type="monotone"
                      dataKey="2020"
                      stroke="#ffc107"
                      connectNulls
                    />
                    <Line
                      type="monotone"
                      dataKey="2019"
                      stroke="#7fc97f"
                      connectNulls
                    />
                    <Line
                      type="monotone"
                      dataKey="2018"
                      stroke="#386cb0"
                      connectNulls
                    />
                    <Line
                      type="monotone"
                      dataKey="2017"
                      stroke="#666666"
                      connectNulls
                    />
                    <Line
                      type="monotone"
                      dataKey="target"
                      stroke="#dc3545"
                      connectNulls
                    />
                    <Legend />
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            </div>

            <div className="col-xs-12 mb-2">
              <Card body>
                <CardTitle>
                  <h4>Yearly Sales</h4>
                </CardTitle>
                <ResponsiveContainer
                  width="100%"
                  minWidth={300}
                  minHeight={200}
                  style={{ margin: 'auto' }}
                >
                  <BarChart
                    data={yearly}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={this.formatYAxis} />
                    <Tooltip formatter={this.formatTooltip} />
                    <Bar dataKey="sales" fill="#28a745">
                      {yearly.map(entry => {
                        let color = '';
                        if (entry.sales >= YEARLY_QUOTA) {
                          color = '#28a745';
                        } else if (entry.sales >= YEARLY_QUOTA * 0.5) {
                          color = '#ffc107';
                        } else {
                          color = '#dc3545';
                        }
                        return <Cell key={entry.name} fill={color} />;
                      })}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Card>
            </div>

            <div className="col-xs-12 mb-4">
              <Card body>
                <CardTitle>
                  <h4>Last 12 Months Sales</h4>
                </CardTitle>
                <ResponsiveContainer
                  width="100%"
                  minWidth={400}
                  minHeight={200}
                  style={{ margin: 'auto' }}
                >
                  <BarChart
                    data={last12Months}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={this.formatYAxis} />
                    <Tooltip formatter={this.formatTooltip} />
                    <Bar dataKey="sales" fill="#28a745">
                      {last12Months.map(entry => {
                        let color = '';
                        if (entry.sales >= MONTHLY_QUOTA) {
                          color = '#28a745';
                        } else if (entry.sales >= MONTHLY_QUOTA * 0.5) {
                          color = '#ffc107';
                        } else {
                          color = '#dc3545';
                        }
                        return <Cell key={entry.name} fill={color} />;
                      })}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
