import React, { Component } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import Header from '../../containers/Header';
import Loading from '../../components/Loading';
import QuotationForm from '../quotation/form';
import QuotationView from '../quotation/view';
import ProjectForm from './form';
import API from '../../api';
import Info from './info';
import Activity from '../activity';
import QuotationList from '../quotation/list';
import { UserContext } from '../../context/user';

import {
  ROLE_KDC,
  ROLE_EXECUTIVE,
  ROLE_SALES,
  ROLE_DOCUMENT_CONTROLLER,
  ROLE_MARKETING,
  ROLE_MARKETING_SECRETARY
} from '../../util/roles';

class Summary extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    const { params } = match;
    this.state = {
      _id: params.id,
      project: null,
      selectedQuotation: null,
      showProjectModal: false,
      showQuotationModal: false,
      showQuotationViewModal: false,
      loading: true,
      errorMessage: null,
      listUpdateTs: new Date().getTime()
    };

    this.quotationList = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
    this.getProject();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setLastUpdate = (key, timestamp) => {
    if (!timestamp) return;

    const stateUpdateInfo = {};
    stateUpdateInfo[`${key}`] = timestamp;
    this.setState(stateUpdateInfo);

    setTimeout(() => {
      this.getProject();
    });
  };

  getProject() {
    const { _id } = this.state;
    API.ProjectSummary(_id)
      .then(project => {
        if (!this.mounted) return;
        this.setState({ project, loading: false });
      })
      .catch(e => {
        console.log(e);
        if (!this.mounted) return;
        this.setState({ errorMessage: e.message, loading: false });
      });
  }

  parent = () => {
    return { setLastUpdate: this.setLastUpdate };
  };

  toggleProjectModal = () => {
    this.setState(prevState => {
      return {
        showProjectModal: !prevState.showProjectModal
      };
    });
  };

  toggleQuotationModal = () => {
    this.setState(prevState => {
      return {
        showQuotationModal: !prevState.showQuotationModal
      };
    });
  };

  toggleQuotationViewModal = () => {
    this.setState(prevState => {
      return {
        showQuotationViewModal: !prevState.showQuotationViewModal
      };
    });
  };

  setSelectedQuotation = quotation => {
    const { project } = this.state;
    this.setState({ selectedQuotation: quotation });

    if (!quotation) {
      this.toggleQuotationModal();
    } else if (quotation.last) {
      if (['For Quotation', 'For Revision'].indexOf(project.status) === -1) {
        this.toggleQuotationViewModal();
      } else {
        this.toggleQuotationModal();
      }
    } else {
      this.toggleQuotationViewModal();
    }
  };

  showForRevisionButton = project => {
    const { user } = this.context;

    if (project.status !== 'Quoted') return false;

    const allowedRole = [
      ROLE_KDC,
      ROLE_EXECUTIVE,
      ROLE_SALES,
      ROLE_MARKETING,
      ROLE_MARKETING_SECRETARY,
      ROLE_DOCUMENT_CONTROLLER
    ];
    if (allowedRole.indexOf(user.role) === -1) return false;

    return true;
  };

  updateProjectStatus = status => {
    const { project } = this.state;

    if (
      !window.confirm(`Are you sure you want to change status to ${status}?`)
    ) {
      return;
    }

    if (status === project.status) {
      console.log(`Status is already ${status}`);
      return;
    }

    const data = {
      _id: project._id,
      status
    };

    API.ProjectUpdateStatus(data)
      .then(() => {
        this.setLastUpdate('listUpdateTs', new Date().getTime());
      })
      .catch(e => {
        console.log('UpdateProjectStatus', e);
        alert(e.message);
      });
  };

  rightNav() {
    const { history } = this.props;
    const { project } = this.state;
    const { quotations } = project;

    let lastQuote = null;
    if (quotations && quotations.length) {
      quotations.forEach(quote => {
        if (quote.last) {
          const last = { ...quote };
          delete last._id;
          lastQuote = last;
        }
      });
    }

    return (
      <div>
        {['For Quotation', 'For Revision'].indexOf(project.status) > -1 ? (
          <Button
            color="primary"
            size="sm"
            onClick={() => this.setSelectedQuotation(lastQuote)}
            className="mr-1"
          >
            Add Quotation
          </Button>
        ) : (
          ''
        )}

        {this.showForRevisionButton(project) ? (
          <Button
            color="primary"
            size="sm"
            className="mr-1"
            onClick={() => this.updateProjectStatus('For Revision')}
          >
            For Revision
          </Button>
        ) : (
          ''
        )}

        <Button
          color="danger"
          size="sm"
          onClick={() => history.push('/app/project/')}
        >
          Back
        </Button>
      </div>
    );
  }

  render() {
    const {
      project,
      selectedQuotation,
      showProjectModal,
      showQuotationModal,
      showQuotationViewModal,
      loading,
      errorMessage,
      listUpdateTs
    } = this.state;
    const { sidebar, history } = this.props;

    if (loading) {
      return (
        <div className="mt-5">
          <Loading className="h-100" />
        </div>
      );
    }

    if (errorMessage) {
      return <div className="mt-5 text-danger">Error: {errorMessage}</div>;
    }

    return (
      <div>
        <Header
          sidebar={sidebar}
          title={project.projectName}
          mode="buttons"
          rightNav={this.rightNav()}
        />

        <Info
          project={project}
          history={history}
          setLastUpdate={this.setLastUpdate}
          toggleProjectModal={this.toggleProjectModal}
          collapse
        />

        <Row className="mb-4">
          <Col md="6" className="flex-grow-1 mb-3">
            <QuotationList
              setSelectedQuotation={this.setSelectedQuotation}
              quotations={project.quotations}
            />
          </Col>
          <Col md="6" className="flex-grow-1 mb-3">
            <Activity projectId={project._id} />
          </Col>
        </Row>

        <Modal
          isOpen={showProjectModal}
          toggle={this.toggleProjectModal}
          backdrop="static"
        >
          <ModalHeader>{`${project ? 'Edit' : 'Add'} Project`}</ModalHeader>

          <ModalBody>
            <ProjectForm
              selectedQuotation={selectedQuotation}
              selectedProject={project}
              listUpdateTs={listUpdateTs}
              parent={this.parent()}
              toggleProjectModal={this.toggleProjectModal}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={showQuotationModal}
          toggle={this.toggleQuotationModal}
          backdrop="static"
        >
          <ModalHeader>{`${
            selectedQuotation ? 'Edit' : 'Add'
          } Quotation`}</ModalHeader>

          <ModalBody>
            <QuotationForm
              selectedProject={project}
              selectedQuotation={selectedQuotation}
              setLastUpdate={this.setLastUpdate}
              toggleQuotationModal={this.toggleQuotationModal}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={showQuotationViewModal}
          toggle={this.toggleQuotationViewModal}
          backdrop="static"
        >
          <ModalHeader>View Quotation</ModalHeader>
          <ModalBody>
            <QuotationView
              selectedQuotation={selectedQuotation}
              parent={this.parent()}
              toggleQuotationViewModal={this.toggleQuotationViewModal}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

Summary.contextType = UserContext;

export default Summary;
