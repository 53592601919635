import React, { Component } from 'react';
import { Button, Col, FormGroup, Label } from 'reactstrap';

import { UserContext } from '../../context/user';
import { getRoleProperNames } from '../../util/roles';

class UserView extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  composeUserViewFields = () => {
    const { selectedUser } = this.props;
    const {
      username,
      role,
      email,
      firstName,
      lastName,
      mobileNumber,
      landlineNumber,
      salesAgent,
      estimator,
      active
    } = selectedUser;

    const setYesNoValue = value => {
      return value === true ? 'Yes' : 'No';
    };

    const statusProperValue = isActive => {
      return isActive ? 'Active' : 'Inactive';
    };

    return (
      <div className="row" style={{ display: 'flex' }}>
        <div className="col-sm-12">
          <Label className="col-sm-3 text-right">Username</Label>
          <Label className="col-sm-9">{username}</Label>
        </div>
        <div className="col-sm-12">
          <Label className="col-sm-3 text-right">Role</Label>
          <Label className="col-sm-9">{getRoleProperNames[role]}</Label>
        </div>
        <div className="col-sm-12">
          <Label className="col-sm-3 text-right">Email</Label>
          <Label className="col-sm-9">{email}</Label>
        </div>
        <div className="col-sm-12">
          <Label className="col-sm-3 text-right">First Name</Label>
          <Label className="col-sm-9">{firstName}</Label>
        </div>
        <div className="col-sm-12">
          <Label className="col-sm-3 text-right">Last Name</Label>
          <Label className="col-sm-9">{lastName}</Label>
        </div>
        <div className="col-sm-12">
          <Label className="col-sm-3 text-right">Mobile Number</Label>
          <Label className="col-sm-9">{mobileNumber}</Label>
        </div>
        <div className="col-sm-12">
          <Label className="col-sm-3 text-right">Landline Number</Label>
          <Label className="col-sm-9">{landlineNumber}</Label>
        </div>
        <div className="col-sm-12">
          <Label className="col-sm-3 text-right">Sales Agent</Label>
          <Label className="col-sm-9">{setYesNoValue(salesAgent)}</Label>
        </div>
        <div className="col-sm-12">
          <Label className="col-sm-3 text-right">Estimator</Label>
          <Label className="col-sm-9">{setYesNoValue(estimator)}</Label>
        </div>
        <div className="col-sm-12">
          <Label className="col-sm-3 text-right">Status</Label>
          <Label className="col-sm-9">{statusProperValue(active)}</Label>
        </div>
      </div>
    );
  };

  composeUserViewButtons = toggleUserViewModal => {
    return (
      <div className="col-sm-12" style={{ marginTop: '21px' }}>
        <FormGroup row>
          <Col className="text-right">
            <Button type="button" color="danger" onClick={toggleUserViewModal}>
              Close
            </Button>
          </Col>
        </FormGroup>
      </div>
    );
  };

  render() {
    const { toggleUserViewModal } = this.props;

    return (
      <div>
        {this.composeUserViewFields()}
        {this.composeUserViewButtons(toggleUserViewModal)}
      </div>
    );
  }
}

UserView.contextType = UserContext;

export default UserView;
