/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Table,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/user';
import Loading from '../../components/Loading';
import dateFormat from '../../util/date';
import currencyFormat from '../../util/currency';
import sortArrayObjectsByKeys from '../../util/processArrayObjects';
import { CLEARED_THRESHOLD } from '../../constants/others';
import PaymentAddInvoice from '../payment/addInvoice';
import SalesOrderForm from '../salesOrder/form';
import InvoiceAdd from '../invoice/soAdd';
import PaymentAdd from '../payment/add';
import API from '../../api';

import {
  ROLE_KDC,
  ROLE_EXECUTIVE,
  ROLE_EXECUTIVE_SECRETARY,
  ROLE_RECEIVABLE,
  ROLE_PAYABLE,
  ROLE_PURCHASING
} from '../../util/roles';

class Receivables extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSO: null,
      showSOModal: false,
      showInvoiceModal: false,
      showPaymentModal: false,
      showPaymentAddInvoiceModal: false,
      editSO: null
    };

    this.soList = React.createRef();
    this.invList = React.createRef();
    this.payList = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
    this.setSOListHeight();
  }

  componentDidUpdate(prevProps) {
    // Make selected SO view auto update
    const { company } = this.props;
    const { selectedSO } = this.state;

    if (selectedSO && prevProps.company !== company) {
      if (!(company && company.sales)) return;

      setTimeout(() => {
        company.sales.forEach(selected => {
          if (selected._id === selectedSO._id) {
            this.setState({ selectedSO: selected });
          }
        });
      });
    }

    this.setSOListHeight();
  }

  setSOListHeight = () => {
    if (window.innerWidth <= 992) return;

    if (!this.soList.current) return;

    const rect = this.soList.current.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const bottomMargin = 10;

    // .. Temporary code.
    // .. Add 35px as space for history at the bottom of the page.
    const height =
      window.innerHeight - (rect.top + scrollTop) - bottomMargin - 55;

    this.soList.current.style.height = `${height}px`;
    this.soList.current.style.overflow = 'auto';
  };

  getSOBadge = so => {
    const badges = [];
    if (so.invoiceBalance > CLEARED_THRESHOLD) {
      badges.push(
        <Badge key="unpaid" pill color="danger">
          Unpaid
        </Badge>
      );
    }
    if (so.unbilledAmount > CLEARED_THRESHOLD) {
      badges.push(
        <Badge key="unbilled" pill color="warning">
          Unbilled
        </Badge>
      );
    }
    if (badges.length === 0) {
      badges.push(
        <Badge key="unbilled" pill color="success">
          Cleared
        </Badge>
      );
    }
    return badges;
  };

  hiliteInvoice = invoice => {
    const { selectedPayment, selectedInvoice } = this.state;

    if (selectedInvoice && selectedInvoice._id === invoice._id) {
      return 'table-primary selectableTR text-center';
    }

    if (!selectedPayment) return 'selectableTR text-center';
    for (let i = 0; i < selectedPayment.invoices.length; i += 1) {
      const inv = selectedPayment.invoices[i];
      if (inv.invoiceNum === invoice.invoiceNum) {
        return 'table-primary selectableTR text-center';
      }
    }
    return 'selectableTR text-center';
  };

  isPaymentInSO = (so, payment) => {
    if (!so) return true;
    if (!payment.invoices) return false;

    for (let i = 0; i < payment.invoices.length; i += 1) {
      const inv = payment.invoices[i];
      if (inv.soNum === so.soNum) {
        return true;
      }
    }
    return false;
  };

  hilitePayment = payment => {
    const { selectedPayment, selectedInvoice } = this.state;

    if (selectedPayment && selectedPayment._id === payment._id) {
      return 'table-primary selectableTR';
    }

    if (!selectedInvoice) return 'selectableTR';
    for (let i = 0; i < selectedInvoice.payments.length; i += 1) {
      const pay = selectedInvoice.payments[i];
      if (pay.orNum === payment.orNum) {
        return 'table-primary selectableTR';
      }
    }
    return 'selectableTR';
  };

  assignInvoiceToPayment = pay => {
    this.setState({
      selectedInvoice: null,
      selectedPayment: pay,
      showPaymentAddInvoiceModal: true
    });
  };

  toggleSOModal = so => {
    this.setState(prevState => {
      return {
        editSO: so,
        showSOModal: !prevState.showSOModal
      };
    });
  };

  toggleInvoiceModal = () => {
    const { selectedSO, showInvoiceModal } = this.state;
    if (!showInvoiceModal && !selectedSO) {
      setTimeout(() => {
        alert('Please select Sales Order first');
      }, 100);
      return;
    }

    this.setState(prevState => {
      return {
        showInvoiceModal: !prevState.showInvoiceModal
      };
    });
  };

  togglePaymentModal = () => {
    this.setState(prevState => {
      return {
        showPaymentModal: !prevState.showPaymentModal
      };
    });
  };

  togglePaymentAddInvoiceModal = () => {
    this.setState(prevState => {
      return {
        showPaymentAddInvoiceModal: !prevState.showPaymentAddInvoiceModal
      };
    });
  };

  SOAdded = () => {
    const { getCompanyReceivablesSummary } = this.props;

    this.setState({ showSOModal: false }, () => {
      getCompanyReceivablesSummary();
    });
  };

  InvoiceAdded = () => {
    const { getCompanyReceivablesSummary } = this.props;

    this.setState({ showInvoiceModal: false }, () => {
      getCompanyReceivablesSummary();
    });
  };

  PaymentAdded = () => {
    const { getCompanyReceivablesSummary } = this.props;

    this.setState({ showPaymentModal: false }, () => {
      getCompanyReceivablesSummary();
    });
  };

  PaymentAddInvoiceAdded = () => {
    const { getCompanyReceivablesSummary } = this.props;

    this.setState({ showPaymentAddInvoiceModal: false }, () => {
      getCompanyReceivablesSummary();
    });
  };

  selectSO = so => {
    const { selectedSO } = this.state;
    if (selectedSO && so._id === selectedSO._id) {
      this.setState({
        selectedSO: null,
        selectedInvoice: null,
        selectedPayment: null
      });
    } else {
      this.setState({
        selectedSO: so,
        selectedInvoice: null,
        selectedPayment: null
      });
      window.scrollTo(0, 0);
    }
  };

  selectInvoice = inv => {
    const { selectedInvoice } = this.state;
    if (selectedInvoice && inv._id === selectedInvoice._id) {
      this.setState({ selectedInvoice: null });
    } else {
      this.setState({ selectedInvoice: inv, selectedPayment: null });
      window.scrollTo(0, this.payList.current.offsetTop);
    }
  };

  selectPayment = pay => {
    const { selectedPayment } = this.state;
    if (selectedPayment && pay._id === selectedPayment._id) {
      this.setState({ selectedPayment: null });
    } else {
      this.setState({ selectedInvoice: null, selectedPayment: pay });
      window.scrollTo(0, this.invList.current.offsetTop);
    }
  };

  filterUnpaidInvoices = invoices => {
    if (!invoices) return invoices;

    const filtered = [];
    invoices.forEach(inv => {
      if (inv.balance <= CLEARED_THRESHOLD) return;
      filtered.push({ ...inv });
    });
    return filtered;
  };

  filterInvoices = (invoices, so) => {
    if (!(so && invoices)) return invoices;

    const filtered = [];
    invoices.forEach(inv => {
      if (inv.soNum !== so.soNum) return;
      filtered.push(inv);
    });
    return filtered;
  };

  filterPayments = (payments, so) => {
    if (!(so && payments)) return payments;

    const filtered = [];
    payments.forEach(pay => {
      if (!this.isPaymentInSO(so, pay)) return;
      filtered.push(pay);
    });
    return filtered;
  };

  removeInvoice = payment => {
    if (!window.confirm(`Are you sure you want un-assign selected payment?`)) {
      return;
    }

    const { getCompanyReceivablesSummary } = this.props;

    const { orNum } = payment;
    const { company } = this.props;
    const { invoices } = company;

    if (!invoices) return;

    const invoiceNumList = [];
    invoices.forEach(inv => {
      const { payments, invoiceNum } = inv;
      if (!payments) return;

      payments.forEach(pay => {
        const { orNum: paymentOrNum } = pay;

        if (paymentOrNum === orNum) {
          invoiceNumList.push(pay.invoiceNum || invoiceNum);
        }
      });
    });

    if (invoiceNumList.length) {
      const params = { orNum, invoiceNum: invoiceNumList };

      API.PaymentRemoveInvoice(params)
        .then(() => {
          if (!this.mounted) return;
          getCompanyReceivablesSummary();
        })
        .catch(e => {
          console.log('PaymentRemoveInvoice Error', e);
        });
    }
  };

  deleteSO = so => {
    if (!window.confirm(`Are you sure you want delete selected Sales Order?`)) {
      return;
    }

    const { getCompanyReceivablesSummary } = this.props;
    API.SalesOrdersRemove(so.soNum)
      .then(() => {
        if (!this.mounted) return;
        getCompanyReceivablesSummary();
      })
      .catch(err => {
        console.log(err);
      });
  };

  deletePayment = payment => {
    if (!window.confirm(`Are you sure you want delete selected payment?`)) {
      return;
    }

    const { amount, excessAmount } = payment;
    if (amount !== excessAmount) {
      alert(
        'Cannot delete payment.\n\nPlease make sure that the payment you are trying to delete is not connected to any invoice.'
      );
      return;
    }

    const { getCompanyReceivablesSummary } = this.props;

    API.PaymentDelete({ id: payment._id })
      .then(() => {
        if (!this.mounted) return;
        getCompanyReceivablesSummary();
      })
      .catch(err => {
        console.log(err);
      });
  };

  deleteInvoice = invoice => {
    if (!window.confirm(`Are you sure you want delete selected invoice?`)) {
      return;
    }

    const { amount, balance } = invoice;
    if (amount !== balance) {
      alert(
        'Cannot delete invoice.\n\nPlease make sure that the invoice you are trying to delete is not connected to any payment.'
      );
      return;
    }

    const { getCompanyReceivablesSummary } = this.props;

    API.InvoiceDelete({ id: invoice._id })
      .then(() => {
        if (!this.mounted) return;
        getCompanyReceivablesSummary();
      })
      .catch(err => {
        console.log(err);
      });
  };

  renderLoading = () => {
    return (
      <div className="mt-5">
        <Loading className="h-100" />
      </div>
    );
  };

  renderAddDropdownMenu = () => {
    return (
      <div style={{ padding: '0px 0px 21px', textAlign: 'right' }}>
        <UncontrolledButtonDropdown className="mr-1">
          <DropdownToggle caret color="primary" size="sm">
            Add
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={() => this.toggleSOModal(null)}>
              Sales Order
            </DropdownItem>
            <DropdownItem onClick={this.toggleInvoiceModal}>
              Invoice
            </DropdownItem>
            <DropdownItem onClick={this.togglePaymentModal}>
              Payment
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    );
  };

  renderReceivableSummary = company => {
    let invoiceAmount = 0;
    let paidAmount = 0;
    let invoiceBalance = 0;
    let unbilledAmount = 0;

    if (company.receivables) {
      invoiceAmount = company.receivables.invoiceAmount || 0;
      paidAmount = company.receivables.paidAmount || 0;
      invoiceBalance = company.receivables.invoiceBalance || 0;
      unbilledAmount = company.receivables.unbilledAmount || 0;
    }

    const invColor = invoiceAmount ? 'bg-primary' : 'bg-light text-dark';
    const paidColor = paidAmount ? 'bg-success' : 'bg-light text-dark';
    const unpaidColor = invoiceBalance ? 'bg-danger' : 'bg-light text-dark';
    const unbilledColor = unbilledAmount ? 'bg-warning' : 'bg-light text-dark';

    return (
      <Row className="mb-3 mx-1 py-1 px-1 bg-secondary text-white text-center">
        <Col lg={1} sm={2} xs={4}>
          Invoice
        </Col>
        <Col lg={2} sm={4} xs={8} className={invColor}>
          <strong>{currencyFormat(invoiceAmount)}</strong>
        </Col>
        <Col lg={1} sm={2} xs={4}>
          Paid
        </Col>
        <Col lg={2} sm={4} xs={8} className={paidColor}>
          <strong>{currencyFormat(paidAmount)}</strong>
        </Col>
        <Col lg={1} sm={2} xs={4}>
          Unpaid
        </Col>
        <Col lg={2} sm={4} xs={8} className={unpaidColor}>
          <strong>{currencyFormat(invoiceBalance)}</strong>
        </Col>
        <Col lg={1} sm={2} xs={4}>
          Unbilled
        </Col>
        <Col lg={2} sm={4} xs={8} className={unbilledColor}>
          <strong>{currencyFormat(unbilledAmount)}</strong>
        </Col>
      </Row>
    );
  };

  renderSOListContainer = () => {
    return (
      <Card>
        <CardHeader className="text-center">
          <strong>Sales Order</strong>
        </CardHeader>
        <div ref={this.soList}>
          <CardBody className="px-1 py-0">{this.renderSOList()}</CardBody>
        </div>
      </Card>
    );
  };

  renderSOList() {
    const { company } = this.props;
    const { sales } = company;
    const { user } = this.context;
    const { role } = user;

    const { selectedSO } = this.state;
    const sortedSales = sales ? sortArrayObjectsByKeys(sales, 'soNum') : [];
    const roles = [ROLE_KDC];

    const isDeletePaymentAllowed = roles.indexOf(role) > -1;
    return (
      <Table striped hover responsive size="sm">
        <thead>
          <tr>
            <th>SO#</th>
            <th>Project</th>
          </tr>
        </thead>
        <tbody>
          {sortedSales.length > 0 ? (
            sortedSales.map(row => {
              const badge = this.getSOBadge(row);

              return (
                <tr
                  key={row._id}
                  className={
                    selectedSO && row._id === selectedSO._id
                      ? 'table-primary'
                      : 'selectableTR'
                  }
                  onClick={() => this.selectSO(row)}
                >
                  <th scope="row">
                    <div>{row.soNum}</div>
                    {badge}
                  </th>
                  <td>
                    {row.projectName && <div>{row.projectName}</div>}
                    {currencyFormat(row.poAmount)}
                  </td>
                  {isDeletePaymentAllowed ? (
                    <td className="text-center">
                      <>
                        <Link
                          to="/#"
                          onClick={e => {
                            e.preventDefault();
                            this.deleteSO(row);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="ml-2 mr-2 text-danger"
                          />
                        </Link>
                      </>
                    </td>
                  ) : (
                    ''
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <th scope="row" colSpan={2} className="text-center">
                -- none --
              </th>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }

  renderSODetails() {
    const { selectedSO } = this.state;
    if (!selectedSO) return '';

    const badge = this.getSOBadge(selectedSO);
    return (
      <Card className="mb-2">
        <CardHeader className="px-3">
          <Row>
            <Col sm={6}>
              <strong className="mr-1">Sales Order: {selectedSO.soNum}</strong>
              {badge}
            </Col>
            <Col sm={6} className="text-right">
              Amount: {currencyFormat(selectedSO.poAmount, 'text-primary')}
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="bg-white">
          <dl className="row mb-0 pb-0">
            <dt className="col-6 col-sm-3">Unpaid Invoice</dt>
            <dd className="col-6 col-sm-3">
              {currencyFormat(selectedSO.invoiceBalance, 'text-danger')}
            </dd>
            <dt className="col-6 col-sm-3">Unbilled</dt>
            <dd className="col-6 col-sm-3">
              {currencyFormat(selectedSO.unbilledAmount, 'text-warning')}
            </dd>
            <dt className="col-6 col-sm-3">Project Name</dt>
            <dd className="col-6 col-sm-3">
              {selectedSO.projectName ? selectedSO.projectName : '-'}
            </dd>
            <dt className="col-6 col-sm-3">Sales Agent</dt>
            <dd className="col-6 col-sm-3">{selectedSO.salesAgent}</dd>
            <dt className="col-6 col-sm-3">PO Date</dt>
            <dd className="col-6 col-sm-3">{dateFormat(selectedSO.poDate)}</dd>
            <dt className="col-6 col-sm-3">SO Date</dt>
            <dd className="col-6 col-sm-3">{dateFormat(selectedSO.soDate)}</dd>
            <dt className="col-6 col-sm-3">Terms</dt>
            <dd className="col-6 col-sm-3">
              {selectedSO.terms ? selectedSO.terms : '-'}
            </dd>
            <dt className="col-6 col-sm-3">TPC</dt>
            <dd className="col-6 col-sm-3">
              {selectedSO.tpcAmount
                ? currencyFormat(selectedSO.tpcAmount)
                : '-'}
            </dd>
            <dt className="col-6 col-sm-3">Delivery Date</dt>
            <dd className="col-6 col-sm-3">
              {dateFormat(selectedSO.deliveryDate)}
            </dd>
          </dl>
          <dl className="row mb-0 pb-0">
            <dt className="col-12 col-sm-12">Description:</dt>
            <dd className="col-12 col-sm-12">
              {selectedSO.description ? selectedSO.description : '-'}
            </dd>
          </dl>
          <dl className="row mb-0 pb-0">
            <dt className="col-12 col-sm-12">Remarks:</dt>
            <dd className="col-12 col-sm-12">
              {selectedSO.remarks ? selectedSO.remarks : '-'}
            </dd>
          </dl>
          <div className="text-right">
            <Button size="sm" onClick={() => this.toggleSOModal(selectedSO)}>
              Edit
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }

  renderUnassignedPaymentList = payments => {
    if (!payments) return null;

    const { user } = this.context;
    const { role } = user;

    const roles = [
      ROLE_KDC,
      ROLE_EXECUTIVE,
      ROLE_EXECUTIVE_SECRETARY,
      ROLE_RECEIVABLE,
      ROLE_PAYABLE,
      ROLE_PURCHASING
    ];

    const isDeletePaymentAllowed = roles.indexOf(role) > -1;

    const { selectedSO } = this.state;
    if (selectedSO) return null;

    let unassigned = _.filter(payments, p => {
      return p.excessAmount > 0;
    });

    unassigned = unassigned.map(row => {
      const trClass = this.hilitePayment(row);
      return (
        <tr key={row._id} className={trClass}>
          <th scope="row" onClick={() => this.assignInvoiceToPayment(row)}>
            {row.birOrNum}
          </th>
          <td
            className="d-none d-sm-table-cell"
            onClick={() => this.assignInvoiceToPayment(row)}
          >
            {dateFormat(row.date)}
          </td>
          <td
            className="d-none d-sm-table-cell text-right"
            onClick={() => this.assignInvoiceToPayment(row)}
          >
            {currencyFormat(row.amount)}
          </td>
          <td
            className="text-right"
            onClick={() => this.assignInvoiceToPayment(row)}
          >
            {currencyFormat(row.excessAmount)}
          </td>
          {isDeletePaymentAllowed ? (
            <td className="text-center">
              <>
                {row.amount === row.excessAmount ? (
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault();
                      this.deletePayment(row);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="ml-2 mr-2 text-danger"
                    />
                  </Link>
                ) : (
                  ''
                )}
              </>
            </td>
          ) : (
            ''
          )}
        </tr>
      );
    });

    if (!unassigned || unassigned.length <= 0 || unassigned[0] === null) {
      return null;
    }

    return (
      <Card className="mb-3">
        <CardHeader className="text-center bg-danger text-white">
          <strong>Unassigned Payment</strong>
        </CardHeader>
        <CardBody className="px-1 py-0">
          <Table striped hover responsive size="sm">
            <thead>
              <tr>
                <th>OR#</th>
                <th className="d-none d-sm-table-cell">Date</th>
                <th className="d-none d-sm-table-cell text-center">Amount</th>
                <th className="text-center">Unassigned</th>
                {isDeletePaymentAllowed ? (
                  <th className="text-center"></th>
                ) : (
                  ''
                )}
              </tr>
            </thead>
            <tbody>{unassigned}</tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  renderInvoiceList = invoices => {
    if (!invoices) return '';

    const { user } = this.context;
    const { role } = user;

    const roles = [
      ROLE_KDC,
      ROLE_EXECUTIVE,
      ROLE_EXECUTIVE_SECRETARY,
      ROLE_RECEIVABLE,
      ROLE_PAYABLE,
      ROLE_PURCHASING
    ];

    const isRemoveInvoiceAllowed = roles.indexOf(role) > -1;

    return (
      <Table striped hover responsive size="sm">
        <thead>
          <tr className="text-center">
            <th>Status</th>
            <th>INV#</th>
            <th>BL#</th>
            <th className="d-none d-sm-table-cell">Date</th>
            <th>Amount</th>
            <th>Balance</th>
            {isRemoveInvoiceAllowed ? <th className="text-center"></th> : ''}
          </tr>
        </thead>
        <tbody>
          {invoices.length > 0 ? (
            invoices.map(row => {
              const trClass = this.hiliteInvoice(row);
              const badge =
                row.balance > CLEARED_THRESHOLD ? (
                  <Badge key="unpaid" pill color="danger">
                    Unpaid
                  </Badge>
                ) : (
                  <Badge key="paid" pill color="success">
                    Paid
                  </Badge>
                );
              return (
                <tr
                  key={row._id}
                  className={trClass}
                  onClick={() => this.selectInvoice(row)}
                >
                  <td>{badge}</td>
                  <th scope="row">{row.birInvoiceNum}</th>
                  <th scope="row">{row.billingInvoiceNum}</th>
                  <td className="d-none d-sm-table-cell">
                    {dateFormat(row.date)}
                  </td>
                  <td className="text-right">{currencyFormat(row.amount)}</td>
                  <td className="text-right">{currencyFormat(row.balance)}</td>
                  {isRemoveInvoiceAllowed ? (
                    <td className="text-center">
                      <>
                        {row.amount === row.balance ? (
                          <Link
                            to="/#"
                            onClick={e => {
                              e.preventDefault();
                              this.deleteInvoice(row);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="ml-2 mr-2 text-danger"
                            />
                          </Link>
                        ) : (
                          ''
                        )}
                      </>
                    </td>
                  ) : (
                    ''
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <th
                scope="row"
                colSpan={isRemoveInvoiceAllowed ? 7 : 6}
                className="text-center"
              >
                -- none --
              </th>
            </tr>
          )}
        </tbody>
      </Table>
    );
  };

  renderSoInvoiceList = () => {
    const { selectedSO } = this.state;
    const { company } = this.props;

    const invoices = this.filterInvoices(company.invoices, selectedSO);

    return (
      <div ref={this.invList}>
        <Card className="mb-3">
          <CardHeader className="text-center">
            <strong>Invoice</strong>
          </CardHeader>
          <CardBody className="px-1 py-0 mb-3">
            {this.renderInvoiceList(invoices)}
          </CardBody>
        </Card>
      </div>
    );
  };

  renderPaymentList = payments => {
    if (!payments) return '';

    const { user } = this.context;
    const { role } = user;

    const roles = [
      ROLE_KDC,
      ROLE_EXECUTIVE,
      ROLE_EXECUTIVE_SECRETARY,
      ROLE_RECEIVABLE,
      ROLE_PAYABLE,
      ROLE_PURCHASING
    ];

    const isRemovePaymentAllowed = roles.indexOf(role) > -1;

    return (
      <Table striped hover responsive size="sm">
        <thead>
          <tr>
            <th>OR#</th>
            <th className="d-none d-sm-table-cell">Date</th>
            <th className="d-none d-sm-table-cell text-center">Tax</th>
            <th className="d-none d-sm-table-cell text-center">Amount</th>
            <th className="text-center">Total</th>
            {isRemovePaymentAllowed ? <th className="text-center"></th> : ''}
          </tr>
        </thead>
        <tbody>
          {payments.length > 0 ? (
            payments.map(row => {
              if (row.amount === row.excessAmount) return null;
              const trClass = this.hilitePayment(row);
              return (
                <tr
                  key={row._id}
                  className={trClass}
                  onClick={() => this.selectPayment(row)}
                >
                  <th scope="row">{row.birOrNum}</th>
                  <td className="d-none d-sm-table-cell">
                    {dateFormat(row.date)}
                  </td>
                  <td className="d-none d-sm-table-cell text-right">
                    {currencyFormat(row.tax)}
                  </td>
                  <td className="d-none d-sm-table-cell text-right">
                    {currencyFormat(row.subamount)}
                  </td>
                  <td className="text-right">{currencyFormat(row.amount)}</td>
                  {isRemovePaymentAllowed ? (
                    <td className="text-center">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.removeInvoice(row);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="ml-2 mr-2 text-danger"
                        />
                      </Link>
                    </td>
                  ) : (
                    ''
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <th
                scope="row"
                colSpan={isRemovePaymentAllowed ? 6 : 5}
                className="text-center"
              >
                -- none --
              </th>
            </tr>
          )}
        </tbody>
      </Table>
    );
  };

  renderSoPaymentList = payments => {
    return (
      <div ref={this.payList}>
        <Card className="mb-3">
          <CardHeader className="text-center">
            <strong>Payment</strong>
          </CardHeader>
          <CardBody className="px-1 py-0">
            {this.renderPaymentList(payments)}
          </CardBody>
        </Card>
      </div>
    );
  };

  renderSoModal = () => {
    const { company } = this.props;
    const { showSOModal, editSO } = this.state;

    return (
      <Modal isOpen={showSOModal} toggle={this.toggleSOModal} backdrop="static">
        <ModalHeader>{editSO ? 'Edit' : 'Add'} Sales Order</ModalHeader>
        <ModalBody>
          <SalesOrderForm
            close={this.toggleSOModal}
            so={editSO}
            companyName={company.name}
            companyId={company._id}
            salesAgent={company.salesAgent}
            salesAgentId={company.salesAgentId}
            done={this.SOAdded}
          />
        </ModalBody>
      </Modal>
    );
  };

  renderInvoiceModal = () => {
    const { selectedSO, showInvoiceModal } = this.state;
    const { company } = this.props;

    return (
      <Modal
        isOpen={showInvoiceModal}
        toggle={this.toggleInvoiceModal}
        backdrop="static"
      >
        <ModalHeader>Add Invoice</ModalHeader>
        <ModalBody>
          <InvoiceAdd
            close={this.toggleInvoiceModal}
            companyName={company.name}
            companyId={company._id}
            so={selectedSO}
            done={this.InvoiceAdded}
          />
        </ModalBody>
      </Modal>
    );
  };

  renderPaymentModal = () => {
    const { showPaymentModal } = this.state;
    const { company } = this.props;

    return (
      <Modal
        isOpen={showPaymentModal}
        toggle={this.togglePaymentModal}
        backdrop="static"
      >
        <ModalHeader>Add Payment</ModalHeader>
        <ModalBody>
          <PaymentAdd
            close={this.togglePaymentModal}
            companyName={company.name}
            companyId={company._id}
            done={this.PaymentAdded}
            type="receivables"
          />
        </ModalBody>
      </Modal>
    );
  };

  renderPaymentAddInvoiceModal = () => {
    const { company } = this.props;
    const { selectedPayment, showPaymentAddInvoiceModal } = this.state;

    const unpaidInvoices = this.filterUnpaidInvoices(company.invoices);

    return (
      <Modal
        isOpen={showPaymentAddInvoiceModal}
        toggle={this.togglePaymentAddInvoiceModal}
        backdrop="static"
      >
        <ModalHeader>Assign Invoice to Payment</ModalHeader>
        <ModalBody>
          <PaymentAddInvoice
            close={this.togglePaymentAddInvoiceModal}
            payment={selectedPayment}
            invoices={unpaidInvoices}
            done={this.PaymentAddInvoiceAdded}
            type="receivables"
          />
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const { selectedSO } = this.state;
    const { company } = this.props;

    if (!company) return this.renderLoading();

    const payments = this.filterPayments(company.payments, selectedSO);

    return (
      <Row className="mb-3">
        <CardBody>
          {this.renderAddDropdownMenu()}

          {this.renderReceivableSummary(company)}

          <Row className="mb-1">
            <Col md="4" className="flex-grow-1 mb-3">
              {this.renderSOListContainer()}
            </Col>

            <Col md="8">
              {this.renderSODetails()}
              {this.renderUnassignedPaymentList(payments)}
              {this.renderSoInvoiceList()}
              {this.renderSoPaymentList(payments)}
            </Col>
          </Row>
        </CardBody>

        {this.renderSoModal()}
        {this.renderInvoiceModal()}
        {this.renderPaymentModal()}
        {this.renderPaymentAddInvoiceModal()}
      </Row>
    );
  }
}

Receivables.contextType = UserContext;

export default Receivables;
