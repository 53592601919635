import React, { Component } from 'react';
import {
  Pagination as PaginationStrap,
  PaginationItem,
  PaginationLink
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Pagination extends Component {
  setCurrentPage(page) {
    const { setCurrentPage } = this.props;
    setCurrentPage(page);
  }

  renderPageLink(page, active) {
    return (
      <PaginationItem key={page} active={active}>
        <PaginationLink onClick={() => this.setCurrentPage(page)}>
          {page}
        </PaginationLink>
      </PaginationItem>
    );
  }

  render() {
    const { page, pages } = this.props;

    if (pages <= 1) {
      return null;
    }

    let first = (
      <li className="page-item">
        <button
          type="button"
          className="page-link"
          aria-label="Previous"
          onClick={() => this.setCurrentPage(1)}
        >
          <FontAwesomeIcon className="icon" icon="step-backward" />
        </button>
      </li>
    );

    if (page === 1) {
      first = (
        <li className="page-item disabled">
          <button
            type="button"
            className="page-link"
            aria-label="Previous"
            onClick={() => this.setCurrentPage(1)}
          >
            <FontAwesomeIcon className="icon" icon="step-backward" />
          </button>
        </li>
      );
    }

    let last = (
      <li className="page-item">
        <button
          type="button"
          className="page-link"
          aria-label="Previous"
          onClick={() => this.setCurrentPage(pages)}
        >
          <FontAwesomeIcon className="icon" icon="step-forward" />
        </button>
      </li>
    );
    if (page === pages) {
      last = (
        <li className="page-item disabled">
          <button type="button" className="page-link" aria-label="Previous">
            <FontAwesomeIcon className="icon" icon="step-forward" />
          </button>
        </li>
      );
    }
    const links = [];

    let i = page > 5 ? page - 4 : 1;
    if (i !== 1) {
      links.push(
        <PaginationItem key="-1" disabled>
          <PaginationLink href="#">...</PaginationLink>
        </PaginationItem>
      );
    }
    for (; i <= page + 4 && i <= pages; i += 1) {
      if (i === page) {
        links.push(this.renderPageLink(i, true));
      } else {
        links.push(this.renderPageLink(i, false));
      }
      if (i === page + 4 && i < pages) {
        links.push(
          <PaginationItem key="-2" disabled>
            <PaginationLink href="#">...</PaginationLink>
          </PaginationItem>
        );
      }
    }

    return (
      <PaginationStrap className="float-right" size="sm">
        {first}
        {links}
        {last}
      </PaginationStrap>
    );
  }
}
