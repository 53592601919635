import React, { Component } from 'react';
import {
  Button,
  Card,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import { isMobileOnly } from 'react-device-detect';

import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import ActivityForm from '../activity/form';
import ActivityView from '../activity/view';

import Header from '../../containers/Header';

import API from '../../api';
import { UserContext } from '../../context/user';

import sortArrayObjectsByKeys from '../../util/processArrayObjects';
import { getWeekDateRange, getMonthDateRange } from '../../util/date';

import '../../styles/react-big-calendar.css';
import '../../styles/react-big-calendar-overrride.css';

import autoRefreshList, { clearAutoRefreshList } from '../../util/scheduler';

import {
  ROLE_KDC,
  ROLE_EXECUTIVE,
  ROLE_ENGINEERING_SECRETARY,
  ROLE_MARKETING_SECRETARY,
  ROLE_HR,
  ROLE_EXECUTIVE_SECRETARY,
  ROLE_DOCUMENT_CONTROLLER
} from '../../util/roles';

const localizer = momentLocalizer(moment); // or globalizeLocalizer

const LEFT = 'left';
const RIGHT = 'right';

// Make  Monday as start of the wek.
moment.updateLocale('en', { week: { dow: 1 } });

class ActivityWeekly extends Component {
  executiveRoles = [
    ROLE_KDC,
    ROLE_EXECUTIVE,
    ROLE_ENGINEERING_SECRETARY,
    ROLE_MARKETING_SECRETARY,
    ROLE_HR,
    ROLE_EXECUTIVE_SECRETARY,
    ROLE_DOCUMENT_CONTROLLER
  ];

  constructor(props) {
    super(props);

    this.state = {
      selectOptions: [],
      dateRange: getWeekDateRange(),
      selectedUser: [],
      activityLogs: [],
      showActivityModal: false,
      showActivityViewModal: false,
      selectedActivity: null
    };
  }

  async componentDidMount() {
    const { user } = this.context;
    const { role } = user;

    this.mounted = true;

    const inExcutiveRole = this.executiveRoles.indexOf(role) > -1;
    await this.getAllUsersList(inExcutiveRole);
    this.getUserActivityWeekly();
  }

  componentWillUnmount() {
    this.mounted = false;
    clearAutoRefreshList('getUserActivityWeekly');
  }

  toggleActivityModal = () => {
    this.setState(prevState => {
      return {
        showActivityModal: !prevState.showActivityModal
      };
    });
  };

  toggleActivityViewModal = () => {
    this.setState(prevState => {
      return {
        showActivityViewModal: !prevState.showActivityViewModal
      };
    });
  };

  reconstructActivityWeeklyInformation = activities => {
    const activityLogs = [];
    activities.forEach(activity => {
      activityLogs.push({
        activity,
        id: activity._id,
        title: activity.log,
        desc: activity.log,
        start: new Date(
          `${activity.date} ${activity.timeStart}`.replace(/-/g, '/') // Replacing - with / is for Safari
        ),
        end: new Date(
          `${activity.date} ${activity.timeEnd}`.replace(/-/g, '/')
        ), // Replacing - with / is for Safari
        projectName: activity.projectName
      });
    });

    this.setState({ activityLogs });
  };

  getUserActivityWeekly = async (limit = 6) => {
    setTimeout(async () => {
      const { dateRange, selectedUser } = this.state;
      const userId = selectedUser.value;
      const dateStart = dateRange[0].stringDate;
      const dateEnd = dateRange[limit].stringDate;

      if (!(userId && dateStart && dateEnd)) return;

      await API.UserActivityWeekly(userId, dateStart, dateEnd)
        .then(res => {
          if (!this.mounted) return;
          this.reconstructActivityWeeklyInformation(res);
          autoRefreshList(this, 'getUserActivityWeekly');
        })
        .catch(err => {
          console.log(err);
        });
    }, 100);
  };

  getAllUsersList = async inExcutiveRole => {
    let { selectedUser } = this.state;
    const { user } = this.context;

    if (!(selectedUser && selectedUser.length > 0)) {
      selectedUser = {
        value: user._id,
        label: `${user.firstName} ${user.lastName}`
      };
    }

    if (!inExcutiveRole) {
      this.setState({ selectedUser });
      return;
    }

    await API.AllUsers()
      .then(res => {
        if (!this.mounted) return;

        const selectOptions = [];
        res.forEach(u => {
          selectOptions.push({
            value: u._id,
            label: `${u.firstName} ${u.lastName}`
          });
        });

        this.setState({
          selectOptions: sortArrayObjectsByKeys(selectOptions, 'label', 0),
          selectedUser
        });
      })
      .catch(err => {
        console.log(err);
        if (!this.mounted) return;
        this.setState({ selectOptions: [] });
      });
  };

  setSelectedActivity = activity => {
    this.setState({ selectedActivity: activity });
    this.toggleActivityModal();
  };

  setSelectedActivityView = activity => {
    this.setState({ selectedActivity: activity });
    this.toggleActivityViewModal();
  };

  renderLoading = () => {
    return (
      <tr key="spinner">
        <td colSpan={9} className="text-center">
          <span className="spinner-border" role="status" aria-hidden="true" />
        </td>
      </tr>
    );
  };

  onUserSelect = user => {
    this.setState({ selectedUser: user });
    this.getUserActivityWeekly();
  };

  renderUserDropdown = () => {
    const { selectedUser, selectOptions } = this.state;
    const selectValue = selectedUser || [];

    return (
      <Row>
        <div className="col-sm-8 col-md-4" style={{ margin: 'auto' }}>
          <Select
            options={selectOptions}
            onChange={this.onUserSelect}
            value={selectValue}
          />
        </div>
      </Row>
    );
  };

  renderTitle = () => {
    return (
      <Row>
        <Col>
          <h5 style={{ textAlign: 'center' }}>My Activity Week</h5>
        </Col>
      </Row>
    );
  };

  renderHeader = () => {
    const { user } = this.context;
    const { role } = user;

    return this.executiveRoles.indexOf(role) > -1
      ? this.renderUserDropdown()
      : this.renderTitle();
  };

  renderButton = (options = {}) => {
    const defaultIcon =
      options.position !== RIGHT ? 'angle-left' : 'angle-right';

    const defaultStyle = {
      textDecoration: 'none',
      color: '#212529',
      fontSize: '.8rem'
    };

    const param =
      options.clickParam || options.clickParamForce ? options.clickParam : '';

    const settings = {
      click: options.click
        ? () => {
            this[options.click](param);
          }
        : () => {},
      position: options.position ? options.position : LEFT,
      text: options.text ? options.text : '',
      icon: options.icon ? options.icon : defaultIcon,
      style: options.style
        ? { ...defaultStyle, ...options.style }
        : defaultStyle,
      className: options.className ? options.className : '',
      labelClassName: options.labelClassName ? options.labelClassName : ''
    };

    const deafultIconStructure = (
      <FontAwesomeIcon className="icon mr-1" icon={settings.icon} />
    );

    return (
      <Button
        onClick={settings.click}
        color="light small"
        className={`text-left ${settings.className}`}
        style={settings.style}
      >
        <span className={settings.labelClassName}>
          {settings.position === RIGHT ? settings.text : ''}
        </span>
        {settings.icon === 'none' ? '' : deafultIconStructure}
        <span className={settings.labelClassName}>
          {settings.position === LEFT ? settings.text : ''}
        </span>
      </Button>
    );
  };

  renderNavigation = () => {
    const addSettings = {
      text: 'Add Activity',
      click: 'setSelectedActivity',
      clickParam: null,
      clickParamForce: true,
      icon: 'none',
      style: {
        color: '#373a3c',
        display: 'inline-block',
        margin: '0',
        textAlign: 'center',
        verticalAlign: 'middle',
        background: 'none',
        backgroundImage: 'none',
        border: '1px solid #ccc',
        padding: '.375rem 1rem',
        borderRadius: '4px',
        lineHeight: 'normal',
        whiteSpace: 'nowrap'
      }
    };

    return (
      <div
        className="col-sm-12"
        style={{ padding: '2px', display: 'flex', flexWrap: 'wrap' }}
      >
        <div
          className="col-sm-12"
          style={{ textAlign: 'right', padding: '14px 0px' }}
        >
          {this.renderButton(addSettings)}
        </div>
      </div>
    );
  };

  setLastUpdate = () => {
    this.getUserActivityWeekly();
  };

  parent = () => {
    return {
      setLastUpdate: this.setLastUpdate
    };
  };

  handleNavigate = (today, viewType) => {
    if (viewType === 'agenda') {
      const range = getMonthDateRange(today);
      this.setState({ dateRange: range });
      this.getUserActivityWeekly(range.length - 1);
      return;
    }

    const range = getWeekDateRange(moment(today).startOf('isoweek'));
    this.setState({ dateRange: range });
    this.getUserActivityWeekly();
  };

  handleView = viewType => {
    const { dateRange } = this.state;
    if (viewType === 'agenda') {
      const range = getMonthDateRange(dateRange[0].stringDate);
      this.setState({ dateRange: range });
      this.getUserActivityWeekly(range.length - 1);
      return;
    }

    const range = getWeekDateRange(
      moment(dateRange[0].stringDate).startOf('isoweek')
    );
    this.setState({ dateRange: range });
    this.getUserActivityWeekly();
  };

  customCalendarEvent = calendarEvent => {
    const { user } = this.context;
    const { _id: userId } = user;
    const { activity } = calendarEvent;

    let allowEdit = 1;
    const startOfCurrentWeekDate = moment().startOf('isoWeek');
    const selectedDate = moment(activity.date);

    if (!selectedDate.isSameOrAfter(startOfCurrentWeekDate)) {
      allowEdit = 0;
    }

    if (userId === activity.userId && allowEdit) {
      this.setSelectedActivity(calendarEvent.activity);
    } else {
      this.setSelectedActivityView(calendarEvent.activity);
    }
  };

  customCalendarView = data => {
    return (
      <span>
        {data.title}
        <br />
        <br />
        {data.event.projectName ? `#${data.event.projectName}` : ''}
      </span>
    );
  };

  renderCalendar = () => {
    const { activityLogs } = this.state;

    const formats = {
      dayRangeHeaderFormat: ({ start, end }, culture, local) =>
        `${local.format(start, 'MMM DD', culture)} - ${local.format(
          end,
          'MMM DD, YYYY',
          culture
        )}`,

      agendaHeaderFormat: ({ start, end }, culture, local) =>
        `${local.format(start, 'MMM DD', culture)} - ${local.format(
          end,
          'MMM DD, YYYY',
          culture
        )}`,

      dayFormat: 'ddd M/D'
    };

    return (
      <div>
        <div style={{ height: '500pt' }}>
          <Calendar
            views={[Views.WEEK, Views.DAY, Views.AGENDA]}
            defaultView={isMobileOnly ? Views.DAY : Views.WEEK}
            events={activityLogs}
            startAccessor="start"
            endAccessor="end"
            defaultDate={moment().toDate()}
            localizer={localizer}
            scrollToTime={new Date(2019, 0, 1, 8, 0)} // 8:00 AM
            formats={formats}
            onSelectEvent={event => this.customCalendarEvent(event)}
            onNavigate={this.handleNavigate}
            onView={this.handleView}
            length={7}
            components={{
              event: this.customCalendarView
            }}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      selectedActivity,
      showActivityModal,
      showActivityViewModal
    } = this.state;
    const { sidebar } = this.props;

    return (
      <div>
        <Header sidebar={sidebar} title="Activity Weekly" />
        <div className="row">
          <div className="col-sm-12">
            <Card body>
              {this.renderHeader()}

              {this.renderNavigation()}

              {this.renderCalendar()}
            </Card>
          </div>
        </div>

        <Modal
          isOpen={showActivityModal}
          toggle={this.toggleActivityModal}
          backdrop="static"
        >
          <ModalHeader>
            {`${selectedActivity ? 'Edit' : 'Add'} Activity`}
          </ModalHeader>
          <ModalBody>
            <ActivityForm
              selectedActivity={selectedActivity}
              parent={this.parent()}
              toggleActivityModal={this.toggleActivityModal}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={showActivityViewModal}
          toggle={this.toggleActivityViewModal}
          backdrop="static"
        >
          <ModalHeader>View Activity</ModalHeader>
          <ModalBody>
            <ActivityView
              selectedActivity={selectedActivity}
              parent={this.parent()}
              toggleActivityViewModal={this.toggleActivityViewModal}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

ActivityWeekly.contextType = UserContext;

export default ActivityWeekly;
